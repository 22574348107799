import React, { useEffect, useState } from 'react'
import { usePumps } from 'hooks'
import { IOption } from 'components/Dropdown/types'
import { Dropdown } from 'components/Dropdown'

interface PumpDropdownProps {
  onChange: (selectedPump: string) => void
  className: string
  value: string
  readOnly?: boolean
  transparent?: boolean
}

const PumpDropdown: React.FC<PumpDropdownProps> = ({ onChange, className, value, readOnly, transparent }) => {
  const { data, mutate } = usePumps({ active: 1 })
  const { data: allPumps } = usePumps()
  const [options, setOptions] = useState<IOption[]>([])
  const [placeHolder, setPlaceHolder] = useState('')

  useEffect(() => {
    if (data) {
      const pumpOptions = data.sort((pumpA, pumpB) => {
        if (pumpA.pumpNumber < pumpB.pumpNumber) return -1
        if (pumpA.pumpNumber > pumpB.pumpNumber) return 1
        return 0
      }).map((pump) => ({
        label: `${pump.pumpNumber || pump.serialNumber}`,
        value: pump.id,
        disabled: pump.sessionId !== null
      }))
      setOptions(pumpOptions)
    }
  }, [data])

  useEffect(() => {
    if (allPumps)
      for (const pump of allPumps) {
        if (pump.id === value) {
          setPlaceHolder(pump.pumpNumber ? String(pump.pumpNumber) : pump.serialNumber)
          break
        }
      }
  }, [value, allPumps])

  const handleDropdownClick = () => {
    void mutate()
  }

  return (
    <Dropdown
      value={value}
      withAutocomplete
      options={options}
      onChange={({ target: { value } }) => onChange(value as string)}
      onFocus={handleDropdownClick}
      className={className}
      placeholder={placeHolder || ''}
      required
      readOnly={readOnly}
      transparent={transparent}
    />
  )
}

export default PumpDropdown
