import { PaginatedQuery, PaginatedResponse } from './pagination'
import { MembershipDetail } from './memberships'
import { APP_SCREEN_NAMES } from './common'

export enum USER_ROLE {
  PROVIDER = 'provider',
  CLINICAL_STAFF = 'clinical-staff',
  ADMINISTRATOR = 'administrator',
}

export type UserQuery = PaginatedQuery<{
  search?: string
  active?: string
  clinicIds?: string[]
  roles?: string[]
}>

export type User = {
  id: string
  auth0Id: string
  firstName: string
  lastName: string
  email: string
  password?: string
  initials: string
  role: USER_ROLE
  active: boolean
  npi?: string
  credentials?: string | null
  settings: UserSettings
}

export type UserDetail = Omit<User, 'password'> & {
  updated: string
  created: string
  deleted: Date | null
  memberships: MembershipDetail[]
  history?: UserHistory | null
}

export type UserListResponse = PaginatedResponse<UserDetail>

export type CredentialsDTO = {
  email: string
  password: string
}

export type UserSettings = Partial<{
  [key in APP_SCREEN_NAMES]: {
    sortListings: {
      fieldName: string
      order: 'ASC' | 'DESC'
    },
    columns?: string[]
  }
}>

export type UserHistory = {
  id: string
  lastLogin?: Date
  lastLogout?: Date
  email: string
  user?: User
}

export type UserHistoryModelEntity = {
  id: string
  lastLogin?: Date
  lastLogout?: Date
  email?: string
  user?: User
}