import _ from "lodash"
import { useEffect, useState } from "react"
import { usePatients } from "./usePatient"
import { PatientOutputDTO } from "types"

export const useCheckForExistingPatient = (
  fields: {
    firstName: string | undefined,
    lastName: string | undefined,
    dob: string | undefined,
    clinicId: string | null },
  skip = true
) => {
  const [query, setQuery] = useState({})
  const { firstName, lastName, dob, clinicId } = fields

  const { data: existingPatients } = usePatients(
    JSON.parse(JSON.stringify(query)),
    {},
    skip || _.isEmpty(query)
  )

  useEffect(() => {
    if (skip) return
    if (!firstName || !lastName || !dob || !clinicId) return

    const delayDebounceId = setTimeout(() => {
      setQuery({
        'clinicIds[0]': clinicId,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        dob
      })
    }, 1000)

    return () => clearTimeout(delayDebounceId)
  }, [skip, firstName, lastName, dob, clinicId])

  return (existingPatients || []) as PatientOutputDTO[]
}