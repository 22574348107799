import { Button } from 'components/Button'
import { useAuth } from 'hooks'
import React, { useEffect } from 'react'

export const NoActiveUserScreen = () => {
  const { signout, cleanupSession } = useAuth()

  useEffect(() => {
    cleanupSession()
  }, [cleanupSession])

  return (
    <dialog open className='modal backdrop-blur-sm'>
      <div className='modal-box p-4'>
        <div className='flex'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            className='stroke-current flex-shrink-0 w-16 h-16 mr-2 text-warning'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
            />
          </svg>
          <div>
            <span className='text-primary text-xl leading-9'>User not active or no associated clinics</span>
            <span className='block text-sm text-slate-400'>Contact administrator for account activation</span>

            <div className='mt-8 flex justify-center'>
              <Button onClick={signout}> Back to Login </Button>
            </div>
          </div>

        </div>
      </div>
    </dialog>
  )
}