import React from 'react'
import { DeleteRowModalProps } from './types'
import { Button } from 'components/Button'
export const openConfirmationModal = (id: string) => (document.getElementById(id) as HTMLDialogElement)?.showModal()
export const hideConfirmationModal = (id: string) => (document.getElementById(id) as HTMLDialogElement)?.close()

export const DeleteRowModal = ({
  id,
  open,
  title,
  description,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
}: DeleteRowModalProps) => {
  const onConfirmClick = () => {
    onConfirm()
  }

  const onCancelClick = () => {
    onCancel?.()
  }

  return (
    <dialog open={open} id={id} className='modal'>
      <form method='dialog' className='modal-box overflow-visible flex flex-col gap-6'>
        <div>
          {title ? <h2 className='text-2xl mb-2'>{title}</h2> : <></>}
          {description ? <p>{description}</p> : <></>}
        </div>
        <div className='flex gap-3 justify-end'>
          <Button background='red' onClick={onCancelClick}>
            {cancelText}
          </Button>
          <Button onClick={onConfirmClick}>{confirmText}</Button>
        </div>
      </form>
      <form method='dialog' className='modal-backdrop'>
        <button>close</button>
      </form>
    </dialog>
  )
}
