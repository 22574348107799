import { APPOINTMENT_STATUS, SESSION_STATUS_TYPE } from 'types'

export const sessionStatusesLabels = {
  [APPOINTMENT_STATUS.SESSION_NOT_STARTED as string]: {
    label: 'Session not started',
    value: APPOINTMENT_STATUS.SESSION_NOT_STARTED,
    color: 'text-black',
  },
  [SESSION_STATUS_TYPE.NOT_STARTED as string]: {
    label: 'Prepping session',
    value: SESSION_STATUS_TYPE.NOT_STARTED,
    color: 'text-green-400',
  },
  [SESSION_STATUS_TYPE.STARTED as string]: {
    label: 'Session started',
    value: SESSION_STATUS_TYPE.STARTED,
    color: 'text-blue-600',
  },
  [SESSION_STATUS_TYPE.PUMP_RUNNING as string]: {
    label: 'Pump running',
    value: SESSION_STATUS_TYPE.PUMP_RUNNING,
    color: 'text-orange-400',
  },
  [SESSION_STATUS_TYPE.PUMP_PAUSED as string]: {
    label: 'Pump paused',
    value: SESSION_STATUS_TYPE.PUMP_PAUSED,
    color: 'text-yellow-400',
  },
  [SESSION_STATUS_TYPE.COMPLETED as string]: {
    label: 'Session complete',
    value: SESSION_STATUS_TYPE.COMPLETED,
    color: 'text-red-400',
  },
  [SESSION_STATUS_TYPE.SIGNED as string]: {
    label: 'Session signed',
    value: SESSION_STATUS_TYPE.SIGNED,
    color: 'text-green-700',
  },
  [APPOINTMENT_STATUS.SESSION_CANCELED as string]: {
    label: 'Session canceled',
    value: APPOINTMENT_STATUS.SESSION_CANCELED,
    color: 'text-gray-400'
  },
  [APPOINTMENT_STATUS.SESSION_DELETED as string]: {
    label: 'Session deleted',
    value: APPOINTMENT_STATUS.SESSION_DELETED,
    color: 'text-[#c6a2a2]'
  },
  [APPOINTMENT_STATUS.SESSION_NOSHOW as string]: {
    label: 'Session no show',
    value: APPOINTMENT_STATUS.SESSION_NOSHOW,
    color: 'text-[#c6a2a2]'
  },
}

export const getSessionStatusLabel = (key: string) => {
  return sessionStatusesLabels[key] || sessionStatusesLabels[SESSION_STATUS_TYPE.NOT_STARTED]
}
