import React from 'react'
import { Dropdown } from 'components/Dropdown'
import { IFilter, IColumn } from 'components/Table/types'
import { ReactComponent as Filter } from 'assets/icons/filter.svg'

interface DropdownFilterProps<T> {
  filter: IFilter<T>
  columns: IColumn<T>[]
  onChange: (value?: string) => void
  value: string
}
export const DropdownFilter = <T extends any>({ filter, value, columns, onChange }: DropdownFilterProps<T>) => {
  const filterColumn = columns.find((column) => column.key === filter.key)
  const placeholder = `Filter by ${filterColumn?.label ? filterColumn?.key?.toString() : ''}`

  return (
    <Dropdown
      key={placeholder}
      placeholder={placeholder}
      options={filter.options}
      icon={<Filter />}
      className='md:min-w-32 xl:w-64 h-8'
      onChange={({ target: { value } }) => onChange(value as string)}
      value={value}
    />
  )
}
