import React, { useState } from 'react'
import dayjs from 'dayjs'
import * as Toast from 'components/Toast'
import { useForm, Controller } from 'react-hook-form'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { AppointmentOutputDTO, APPOINTMENT_TYPE, APPOINTMENT_STATUS } from 'types'
import { useAppointments, useAuth } from 'hooks'
import { ReactComponent as EditIcon } from 'assets/icons/pencil.svg'
import { Input } from 'components/Input'
import { DurationList } from './constants'
import { appointmentTypeOptions } from '../Flowsheet/constants'
import { appointments as AppointmentActions } from 'data'
import { useNavigate } from 'react-router-dom'
import { TimeInput, isValidTime } from 'components/TimeInput'
import { useScrollIndicator } from 'hooks/useScrollIndicator'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import clsx from 'clsx'
import { InputDate, validateInputDate } from 'components/Input/InputDate'

export type CreateNextAppointment = {
  start: string
  startTime: string
  type: APPOINTMENT_TYPE | ''
  durationInMinutes: string
  comment: string | null
}

interface NextAppointmentDetailsProps {
  patientId: string
  isReadOnly?: boolean
}

interface NextAppointmentItemProps {
  appointment: AppointmentOutputDTO
  onEdit: (appointmentId: string) => void
  isReadOnly?: boolean
}

const defaultAppointment: CreateNextAppointment = {
  start: '',
  startTime: '',
  durationInMinutes: '',
  type: '',
  comment: null,
}

export const NextAppointmentItem: React.FC<NextAppointmentItemProps> = ({
  appointment,
  onEdit,
  isReadOnly
}) => {
  return (
    <>
      <div className='flex border-r px-6 py-2 gap-2 bg-athens-gray shadow-md'>
        <div className='font-bold text-base text-dark-gray'>
          {dayjs(appointment.start).format('MMM DD')} / {dayjs(appointment.start).format('hh:mm')} /{' '}
          {appointment.duration?.label} / {appointment.type}
        </div>
      </div>
      <div className='flex border-r px-6 py-2 gap-2 bg-athens-gray shadow-md'>
        <div className='text-dark-gray'>Comment:</div>
        <div className='font-bold text-base text-dark-gray'>{appointment.comment || '-'}</div>
      </div>
      <div className='flex items-center justify-center'>
        <Button
          disabled={isReadOnly}
          type='button'
          className='w-9 min-h-9 h-[36px] p-0'
          onClick={() => onEdit(appointment.id)}
        >
          <EditIcon />
        </Button>
      </div>
    </>
  )
}

interface FormCreateNextAppointmentProps {
  patientId: string
  onSave: () => void
  onToggle: () => void
  isCollapsed: boolean
}

export const FormCreateNextAppointment: React.FC<FormCreateNextAppointmentProps> = ({
  patientId,
  onSave,
  onToggle,
  isCollapsed
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { clinicId } = useAuth()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: defaultAppointment
  })

  const onSubmit = async (data: typeof defaultAppointment) => {
    try {
      setIsSubmitting(true)
      const duration = DurationList.find(durationItem => durationItem.minutes === +(data.durationInMinutes || 0))
      const start = new Date(`${data.start} ${data.startTime}`)

      const newAppointment = {
        patientId: patientId,
        clinicId,
        start: start.toISOString(),
        type: data.type,
        duration,
        comment: data.comment
      }

      await AppointmentActions.create(newAppointment)
      reset(defaultAppointment)
      onSave()
      Toast.success('Appointment saved successfully')
    } catch(err: any) {
      Toast.error(err?.message ?? 'Appointment Update/Creation Failed!')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, Toast.formErrors)} className='space-y-4'>
      <div className='flex justify-between'>
        <div className='flex gap-2 items-center'>
          <Button type="button" className='bg-white w-8 min-h-8 h-[16px] p-0' onClick={onToggle}>
            <DropdownIcon className={clsx({
              'rotate-180': !isCollapsed
            })} />
          </Button>
          <h2 className='text-2xl font-medium'>Next Appointment Details</h2>
        </div>
        <div>
          <Button disabled={!isDirty || isSubmitting} className='min-h-8 h-8 bg-secondary font-light lg:px-6' type='submit'>
            Create new appointment
          </Button>
        </div>
      </div>

      <div className={clsx('flex gap-4', {
        'hidden': isCollapsed
      })}>
        <div className='flex-1'>
          <InputDate
            label='Date'
            placeholder='Enter Date'
            {...register('start', {
              validate: value => {
                return validateInputDate(value);
              },
              required: 'start date is required'
            })}
            error={errors?.start?.message}
          />
        </div>
        <div>
          <Controller
            name="startTime"
            rules={{
              required: 'Start time should be selected',
              validate: value => {
                const [hour, minute] = value.split(':')
                const isValid = isValidTime(hour, minute)
                return isValid
              }
            }}
            control={control}
            render={({ field }) => {
              return (
                <TimeInput
                  value={field.value}
                  label='Start Time'
                  onChange={field.onChange}
                  error={errors.startTime?.message}
                />
              )
            }}
          />
        </div>
        <div className='flex-1'>
          <Controller
            name='durationInMinutes'
            rules={{
              required: 'Duration is required'
            }}
            control={control}
            render={({ field }) => (
              <Dropdown
                required
                value={field.value}
                label='Duration'
                className='h-12'
                background='white'
                placeholder='Choose Duration'
                onChange={(event) =>
                  event?.target?.value && !Array.isArray(event?.target?.value) && field.onChange(event.target.value)
                }
                options={DurationList.map(duration => ({
                  label: duration.label,
                  value: duration.minutes.toString()
                }))}
                error={errors.durationInMinutes?.message}
              />
            )}
          />
        </div>
        <div className='flex-1'>
          <Controller
            name='type'
            rules={{
              required: 'Type is required'
            }}
            control={control}
            render={({ field }) => (
              <Dropdown
                required
                value={field.value}
                label='Type'
                className='h-12'
                background='white'
                placeholder='Choose Type'
                onChange={(event) =>
                  event?.target?.value && !Array.isArray(event?.target?.value) && field.onChange(event.target.value)
                }
                options={appointmentTypeOptions}
                error={errors.type?.message}
              />
            )}
          />
        </div>
        <div className='flex-1'>
          <Controller
            name='comment'
            control={control}
            render={({ field }) => (
              <Input
                className='h-12'
                type='text'
                label='Comment'
                placeholder='Enter Comment'
                value={field.value || ''}
                onChange={(event) => {
                  field.onChange(event?.target?.value || null)
                }}
              />
            )}
          />
        </div>
      </div>
    </form>
  )
}

interface NextAppointmentsListProps {
  nextAppointments: AppointmentOutputDTO[],
  isReadOnly?: boolean
}

const NextAppointmentsList: React.FC<NextAppointmentsListProps> = ({ nextAppointments, isReadOnly }) => {
  const { clinicId } = useAuth()
  const [bottomTargetRef, showBottomScrollIndicator] = useScrollIndicator()
  const [topTargetRef, showTopScrollIndicator] = useScrollIndicator()
  const navigate = useNavigate()

  const onEdit = (appointmentId: string) => {
    navigate(`/clinics/${clinicId}/appointments/${appointmentId}`)
  }

  return (
    <div className='relative'>
      <div className={clsx('absolute top-0 right-[-12px] rotate-180', {
        'hidden': !showTopScrollIndicator
      })}>
        <DropdownIcon />
      </div>

      <div className='max-h-[152px] py-2 overflow-auto'>
        <div ref={topTargetRef} />

        <div className='grid grid-cols-[300px_auto_50px] gap-2'>
          {nextAppointments?.map(appointment => (
            <NextAppointmentItem
              key={`next-appointment-${appointment.id}`}
              appointment={appointment}
              onEdit={onEdit}
              isReadOnly={isReadOnly}
            />
          ))}
        </div>

        <div ref={bottomTargetRef} />
      </div>

      <div className={clsx('absolute bottom-0 right-[-12px]', {
        'hidden': !showBottomScrollIndicator
      })}>
        <DropdownIcon />
      </div>
    </div>
  )
}

export const NextAppointmentDetails: React.FC<NextAppointmentDetailsProps> = ({ patientId, isReadOnly }) => {
  const { isNextAppointmentDetailsCollapsed, setSession } = useAuth()
  const today = new Date()
  today.setHours(0)
  today.setSeconds(0)
  today.setMilliseconds(0)

  const { data: paginatedData, mutate, isLoading } = useAppointments({
    start_date: today.toISOString(),
    patientId,
    status: APPOINTMENT_STATUS.SESSION_NOT_STARTED
  })
  const nextAppointments = (paginatedData?.data || []).filter(appointment => !appointment.session?.status)

  const onSave = () => {
    mutate()
  }

  const onToggle = () => {
    setSession({
      isNextAppointmentDetailsCollapsed: !isNextAppointmentDetailsCollapsed
    })
  }

  if (isReadOnly && nextAppointments.length === 0) return null

  return (
    <div className='px-6 py-4 bg-light-blue rounded-lg space-y-4'>
      {!isReadOnly && (
        <div>
          <FormCreateNextAppointment
            patientId={patientId}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={isNextAppointmentDetailsCollapsed}
          />
        </div>
      )}

      {isLoading && <span>Loading ...</span>}

      {/* Next Appointments List */}
      {nextAppointments.length > 0 && (
        <div className={clsx({
          'hidden': isNextAppointmentDetailsCollapsed
        })}>
          <h4 className='font-medium text-base mb-3'>Patient's next appointment</h4>
          <NextAppointmentsList
            nextAppointments={nextAppointments}
            isReadOnly={isReadOnly}
          />
        </div>
      )}
    </div>
  )
}
