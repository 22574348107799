import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { FormResult, ScoreQuestion as ScoreQuestionType } from 'types/dist'

interface Props {
  question: ScoreQuestionType
  value: string | number
  disabled?: boolean
  setValue: UseFormSetValue<FormResult>
}

export const ScoreQuestion = ({ question, value, disabled, setValue }: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const val = e.target.value

    if (isNaN(Number(val))) {
      return
    }

    setValue(question.key, Number(val) > question.maxValue ? question.maxValue : Number(val))
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = e.key

    if (keyCode === 'Enter') {
      e.preventDefault()
    }

    if (keyCode === 'Backspace') {
      setValue(question.key, undefined)
    }
  }

  return (
    <div className='text-2xl font-semibold flex items-center gap-5 text-primary'>
      <input
        type='text'
        disabled={disabled}
        value={value === undefined ? '' : Number(value)}
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={question.key}
        className={`text-2xl text-center w-24 border-0 border-b-4 text-black ${disabled ? 'border-slate-400' : 'border-black'} focus:outline-none`}
      />
      <span>/</span>
      <span>{question.maxValue}</span>
    </div>
  )
}
