import React from 'react'
import { Input } from 'components/Input'
import { Control, Controller, useFormContext } from 'react-hook-form'
import { InfusionSession, SESSION_STATUS_TYPE } from 'types'
import { formatTimeToHHmm, formatTimeToDateTime } from 'lib/day'
import clsx from 'clsx'
import { Label } from './Label'
import dayjs from 'dayjs'

export const CommentSection = ({
  control,
  data,
}: {
  control: (triggerEvent: 'onBlur' | 'onChange') => Control<InfusionSession, any>
  data: InfusionSession
}) => {
  const { formState: { errors } } = useFormContext()
  const isSessionSigned = data.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data.status === SESSION_STATUS_TYPE.SESSION_CANCELED
  const isReadOnly = isSessionSigned || isSessionCanceled

  const { setValue } = useFormContext()

  const onIvTimeChange = (newValue: string, onBlurCallback: () => void) => {
    setValue('ivDcTime', newValue)
    onBlurCallback()
  }

  return (
    <div>
      {/* <div className='p-2 shadow-md rounded-md my-3'>
        <Controller
          name='commentsFood'
          control={control('onBlur')}
          render={({ field }) => (
            <Input label='Comments for this session' inline className='h-8' {...field} readOnly={isReadOnly} />
          )}
        />
      </div> */}
      <div className='hidden print:block'>
        <div className='p-2 px-4 shadow-md rounded-md my-3'>
          <Label
            className='font-medium mb-4'
          >
            Comments for this session
          </Label>

          <div>
            {data.infusionSessionComments?.map((sessionComment, index) => (
              <div key={`session-comment-${index}`} className='flex gap-4 mb-2 border-b-2 py-2 last:border-none'>
                <div className='font-bold'>{sessionComment.initials}</div>
                <div className='flex-1'>
                  {sessionComment.isAddendum && (
                    <span className='font-bold mr-2 text-sm'>[Addendum]</span>
                  )}
                  <span>
                    {sessionComment.comment}
                  </span>
                </div>
                <div className='text-xs font-bold'>
                  {dayjs(sessionComment.time).format('ddd DD, hh:mm A')}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='p-2 shadow-md rounded-md my-3'>
        <Controller
          name='commentsForNextSession'
          control={control('onBlur')}
          render={({ field }) => (
            <Input label='Comments for next session' inline className='h-8' {...field} readOnly={isReadOnly} />
          )}
        />
      </div>
      <div className='p-2 shadow-md rounded-md my-3 grid grid-cols-2 space-x-3'>
        <div className='flex flex-row justify-between leading-8 border-r'>
          <span className='mx-1 font-semibold '>Initials</span>
          <span className='mx-1'>{data.ivDcInitials}</span>
        </div>
        <Controller
          name='ivDcTime'
          control={control('onBlur')}
          render={({ field }) => (
            <Input
              id="ivDcTime"
              label='IV D/C @'
              labelClassName={clsx({
                'animate-text-error': !!errors?.ivDcTime
              })}
              inline
              className='h-8'
              type='time'
              {...field}
              readOnly={isReadOnly}
              value={field.value ? formatTimeToHHmm(field.value) : ''}
              onBlur={(e) => onIvTimeChange(formatTimeToDateTime(e.target.value), field.onBlur)}
            />
          )}
        />
      </div>
    </div>
  )
}
