import { sessionStatusesLabels } from 'constants/AppointmentStatuses'
import React from 'react'
import { APPOINTMENT_STATUS } from 'types'

interface AppointmentStatusLabelProps {
  status: string | undefined
}

export const AppointmentStatusLabel: React.FC<AppointmentStatusLabelProps> = ({ status }) => {
  if (!status) return <span className='text-gray'>Session not started</span>

  const { label, color } = sessionStatusesLabels[status] || sessionStatusesLabels[APPOINTMENT_STATUS.SESSION_NOT_STARTED]
  return <span className={color}>{label}</span>
}