import useSWR from "swr"
import { fields } from "data"
import { useAuth } from "./useAuth"

export const useFields = (query: any, optionsProp: any = {}) => {
  const {omit, ...options} = optionsProp
  const { data, error, mutate } = useSWR(!omit ? ['/fields'] : null, ([, query]: any[]) => fields.fetch(query), options)

  return {
    data,
    error,
    mutate,
    isLoading: !error && !data
  }
}

export const useFieldDictionary = () => {
  const auth = useAuth()
  const fieldsDictionary = auth.fieldsDictionary || {}

  const fieldLabel = (key: string, fallbackValue: string) => fieldsDictionary?.[key]?.fieldLabel || fallbackValue
  const fieldValues = (key: string, fallbackValue: any) => fieldsDictionary?.[key]?.values || fallbackValue

  return {
    fieldLabel,
    fieldValues
  }
}