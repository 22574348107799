import React from 'react'
import { cn } from 'lib/cn'

export const Logo = ({ className, ...props }: any) => {
  return (
    <div {...props}>
      <img alt='logo' src='/logo.png' className={cn(className)} />
    </div>
  )
}
