import React, { useState } from 'react'
import { cn } from 'lib/cn'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
import { InputBackgroundType, InputBorder, InputBorderType } from 'components/Input/types'
import { InputBackground } from './Input'
import { FormControl } from './FormControl'
import clsx from 'clsx'

export type Option = {
  label: string
  value: string
}

interface AutocompleteProps {
  placeholder?: string
  readOnly?: boolean
  fullWidth?: boolean
  focused?: boolean
  inline?: boolean
  disabled?: boolean
  error?: string
  value: string
  label?: string
  options: Option[]
  onChange: (value: string) => void
  background?: InputBackgroundType
  border?: InputBorderType
  inputClassName?: string
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  placeholder,
  readOnly,
  focused,
  error,
  inline,
  disabled,
  fullWidth = true,
  label,
  value,
  options,
  onChange: onChangeProp,
  background = 'default',
  inputClassName,
  border = 'default'
}) => {
  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label.toLowerCase().includes(query.toLowerCase())
      })

  const onChange = (value: string) => {
    onChangeProp(value)
  }

  return (
    <FormControl
      focused={focused}
      error={error}
      label={label}
      inline={inline}
      disabled={disabled}
      containerClassName={clsx({
        'w-full': fullWidth
      })}
    >
      <Combobox immediate value={value} onChange={onChange} onClose={() => setQuery('')}>
        <ComboboxInput
          readOnly={readOnly}
          displayValue={(selectedValue: string) => {
            const foundOption = options.find(eachOption => eachOption.value === selectedValue)
            return foundOption?.label || ''
          }}
          onChange={(event) => setQuery(event.target.value)}
          className={cn(
            'w-full input bg-inherit focus:outline-0 text-primary focus:ring-primary hover:border-base-content focus:border-primary cursor-pointer placeholder-gray-300',
            {
              'cursor-default focus:ring-0 focus:border-inherit hover:border-inherit': readOnly || disabled,
              'input-error focus:ring-error focus:border-error': error
            },
            InputBackground[background],
            InputBorder[border],
            inputClassName,
          )}
          placeholder={placeholder}
        />
        <ComboboxOptions anchor="bottom" className={clsx('w-[var(--input-width)] mt-1 rounded-xl border border-solid bg-white empty:hidden shadow-lg z-[1000]', {
          'hidden': readOnly
        })}>
          <div className='max-h-[200px] overflow-auto'>
            {filteredOptions.map((option) => (
              <ComboboxOption key={option.value} value={option.value} className="data-[focus]:bg-blue-100 p-2">
                {option.label}
              </ComboboxOption>
            ))}
          </div>
        </ComboboxOptions>
      </Combobox>
    </FormControl>
  )
}