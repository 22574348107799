import React from 'react'
import clsx from 'clsx'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Button } from 'components/Button'
import { Input, InputProps } from 'components/Input'
import { Field } from 'types'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Down } from 'assets/icons/down-arrow.svg'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'

interface FieldRecordProps extends Field {
  onSave: (field: Field) => Promise<Boolean>
  inputValueProps?: InputProps
  isCollapsed: boolean
  onToggle: (key: string, value: boolean) => void
}

export const FieldRecord: React.FC<FieldRecordProps> = ({
  id,
  fieldLabel,
  fieldId,
  values,
  onSave,
  onToggle,
  isCollapsed,
  inputValueProps
}) => {
  const { control, handleSubmit, reset, formState: { isDirty, errors }, register } = useForm({
    defaultValues: { id, fieldId, fieldLabel, values },
  })
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "values"
  })

  const onSubmit = async (data: Field) => {
    const saved = await onSave({
      id,
      fieldId,
      fieldLabel: data.fieldLabel,
      values: data.values
    })

    if (saved) {
      reset(data)
    }
  }

  const handleMoveUp = (index: number) => {
    if (index > 0) {
      move(index, index - 1)
    }
  }

  const handleMoveDown = (index: number) => {
    if (index < fields.length - 1) {
      move(index, index + 1)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='border-2 border-gray-100 rounded-lg'>
        <div className='flex gap-2 items-center p-6 hover:bg-light-blue cursor-pointer' onClick={() => onToggle(fieldId, !isCollapsed)}>
          <Button type="button" className='bg-white w-8 min-h-8 h-[16px] p-0'>
            <DropdownIcon className={clsx({
              'rotate-180': !isCollapsed
            })} />
          </Button>

          <div className={clsx('flex gap-2', {
            'items-end': !isCollapsed,
            'items-center': isCollapsed
          })}>
            {isCollapsed ? (
              <Input
                type="text"
                placeholder='Enter field label'
                {...register('fieldLabel', {
                  required: 'Field label is required'
                })}
                onClick={e => e.stopPropagation()}
                error={errors?.fieldLabel?.message}
              />
            ) : <h2 className='text-xl cursor-pointer'>{fieldLabel}</h2>}

            <div>
              <span className='font-bold'>(id: {fieldId})</span>
            </div>
          </div>
          
        </div>

        <div className={clsx({
          'hidden': !isCollapsed
        })}>
          <div className='p-6'>
            {fields.length === 0 && <span>No Values</span>}

            <div className='space-y-2'>
              {fields.map((field, index) => (
                <div key={field.id} className='flex gap-2'>
                  <Controller
                    name={`values.${index}.label`}
                    control={control}
                    defaultValue={field.label}
                    rules={{ required: 'Label is required' }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        inline
                        label="Label"
                        className='border-2 border-gray-300 rouned p-1'
                        placeholder='Label'
                        error={errors?.values?.[index]?.label?.message}
                      />
                    )}
                  />

                  <Controller
                    name={`values.${index}.value`}
                    control={control}
                    defaultValue={field.value}
                    rules={{ required: 'Value is required' }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        inline
                        label="Value"
                        className='border-2 border-gray-300 rouned p-1'
                        placeholder='Value'
                        error={errors?.values?.[index]?.value?.message}
                        {...inputValueProps}
                      />
                    )}
                  />

                  <button type="button" onClick={() => remove(index)} className='bg-white'>
                    <Close />
                  </button>

                  <button
                    type="button"
                    onClick={() => handleMoveDown(index)}
                    disabled={index === fields.length - 1}
                    className={clsx({
                      'hidden': index === fields.length - 1
                    })}
                  >
                    <Down />
                  </button>

                  <button
                    type="button"
                    onClick={() => handleMoveUp(index)}
                    disabled={index === 0}
                    className={clsx({
                      'hidden': index === 0
                    })}
                  >
                    <Down className='rotate-180' />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className='flex gap-2 p-6'>
            <Button
              type='button'
              className='min-h-8 h-8'
              onClick={() => append({ label: '', value: '' })}
            >
              Add Value
            </Button>

            <Button disabled={!isDirty} type='submit' className='min-h-8 h-8 bg-secondary'>Save</Button>
          </div>

        </div>
      </div>
    </form>
  )
}