import React from 'react'

export const navBarConfig = {
  menuLeft: [
    {
      label: <span>Today's Appointments</span>,
      url: '/today-appointments',
      adminOnly: false
    },
    {
      label: <span>Pumps</span>,
      url: '/pumps',
      adminOnly: false
    },
    {
      label: <span>Patients</span>,
      url: '/patients',
      adminOnly: false
    },
    {
      label: <span>Appointments</span>,
      url: '/appointments',
      adminOnly: false
    },
  ],
}
