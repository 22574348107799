import React, { useEffect } from 'react'
import { Button } from 'components/Button';
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { cn } from 'lib/cn'
import clsx from 'clsx';

interface DialogProps {
  children: React.ReactNode,
  open: boolean,
  onClose: () => void,
  className?: string,
  hasBorder?: boolean
}

export const Dialog: React.FC<DialogProps> = ({
  hasBorder,
  children,
  open,
  onClose,
  className
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose])

  const onCancelClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
  }

  return (
    <>
      <dialog open={open} className='modal backdrop-blur-sm' onClick={onClose}>
        <div
          className={cn('modal-box overflow-scroll h-auto hide-scrollbar relative', className)}
          onClick={onCancelClick}
        >
          <div className={clsx({
            'border-primary border rounded-xl p-2': hasBorder
          })}>
            <div className={clsx('absolute right-4 top-4', {
              'right-9 top-8': hasBorder
            })}>
              <Button type="button" className='bg-white border-none hover:bg-white' onClick={onClose}>
                <Close />
              </Button>
            </div>

            <div>
              {children}
            </div>
          </div>
        </div>
      </dialog>
    </>
  )
}