import React, { Dispatch, SetStateAction } from 'react'
import { FormResult, Question } from 'types/dist'
import { Button } from '../../../../components/Button'

interface FooterProps {
  questions: Question[]
  data: FormResult
  showSubmitScreen: boolean
  setShowSubmitScreen: Dispatch<SetStateAction<boolean>>
}

export const FormFooter = ({ questions, data, showSubmitScreen, setShowSubmitScreen }: FooterProps) => {
  const onSubmit = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()

    if (!questions.some((x) => data?.[x.key] === undefined)) {
      setShowSubmitScreen(true)
      return
    }
  }

  return (
    <div
      className='fixed bottom-0 left-0 w-full flex flex-col justify-center items-center px-24 py-6 bg-white shadow-lg'
      style={{
        borderTop: '1px solid #dee2e4',
        boxShadow: '0px -10px 15px 0px rgb(0,0,0,0.05)',
      }}
    >
      <div
        style={{
          width: '100%',
          marginBottom: 15,
          background: '#dee2e4',
          borderRadius: 5,
        }}
      >
        <div
          className='bg-primary'
          style={{
            height: 6,
            borderRadius: 5,
            transition: 'width .3s',
            width: `${questions.filter((x) => data?.[x.key] !== undefined).length * (100 / questions.length)}%`,
          }}
        />
      </div>
      <div className='flex w-full justify-between'>
        <div className='font-semibold text-xl'>
          {questions.filter((x) => data?.[x.key] !== undefined).length}/{questions.length}
        </div>
        <div>
          {showSubmitScreen && (
            <Button
              type='button'
              className='mr-3'
              onClick={() => {
                if (showSubmitScreen) {
                  setShowSubmitScreen(false)
                  return
                }
              }}
            >
              Back to Questions
            </Button>
          )}
          {showSubmitScreen ? (
            <Button key={'form-submit'} type='submit'>
              Submit Answers
            </Button>
          ) : (
            <Button
              key={'form-finish'}
              className='w-52 py-0'
              disabled={questions.some((x) => data?.[x.key] === undefined)}
              type='button'
              onClick={onSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
