import React, { useEffect, useState, useRef } from 'react'
import dayjs from 'dayjs'
import { useForm, FormProvider } from 'react-hook-form'
import { TopSection } from 'components/TopSection'
import { PatientHeaderSection } from 'components/PatientHeaderSection'
import { SessionSection } from 'components/SessionSection'
// import { GeneralMonitoringSection } from 'components/GeneralMonitoringSection'
import { MedSection } from 'components/MedSection'
import { CommentSection } from 'components/CommentSection'
import { useParams } from 'react-router-dom'
import * as Toast from 'components/Toast'
import { APPOINTMENT_TYPE, CurrentSessionInfoDTO, InfusionSession, NextSessionInfoDTO, PreviousSessionInfoDTO, SESSION_STATUS_TYPE } from 'types'
import {
  useAuth,
  useClinics,
  useCurrentSession,
  useFinishSession,
  useNextSession,
  usePreviousSession,
  useRegisterField,
  useSession,
  useSignSession,
  useStartSession,
  useTitle,
  useUpdateSession,
} from 'hooks'
import InfusionSessionHour from 'components/InfusionSessionHour'
import { Button } from 'components/Button'
import { useSessionContext } from 'components/SessionProvider'
import BlurredWrapper from 'components/BlurredWrapper'
import { SignSessionSection } from 'components/SignSessionSection'
import { SessionComments } from 'components/SessionComments'
import { deleteInfusionInterval } from 'data/session'

interface FlowsheetSectionsProps {
  clinicName: string
  data: InfusionSession
  previousSession: PreviousSessionInfoDTO
  nextSession: NextSessionInfoDTO
  currentSession: CurrentSessionInfoDTO
  lastTimeSaved: string
  onChange: (data: Partial<InfusionSession>) => Promise<boolean>
  startSession: () => Promise<boolean>
  endSession: () => Promise<boolean>
  signSession: () => Promise<boolean>
  deleteInfusionInterval: (hour: number, interval: number) => Promise<boolean>
  refreshData: () => void
}

export const getSectionBackground = (type: APPOINTMENT_TYPE) => {
  return type === APPOINTMENT_TYPE[APPOINTMENT_TYPE.UR1] ? 'bg-yellow-50' : 'bg-light-blue'
}
const FlowsheetSections = ({
  clinicName,
  data,
  previousSession,
  nextSession,
  currentSession,
  lastTimeSaved,
  onChange,
  startSession,
  endSession,
  signSession,
  refreshData,
  deleteInfusionInterval
}: FlowsheetSectionsProps) => {
  useTitle(`Session - ${data?.patient.firstName || ''} ${data?.patient.lastName || ''}`.trim())
  const commentSectionRef = useRef<HTMLDivElement>(null)
  const { canPump, updateSessionData, numHours, canAddHour, addNewHour } = useSessionContext()

  const form = useForm<InfusionSession>({ defaultValues: data })
  const { register } = useRegisterField(form, data, onChange)

  useEffect(() => {
    form.reset(data)
  }, [data, form])

  useEffect(() => {
    updateSessionData(data)
  }, [data, updateSessionData])

  const onStartSessionClick = async () => {
    if (data.status === SESSION_STATUS_TYPE.NOT_STARTED) {
      return await startSession()
    }
  }

  const validateSessionBeforeEnd = (session: InfusionSession) => {
    if (session.ivTime && !session.ivDcTime) {
      Toast.error('IV D/C time is missing')
      form.clearErrors('ivDcTime')
      setTimeout(() => {
        form.setError('ivDcTime', {
          type: 'manual',
          message: 'IV D/C Time is required'
        })
        commentSectionRef.current?.scrollIntoView({
          behavior: 'smooth'
        })
      }, 100)

      return false
    }

    return true
  }

  const onEndSessionClick = async () => {
    if (validateSessionBeforeEnd(data)) {
      return await endSession()
    }
  }
  const onSignSessionClick = async () => await signSession()

  const onRefreshDataClick = async () => {
    try {
      await refreshData()
    } catch (e) {
      console.error('E', e)
    }
  }

  const onMedicineSubmit = async (name: string, time: string, comments: string) => {
    const index = data.medicineAdministered?.length || 0
    await onChange({
      [`medicineAdministered.${index}.name`]: name,
      [`medicineAdministered.${index}.time`]: time,
      [`medicineAdministered.${index}.comments`]: comments,
    })
  }

  const isSessionSigned = data?.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED

  return (
    <div id={`flowsheet-${data.id}`} className='h-full w-full flex flex-col print:px-4 relative'>
      <FormProvider {...form}>
        <TopSection
          clinicName={clinicName}
          data={data}
          previousSession={previousSession}
          nextSession={nextSession}
          currentSession={currentSession}
          finishSession={onEndSessionClick}
          refreshData={onRefreshDataClick}
          lastTimeSaved={lastTimeSaved}
          control={register}
        />
        <PatientHeaderSection
          data={data}
          patient={data.patient}
          created={data.created || ''}
          control={register}
        />
        <SessionSection
          startSession={onStartSessionClick}
          data={data}
          previousSession={previousSession}
          control={register}
          onChange={onChange}
        />
        {/* <GeneralMonitoringSection control={register} data={data} /> */}
        {Array.from({ length: numHours }, (_, index) => index).map((hour) => (
          <InfusionSessionHour
            key={hour}
            canPump={canPump}
            data={data}
            onChange={onChange}
            hour={hour}
            deleteInfusionInterval={deleteInfusionInterval}
          />
        ))}
        <BlurredWrapper allow={canPump}>
          <div className='w-full flex justify-end'>
            <Button className='min-h-8 h-8 px-16 print:hidden' disabled={!canAddHour || isSessionSigned || isSessionCanceled || (numHours > 1 && !data?.infusionSessionHours?.[numHours-1]?.completed)} onClick={addNewHour}>
              Add New Hour
            </Button>
          </div>
          <MedSection onSubmit={onMedicineSubmit} data={data} />
        </BlurredWrapper>

        <div ref={commentSectionRef}>
          <CommentSection control={register} data={data} />
        </div>

        <SignSessionSection
          signSession={onSignSessionClick}
          control={register}
          data={data}
          finishSession={onEndSessionClick}
        />

        <SessionComments data={data} onChange={onChange} />
      </FormProvider>
    </div>
  )
}

export const Flowsheet = () => {
  const { clinicId } = useAuth()
  const { id } = useParams()
  const [lastTimeSaved, setLastTimeSaved] = useState('Synced')
  const { data, mutate, isLoading: sessionLoading } = useSession(id, { revalidateOnFocus: false })
  const { data: clinicData, loading: clinicLoading } = useClinics({ id: clinicId })
  const { data: previousSession } = usePreviousSession(id, { revalidateOnFocus: false })
  const { data: nextSession } = useNextSession(id, { revalidateOnFocus: false })
  const { data: currentSession } = useCurrentSession(id, { revalidateOnFocus: false })

  const { trigger: triggerSessionUpdate } = useUpdateSession(id)
  const { trigger: triggerSessionStart } = useStartSession(id)
  const { trigger: triggerSessionEnd } = useFinishSession(id)
  const { trigger: triggerSessionSign } = useSignSession(id)

  const isLoading = [sessionLoading, clinicLoading].some(isLoading => isLoading)

  const onChange = async (value: any) => {
    try {
      setLastTimeSaved('Saving...')
      await triggerSessionUpdate(value)
      mutate()
      setLastTimeSaved(`Last time saved at ${dayjs().format('hh:mm:ss a')}`)
      return true
    } catch (e: any) {
      console.log('E', e)
      Toast.error(e.message || 'Error trying to save changes')
      setLastTimeSaved('Error trying to save')
      return false
    }
  }

  const startSession = async () => {
    try {
      setLastTimeSaved('Starting session...')
      await triggerSessionStart()
      mutate()
      setLastTimeSaved(`Last time saved at ${dayjs().format('hh:mm:ss a')}`)
      return true
    } catch (e) {
      console.log('Error:', e)
      Toast.error('Error trying to save changes')
      setLastTimeSaved('Error trying to save')
      return false
    }
  }

  const endSession = async () => {
    try {
      setLastTimeSaved('Ending session...')
      await triggerSessionEnd()
      mutate()
      setLastTimeSaved(`Last time saved at ${dayjs().format('hh:mm:ss a')}`)
      return true
    } catch (e: any) {
      console.log('Error:', e)
      Toast.error(e.message || 'Error trying to save changes')
      setLastTimeSaved('Error trying to save')
      return false
    }
  }

  const signSession = async () => {
    try {
      setLastTimeSaved('Ending session...')
      await triggerSessionSign()
      mutate()
      setLastTimeSaved(`Last time saved at ${dayjs().format('hh:mm:ss a')}`)
      return true
    } catch (e) {
      console.log('Error:', e)
      Toast.error('Error trying to save changes')
      setLastTimeSaved('Error trying to save')
      return false
    }
  }

  const onDeleteInfusionInterval = async (hour: number, interval: number) => {
    try {
      setLastTimeSaved('Saving...')
      await deleteInfusionInterval({
        id,
        clinicId,
        hour,
        interval
      })
      mutate()
      setLastTimeSaved(`Last time saved at ${dayjs().format('hh:mm:ss a')}`)
      return true
    } catch (e: any) {
      console.log('E', e)
      Toast.error(e.message || 'Error trying to deleting infusion interval')
      setLastTimeSaved('Error trying to save')
      return false
    }
  }

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <span className='loader' />
      </div>
    )
  }

  return (
    data &&
    previousSession && nextSession && currentSession && (
      <FlowsheetSections
        key={`flowsheet-${data.id}`}
        clinicName={clinicData.name}
        previousSession={previousSession}
        nextSession={nextSession}
        currentSession={currentSession}
        startSession={startSession}
        deleteInfusionInterval={onDeleteInfusionInterval}
        endSession={endSession}
        signSession={signSession}
        refreshData={mutate}
        data={data}
        lastTimeSaved={lastTimeSaved}
        onChange={onChange}
      />
    )
  )
}