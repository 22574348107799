import React, { useCallback, useMemo, useState } from 'react'
import { pumps as PumpActions } from 'data'
import { Pump } from 'types'
import * as toast from 'components/Toast'
import { Button } from 'components/Button'
import { Table } from 'components/Table'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { AddNewPumpModal } from 'components/Modals/AddNewPump'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'
import { PumpsModelTable } from 'pages/private/Pumps/types'
import { usePumps, useTitle } from 'hooks'
import { EditPumpModal } from 'components/Modals/EditPump'
import clsx from 'clsx'
import { useUserSettings } from 'hooks/useUserSettings'

const defaultColumns: IColumn<PumpsModelTable>[] = [
  { label: 'Serial Number', key: 'serialNumber' },
  { label: 'Pump Number', key: 'pumpNumber' },
  { label: 'Hours Used', key: 'hoursUsed' },
  { label: 'Session Used', key: 'sessionsUsed' },
  { label: 'Action', key: 'action' },
]

export const Pumps = () => {
  useTitle('Pumps')
  const {
    getSortListingByScreen,
    getColumns,
    saveSortListing,
    saveColumns,
  } = useUserSettings()
  const [columns, setColumns] = useState(getColumns<IColumn<PumpsModelTable>[]>('pumps') || defaultColumns)
  const sortListingDefaults = getSortListingByScreen('pumps')
  const [showAddNew, setShowAddNew] = useState(false)
  const [orderBy, setOrderBy] = useState<IOrderBy<PumpsModelTable>>({
    fieldName: sortListingDefaults?.fieldName as keyof PumpsModelTable || 'pumpNumber',
    order: sortListingDefaults?.order || 'ASC'
  })
  const [selectedTab, setSelectedTab] = useState<'active' | 'inactive'>('active')
  const [selectedPump, setSelectedPump] = useState<Pump | null>(null)
  const [pumpActioned, setPumpActioned] = useState<Pump>()
  const { data, mutate, isLoading } = usePumps({
    orderby_field: orderBy.fieldName,
    orderby_order: orderBy.order,
  })

  const onPumpConfirm = async () => {
    try {
      await PumpActions.update({ ...pumpActioned, active: pumpActioned?.active === 1 ? 0 : 1 })
      await mutate()
      setPumpActioned(undefined)
    } catch (e: any) {
      toast.error(e.message || `Error trying to update. Try again later`)
    }
  }

  const onPumpCanceled = useCallback(() => {
    setPumpActioned(undefined)
  }, [])

  const onOrderBy = (options: IOrderBy<PumpsModelTable>) => {
    setOrderBy(options)
    saveSortListing({
      screenName: 'pumps',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const activePumps = useMemo(
    () =>
      data
        ?.filter((pump) => pump.active)
        ?.map((pump) => ({
          id: pump.id,
          serialNumber: pump.serialNumber,
          pumpNumber: pump.pumpNumber,
          hoursUsed: pump.hoursUsed,
          sessionsUsed: pump.sessionsUsed,
          action: {
            render: (
              <Button
                onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                  e?.stopPropagation()
                  setPumpActioned(pump)
                  openConfirmationModal('pumpConfirmationModal')
                }}
                fullWidth
              >
                Deactivate
              </Button>
            ),
            value: 'Deactivate',
          },
        })) || [],
    [data]
  )

  const inactivePumps = useMemo(
    () =>
      data
        ?.filter((pump) => !pump.active)
        ?.map((pump) => ({
          id: pump.id,
          serialNumber: pump.serialNumber,
          pumpNumber: pump.pumpNumber,
          hoursUsed: pump.hoursUsed,
          sessionsUsed: pump.sessionsUsed,
          action: {
            render: (
              <Button
                onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                  e?.stopPropagation()
                  setPumpActioned(pump)
                  openConfirmationModal('pumpConfirmationModal')
                }}
                fullWidth
              >
                Reactivate
              </Button>
            ),
            value: 'Reactivate',
          },
        })) || [],
    [data]
  )

  const onAddNewPumpClick = () => {
    setShowAddNew(true)
  }

  const onPumpClick = (pumpId: string) => {
    const foundPump = data?.find(pump => pump.id === pumpId)
    if (foundPump) {
      setSelectedPump(foundPump)
    }
  }

  const onPumpSaved = () => {
    mutate()
    toast.success('Pump saved successfully')
    setShowAddNew(false)
    setSelectedPump(null)
  }

  const onCloseModal = useCallback(() => setSelectedPump(null), [])

  const getConfirmationModalDescription = () => {
    if (pumpActioned?.active === 0) {
      return 'Please confirm if you want to reactivate this pump'
    }
    return 'Please confirm if you want to deactivate this pump'
  }

  const getConfirmationModalTitle = () => {
    if (pumpActioned?.active === 0) {
      return `Reactivate pump #${pumpActioned.serialNumber}`
    }
    return `Deactivate pump #${pumpActioned?.serialNumber || ''}`
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<PumpsModelTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'pumps',
      columns: tableSettings.columns,
    })
  }

  return (
    <>
      <div className='flex flex-col gap-y-10 pb-8'>
        <ConfirmationModal
          id='pumpConfirmationModal'
          title={getConfirmationModalTitle()}
          description={getConfirmationModalDescription()}
          onConfirm={onPumpConfirm}
          onCancel={onPumpCanceled}
        />
        <div className='w-full flex justify-between items-center p-6 border shadow-md rounded-md'>
          <h1 className='text-2xl'>Pumps</h1>
          <Button background='green' onClick={onAddNewPumpClick}>
            Add new pump
          </Button>
        </div>
        <div className='flex'>
          <div
            className={clsx('text-center flex-1 border-white border-b p-2 rounded-tl-lg rounded-tr-lg', {
              'bg-light-blue font-medium': selectedTab === 'active'
            })}
            role='button'
            onClick={() => setSelectedTab('active')}
          >
            Active
          </div>
          <div
            role='button'
            className={clsx('text-center flex-1 border-white border-b p-2 rounded-tl-lg rounded-tr-lg', {
              'bg-light-blue font-medium': selectedTab === 'inactive'
            })}
            onClick={() => setSelectedTab('inactive')}
          >
            Inactive
          </div>
        </div>
        {isLoading ? (
          <span className='loading loading-spinner loading-lg m-auto'></span>
        ) : (
          <>
            {selectedTab === 'active' && (
              <Table
                title="Active Pumps"
                onRowClick={onPumpClick}
                showGrid
                showSearch={false}
                columns={columns}
                rows={activePumps}
                orderBy={orderBy}
                onOrderBy={onOrderBy}
                onSaveSettings={onSaveTableSettings}
              />
            )}
            {selectedTab === 'inactive' && (
              <Table
                title="Inactive Pumps"
                onRowClick={onPumpClick}
                showGrid
                showSearch={false}
                columns={columns}
                rows={inactivePumps}
                orderBy={orderBy}
                onOrderBy={onOrderBy}
                onSaveSettings={onSaveTableSettings}
              />
            )}
          </>
        )}
      </div>

      {!!selectedPump && (
        <EditPumpModal
          onSave={onPumpSaved}
          onClose={onCloseModal}
          pump={selectedPump}
        />
      )}

      {showAddNew && (
        <AddNewPumpModal
          onSave={onPumpSaved}
          onClose={() => setShowAddNew(false)}
        />
      )}
    </>
  )
}
