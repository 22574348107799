import { FormResult, FormUserType, Question, Section, SectionAnswers, SelectQuestion } from 'types/dist'

export const getQuestions = (sections: Section[], userType: FormUserType): Question[] =>
  sections
    .filter(({ userType: ut }) => userType === FormUserType.PHYSICIAN || ut === userType)
    .map(({ questions }) => questions)
    .flat()

export const getScoreWeights = (
  questions: Question[],
  data: FormResult,
): { key: string; value: number | undefined }[] =>
  Object.entries(data).map(([key, val]) => {
    const scoreWeight = (questions.find(({ key: k }) => k === key) as SelectQuestion)?.variants?.find(
      ({ value }) => value === val,
    )?.scoreWeight

    return { key, value: scoreWeight }
  })

export const getSectionAnswers = (sections: Section[], data: FormResult): SectionAnswers[] =>
  sections.map((section) => {
    const answers = Object.entries(data).filter(([key]) => section.questions.some((x) => x.key === key))

    if (answers.length) {
      return Object.fromEntries(answers)
    }

    return null
  })

export const getAnswersFromSections = (sectionAnswers: SectionAnswers[]): FormResult => {
  const answers: FormResult = {}

  sectionAnswers.forEach((section) => {
    if (section) {
      Object.entries(section).forEach(([key, value]) => {
        answers[key] = value
      })
    }
  })

  return answers
}
