import React, { ChangeEvent, KeyboardEvent, forwardRef, useRef, useState } from 'react';
import { Input, InputProps } from './index';

type InputPhoneProps = Omit<InputProps, 'onChange'> & {
  onChange: (value: string) => void
}

export const DEFAULT_AREA_CODE = '+1'

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>((inputProps, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isBackspacing, setIsBackspacing] = useState(false);

  const formatPhoneNumber = (input: string): string => {
    let cleanedInput = input.replace(/\D/g, '');
    cleanedInput = cleanedInput.replace(/^0/, '');

    let formattedPhoneNumber = '';

    for (let i = 0; i < cleanedInput.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += ' ';
      }
      formattedPhoneNumber += cleanedInput[i];
    }

    return formattedPhoneNumber.trim().slice(0, 12);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = e.target.selectionStart;
    const isAtEnd = cursorPosition === e.target.value.length;

    if (isBackspacing || !isAtEnd) {
      // Skip formatting if backspacing or cursor is not at the end
      inputProps.onChange(e.target.value);
      return;
    }

    const rawValue = e.target.value;
    const formattedPhoneNumber = formatPhoneNumber(rawValue);

    if (inputProps.onChange) {
      inputProps.onChange(formattedPhoneNumber);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      setIsBackspacing(true);
    } else {
      setIsBackspacing(false);
    }
  };

  const handleBlur = () => {
    if (inputRef.current) {
      const formattedPhoneNumber = formatPhoneNumber(inputRef.current.value);
      if (inputProps.onChange) {
        inputProps.onChange(formattedPhoneNumber);
      }
    }
  };

  return (
    <Input
      {...inputProps}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      maxLength={12}
      ref={(instance) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(instance);
          } else {
            ref.current = instance;
          }
        }
        inputRef.current = instance;
      }}
    />
  );
});
