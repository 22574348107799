import { useMemo } from 'react'
import { PreFlightBsReading, InfusionSessionHour, InfusionSessionInterval } from 'types'
import { useAuth } from './useAuth'

interface Row {
  row?: PreFlightBsReading | InfusionSessionInterval | InfusionSessionHour
}

interface CanEditParams extends Row {
  rowNumber: number
  activeRow: number
}

interface PumpingSectionEditClassParams {
  rowNumber: number
  activeRow: number
  attribute: keyof InfusionSessionInterval
  row?: InfusionSessionInterval
}

interface RolePrivilege {
  canEdit: (params: CanEditParams) => boolean
  pumpingSectionEditClass: (params: PumpingSectionEditClassParams) => string
  getInitials: () => string
}

const useRolePrivilege = (): RolePrivilege => {
  const { userDetail } = useAuth()

  // Memoize userDetail
  const memoizedUserDetail = useMemo(() => userDetail, [userDetail])

  const canEdit = ({ rowNumber, activeRow, row }: CanEditParams): boolean => {
    if (rowNumber === activeRow - 1) return true
    if (memoizedUserDetail?.role === 'administrator' || memoizedUserDetail?.role === 'provider') return true
    if (row && row.initials === memoizedUserDetail?.initials && rowNumber === activeRow - 2) return true
    return false
  }

  const pumpingSectionEditClass = ({ rowNumber, activeRow, row, attribute }: PumpingSectionEditClassParams): string => {
    let className = ''

    if (rowNumber === activeRow) {
      className = 'bg-green-50 shadow-bottom'
    }
    if (memoizedUserDetail?.role === 'clinical-staff' && rowNumber !== activeRow) {
      className += ' pointer-events-none'
    }
    // no matter the row if same user, the he can edit that row
    if (row?.initials === memoizedUserDetail?.initials || (attribute === 'pumpPaused' && rowNumber === activeRow - 1)) {
      className = className.replace(' pointer-events-none', '')
    }
    // these two conditions over ride all
    if (row && row.pumpPaused && (attribute === 'drValue' || attribute === 'qValue')) {
      className += ' pointer-events-none'
    }
    if (rowNumber > activeRow) {
      className += ' pointer-events-none'
    }

    return className
  }

  const getInitials = () => {
    return userDetail?.initials ?? ''
  }
  return { canEdit, pumpingSectionEditClass, getInitials }
}

export default useRolePrivilege
