import { IOption } from 'components/Dropdown/types'

export interface IColumn<T> {
  /**
   * label: the header that will be rendered in the column cell
   */
  label: string | number
  /**
   * key: refers to the row's key that should be used to get the value of that column in an specific cell
   */
  key: keyof T
}

export type IComplexCell = {
  /*
   *render: refers to the JSX Element that will be rendered when the cell needs to be treated different
   */
  render: JSX.Element
  /*
   *value: the actual value of the cell, used to filter values of the table.
   */
  value: string | number
}

/**
 * isInstanceOfComplexCell:  used to determine wether
 */
export const isInstanceOfComplexCell = (object: any) => {
  return typeof object === 'object' && 'render' in object && 'value' in object
}

export type ICell = string | number | IComplexCell

/**
 * IRow: represents each of the table's rows
 */
export type IRow<T> = {
  /**
   * [key in keyof T]: Can take any key of T here
   */
  [key in keyof T]: ICell
} & {
  /**
   * id: The row's id.
   */
  id: string
}

/**
 * The table's filters list. Only need to indicate the key of T that will be used to filter rows and the
 * possible values. Each value will be displayed as an option in the dropdown
 */
export type IFilter<T> = {
  key: keyof T
  options: IOption[]
}

export type IOrderBy<T> = {
  fieldName: keyof T
  order: 'ASC' | 'DESC'
}

/**
 * TableProps: Receives a generic argument T that will be used by the filter, columns, and rows.
 * T tells Typescript the properties structure of each row.
 *  - title: The value of the H2 title. Optional
 *  - columns: List of columns
 *  - className: Any custom class you may add to the main wrapper
 *  - showSearch: if false the search input will be hidden. Default is true
 *  - filters: the list of filters. Each filter will render a dropdown containing the possible options
 *  - onRowClick: the callback function that will be triggered when a row is clicked. The rowId is provided.
 */
export interface TableProps<T> {
  title?: string
  columns: IColumn<T>[]
  rows: IRow<T>[]
  className?: string
  showSearch?: boolean
  showGrid?: boolean
  filters?: IFilter<T>[]
  orderBy?: IOrderBy<T>,
  onRowClick?: (id: string) => void
  onOrderBy?: (orderBy: IOrderBy<T>) => void
  onEmptyState?: JSX.Element
  onSaveSettings?: (settings: TableSettings) => void
}

export interface TableSettings {
  columns: IColumn<string>[]
}
