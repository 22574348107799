import { config } from 'config'
import { post } from 'lib/api'
import { toId } from 'lib/utils'
import { get as getFromStorage } from 'lib/storage'

export const trackLogin = (id: string, email: string) => post(`/user-history${toId(id)}/track-login`, { email })
export const trackLogout = async () => {
  const userDetail = getFromStorage('userDetail')
  if (!userDetail) return

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
  const path = `/user-history${toId(userDetail.id)}/track-logout`
  const url = /https?/.test(path) ? path : `${config.api.url}${path}`
  const body = JSON.stringify({ email: userDetail.email })

  await fetch(url, { body, method: 'POST', headers })
}