import React, { useState, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { useAuth } from 'hooks'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { Button } from 'components/Button'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import { useScrollIndicator } from 'hooks/useScrollIndicator'
import { Search } from 'components/Search'

interface Props {
  title?: string
  setSelectedClinic: (clinicId: string) => void
  onClose?: () => void
}

export const SelectClinicModal: React.FC<Props> = ({ setSelectedClinic, title, onClose }) => {
  const [searchTxt, setSearchTxt] = useState<string>('')
  const [bottomTargetRef, showBottomScrollIndicator] = useScrollIndicator()
  const [topTargetRef, showTopScrollIndicator] = useScrollIndicator()
  const { userDetail } = useAuth()
  const dialogRef = useRef<HTMLFormElement | null>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose])

  const onSelectClinic = (clinicId: string) => {
    setSelectedClinic(clinicId)
  }

  const options = useMemo(() => {
    const result = userDetail?.memberships?.map((m) => ({
      label: m.clinic.name ?? '',
      value: m.clinic.id,
      description: `${m.clinic?.streetAddress ?? '' + m.clinic?.city ?? ''}`,
    })) ?? []

    if (searchTxt.trim().length > 0) {
      return result.filter(option => option.label.toLowerCase().includes(searchTxt.toLowerCase()))
    }

    return result
  }, [userDetail, searchTxt])

  const onSearch = (txt: string) => {
    setSearchTxt(txt)
  }

  const onCancelClick: React.MouseEventHandler<HTMLFormElement> = e => {
    e.stopPropagation()
  }

  return (
    <dialog open className='modal backdrop-blur-sm' onClick={onClose}>
      <form
        ref={dialogRef}
        method='dialog'
        className='modal-box overflow-visible h-auto relative'
        onClick={onCancelClick}
      >
        {!!onClose && (
          <div className='absolute right-2 top-4'>
            <Button type="button" className='bg-white border-none hover:bg-white p-0 min-h-[20px] h-[20px]' onClick={onClose}>
              <Close />
            </Button>
          </div>
        )}
        <p className='font-medium text-2xl my-4'>{title || 'Please Select Clinic to Log into:'}</p>

        <div className='mb-2'>
          <Search
            className='w-full'
            placeHolder='Search for clinics'
            onSearch={onSearch}
          />
        </div>

        <div className='relative'>
          <div className={clsx('absolute z-50 top-0 -right-3 rotate-180', {
            'hidden': !showTopScrollIndicator
          })}>
            <DropdownIcon />
          </div>

          <div className='h-[244px] overflow-auto'>
            <div ref={topTargetRef} />
            {options.length === 0 && (
              <div className='flex justify-center pt-2'>
                Nothing to show
              </div>
            )}
            <ul>
              {options.map(option => (
                <li
                  key={`clinic-option-${option.value}`}
                  className='py-3 border-b-[1px] last:border-none cursor-pointer hover:bg-athens-gray active:bg-light-blue whitespace-nowrap text-ellipsis overflow-hidden'
                  onClick={() => onSelectClinic(option.value)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
            <div ref={bottomTargetRef} />
          </div>

          <div className={clsx('absolute bottom-0 -right-3', {
            'hidden': !showBottomScrollIndicator
          })}>
            <DropdownIcon />
          </div>
        </div>


      </form>
    </dialog>
  )
}