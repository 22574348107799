import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react'
import { BLOOD_SUGAR_LIMIT } from 'constants/FlowSheet'
import { SESSION_STATUS_TYPE, SessionModelEntity } from 'types'

interface SessionContextType {
  sessionData: Partial<SessionModelEntity>
  updateSessionData: (data: Record<string, any>) => void
  canPump: boolean
  addNewHour: () => void
  numHours: number
  canAddHour: boolean
  updateAddHour: (can: boolean) => void
  isSessionStarted: () => boolean
}

const SessionContext = createContext<SessionContextType | undefined>(undefined)

export const useSessionContext = (): SessionContextType => {
  const context = useContext(SessionContext)
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider')
  }
  return context
}

interface SessionProviderProps {
  children: ReactNode
}

export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const [sessionData, setSessionData] = useState<Partial<SessionModelEntity>>({})
  const [canPump, setCanPump] = useState<boolean>(false)
  const [numHours, setNumHours] = useState<number>(1)
  const [canAddHour, setCanAddHour] = useState<boolean>(false)

  const updateSessionData = useCallback((data: Record<string, any>): void => {
    setSessionData({ ...data })
  }, []) 

  const updateAddHour = useCallback((can: boolean) => {
    setCanAddHour(can)
  }, [])

  const addNewHour = () => {
    setNumHours(numHours + 1)
    setCanAddHour(false)
  }

  const isSessionStarted = () => {
    return sessionData?.status !== SESSION_STATUS_TYPE.NOT_STARTED
  }
  useEffect(() => {
    setNumHours(sessionData.infusionSessionHours?.length || 1)
    if (!sessionData.preFlightBsReadings) return
    for (const reading of sessionData.preFlightBsReadings) {
      const values = Object.values(reading).filter((r) => !!r)
      if (
        values.length > 0 &&
        reading.value &&
        +reading.glucose >= 0 &&
        (reading.value > BLOOD_SUGAR_LIMIT || sessionData.hr1ForceProceedInfusion || sessionData.status === SESSION_STATUS_TYPE.SIGNED) &&
        sessionData.status !== SESSION_STATUS_TYPE.NOT_STARTED &&
        !!sessionData?.infusionSessionHours?.[0]?.completed
      ) {
        setCanPump(true)
        return
      }
    }
    setCanPump(false)
  }, [sessionData])

  const contextValue: SessionContextType = {
    sessionData,
    updateSessionData,
    addNewHour,
    canPump,
    numHours,
    updateAddHour,
    canAddHour,
    isSessionStarted
  }

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>
}
