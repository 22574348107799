import * as api from 'data/patient/api'
import { CreatePatientDTO, UpdatePatientDTO } from 'types'

export const fetch = (data: any) => {
  return api.fetch(data)
}

export const create = (data: CreatePatientDTO) => {
  return api.create(data)
}

export const update = (data: UpdatePatientDTO & { id: string }) => {
  return api.update(data)
}