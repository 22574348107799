import React, { useState } from 'react'
import { KeyedMutator } from "swr";
import { ToggleSwitch } from '../../../../components/ToggleSwitch'
import { assignmentRules as AssignmentRulesActions } from 'data'

interface Props {
  isActive: boolean
  patientId: string
  assignmentRuleId: string
  mutate: KeyedMutator<any>
}

export const AssignmentActive = ({ isActive, patientId, assignmentRuleId, mutate }: Props) => {
  const [active, setActive] = useState(isActive)

  const onChange = async (event: { target: any, type?: any }) => {
    const value = event.target.value

    setActive(value)

    await AssignmentRulesActions.update({ patientId, id: assignmentRuleId, active: value })

    await mutate()
  }

  return (
    <ToggleSwitch
      checked={active}
      onChange={onChange}
      label={active ? 'Yes' : 'No'}
    />
  )
}
