import React, { useMemo, useState, useRef } from 'react'
import * as Toast from 'components/Toast'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Dropdown } from 'components/Dropdown'
import { Checkbox } from 'components/Checkbox'
import { gaugeOptions, insulinConcentrationOptions, insulinOptions, insulinPumpOptions, ivSiteOptions, targetDropdownOptions } from 'pages/private/Flowsheet/constants'
import { Control, Controller, useFormContext, useWatch } from 'react-hook-form'
import { InfusionSession, PreviousSessionInfoDTO, SESSION_STATUS_TYPE, TARGET_TYPE } from 'types'
import { getSectionBackground, getStatusLabel } from 'pages/private'
import { ReactComponent as ArrowLeft } from 'assets/icons/left-caret.svg'
import dayjs from 'dayjs'
import { formatTimeToDateTime, formatTimeToHHmm } from 'lib/day'
import clsx from 'clsx'
import { useFieldDictionary } from 'hooks/useFields'

export const SessionSection = ({
  control,
  startSession,
  previousSession,
  data,
  onChange
}: {
  control: (triggerEvent: 'onBlur' | 'onChange') => Control<InfusionSession, any>
  startSession?: () => Promise<boolean | undefined>
  previousSession: PreviousSessionInfoDTO
  data?: InfusionSession
  onChange: (data: Partial<InfusionSession>) => void
}) => {
  const { setValue, watch, control: formControl } = useFormContext()
  const customTargetContainerRef = useRef<HTMLDivElement>(null)
  const { fieldLabel, fieldValues } = useFieldDictionary()

  const onIvTimeChange = (newValue: string, onBlurCallback: () => void) => {
    setValue('ivTime', newValue)
    onBlurCallback()
  }

  const appointmentType = useWatch({ control: control('onChange'), name: 'type' })
  const [isLoading, setIsLoading] = useState(false)
  const bgColor = getSectionBackground(appointmentType)

  const mealFields = !data?.npo ? ['meal', 'mealTime'] : []
  const watchFields = useWatch({ name: ['insulin', 'insulinConcentration', ...mealFields] })
  const target = watch('target');
  const landing = watch('landing')
  const targetMin = watch('targetMin')
  const targetMax = watch('targetMax')

  const isStartSessionEnabled = useMemo(() => {
    const requiredFieldsNotEmpty = watchFields.every((fieldValue) => fieldValue?.length > 0)

    if (!requiredFieldsNotEmpty) return false
    if (!(landing > 0)) return false

    if (target === TARGET_TYPE.CUSTOM) {
      if (!targetMin || !targetMax) return false
      if ((String(targetMin).trim().length === 0 || String(targetMax).trim().length === 0)) {
        return false
      }
      if (+targetMin >= +targetMax) return false
    } else {
      return target?.length > 0
    }

    return true
  }, [watchFields, target, targetMin, targetMax, landing])

  const isSessionStarted = data?.status !== SESSION_STATUS_TYPE.NOT_STARTED
  const isSessionSigned = data?.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED
  const isReadOnly = isSessionSigned || isSessionCanceled

  const onStartSessionClick = async () => {
    if (startSession) {
      setIsLoading(true)
      await startSession()
      setIsLoading(false)
    }
  }

  const onResetTarget = async () => {
    setValue('target', '')
    onChange({
      [`target`]: undefined,
      [`targetMin`]: '',
      [`targetMax`]: '',
    })
  }

  const onTargetChange = (value: TARGET_TYPE) => {
    setValue('target', value)
    if (data?.target !== value) {
      onChange({
        target: value
      })
    }
  }

  const onLandingChange = (strValue: string) => {
    const value = strValue.replace(/[^0-9]/g, '') || 0

    setValue('landing', +value)
    onChange({ landing: +value})
  }

  const onCustomTargetBlur: React.FocusEventHandler<HTMLDivElement> = (e) => {
    if (customTargetContainerRef.current && !customTargetContainerRef.current.contains(e.relatedTarget as Node)) {
      if (+targetMin >= +targetMax) {
        Toast.error('Min target cannot be greater than max target ')
        return
      }

      onChange({
        targetMin,
        targetMax,
      })
    }
  }

  return (
    <div className={`py-4 ${bgColor} -mx-8 mt-4`}>
      <div className='mx-8 my-5'>
        <div className='flex justify-between bg-white w-full shadow-md rounded-md px-3 py-4'>
          <span className='text-slate-500'>
            {previousSession.previous
              ? previousSession.previous?.comment ?? 'No Previous Session Notes'
              : 'No Previous Session'}
          </span>
          <span className='text-dark-gray font-bold'>Last Session Notes</span>
        </div>
        <div className='my-4'>
          <div className='flex flex-col gap-y-4'>
            <div className={clsx('flex gap-x-4', {
              'print:hidden': !!data?.npo
            })}>
              <div>
                <Controller
                  name='npo'
                  control={control('onChange')}
                  render={({ field }) => (
                    <Checkbox
                      label='Fasting'
                      readOnly={isReadOnly}
                      checked={field.value}
                      onChange={({ target: { value } }) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
              </div>

              <div className='flex-1'>
                <Controller
                  name='meal'
                  control={control('onBlur')}
                  render={({ field }) => (
                    <Input
                      disabled={data?.npo}
                      label='Meal'
                      className='h-8'
                      {...field}
                      value={data?.npo ? '' : field.value}
                      readOnly={isReadOnly}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name='mealTime'
                  control={control('onBlur')}
                  render={({ field }) => (
                    <Input
                      disabled={data?.npo}
                      label='Time'
                      className='h-8'
                      background='white'
                      type='time'
                      {...field}
                      value={data?.npo ? '' : field.value ? formatTimeToHHmm(field.value) : ''}
                      onBlur={(e) => {
                        setValue('mealTime', e.target.value ? formatTimeToDateTime(e.target.value, data?.startedAt) : '')
                        field.onBlur()
                      }}
                      readOnly={isReadOnly}
                    />
                  )}
                />
              </div>
            </div>

            <div className='flex gap-2 sm:flex-col-reverse lg:flex-row'>
              <div className='grid grid-cols-4 gap-4 items-end justify-between flex-1 sm:grid-cols-2 lg:grid-cols-4'>
                {target === TARGET_TYPE.CUSTOM && (
                  <div>
                    <div className='flex gap-2 items-end'>
                      <div className='flex flex-col'>
                        <div className='mb-[4px] font-medium'>Target</div>
                        <Button
                          type="button"
                          className='bg-white w-9 min-h-[32px] h-[32px] p-0'
                          onClick={onResetTarget}
                          disabled={isReadOnly}
                        >
                          <ArrowLeft />
                        </Button>
                      </div>

                      <div
                        ref={customTargetContainerRef}
                        onBlur={!isReadOnly ? onCustomTargetBlur : undefined}
                        className='flex gap-2'
                      >
                        <div>
                          <Controller
                            name='targetMin'
                            control={formControl}
                            render={({ field }) => (
                              <Input
                                label='Min'
                                type='number'
                                className={clsx('w-[60px] h-8')}
                                background='white'
                                readOnly={isReadOnly}
                                {...field}
                                value={field.value || ''}
                              />
                            )}
                          />
                        </div>

                        <div>
                          <Controller
                            name='targetMax'
                            control={formControl}
                            render={({ field }) => (
                              <Input
                                label='Max'
                                type='number'
                                className={clsx('w-[60px] h-8')}
                                background='white'
                                readOnly={isReadOnly}
                                {...field}
                                value={field.value || ''}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {target !== TARGET_TYPE.CUSTOM && (
                  <Controller
                    name='target'
                    control={control('onChange')}
                    render={({ field }) => (
                      <Dropdown
                        label='Target'
                        required
                        options={targetDropdownOptions}
                        className='h-8'
                        background='white'
                        placeholder='Choose target'
                        readOnly={isReadOnly}
                        {...field}
                        onChange={e => onTargetChange(e.target.value as TARGET_TYPE)}
                      />
                    )}
                  />
                )}

                <Controller
                  name='landing'
                  control={control('onBlur')}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      avoidNumberSpecialChars
                      type='number'
                      label='Landing'
                      className='h-8'
                      background='white'
                      placeholder='Type here...'
                      readOnly={isReadOnly}
                      {...field}
                      onBlur={(e) => onLandingChange(e.target.value)}
                      
                    />
                  )}
                />
                <Controller
                  name='insulin'
                  control={control('onChange')}
                  render={({ field }) => (
                    <Dropdown
                      options={fieldValues('insulin', insulinOptions)}
                      label={fieldLabel('insulin', 'Insulin')}
                      required
                      className='h-8'
                      background='white'
                      placeholder='Choose insulin'
                      readOnly={isReadOnly}
                      {...field}
                    />
                  )}
                />
                <div className='w-full'>
                  <div className='flex justify-between gap-2 items-baseline'>
                    <span className='font-medium'>{fieldLabel('insulinConcentration', 'Concentration')}</span>
                    <span className='text-primary text-nowrap'>
                      {data?.appointment?.a1c ? `A1C - ${parseFloat(String(data?.appointment?.a1c)).toFixed(2)} %` : ''}
                    </span>
                    {!!data?.appointment?.a1cDate && (
                      <span className='text-nowrap text-sm'>
                        ({dayjs(data.appointment.a1cDate).format('MMM DD, YYYY')})
                      </span>
                    )}
                  </div>
                  <Controller
                    name='insulinConcentration'
                    control={control('onChange')}
                    render={({ field }) => (
                      <Dropdown
                        options={fieldValues('insulinConcentration', insulinConcentrationOptions)}
                        required
                        className='h-8'
                        background='white'
                        placeholder='Choose concetration'
                        readOnly={isReadOnly}
                        {...field}
                      />
                    )}
                  />
                </div>

              </div>
              <div className='relative form-control flex flex-col items-center justify-end'>
                {data?.startedAt ? (
                  <span>
                    Session start time: <b>{dayjs(data.startedAt).format('hh:mm a')}</b>
                  </span>
                ) : (
                  <></>
                )}
                {data?.finishedAt ? (
                  <span>
                    Session finish time: <b>{dayjs(data.finishedAt).format('hh:mm a')}</b>
                  </span>
                ) : (
                  <></>
                )}
                {data?.canceledAt ? (
                  <span>
                    Session cancel time: <b>{dayjs(data.canceledAt).format('hh:mm a')}</b>
                  </span>
                ) : (
                  <></>
                )}
                {data?.status === SESSION_STATUS_TYPE.NOT_STARTED ? (
                  <Button
                    disabled={isLoading || !isStartSessionEnabled}
                    onClick={onStartSessionClick}
                    className='min-h-8 h-8'
                    type='button'
                  >
                    Start Session
                    {isLoading ? <span className='loading loading-spinner loading-sm' /> : <></>}
                  </Button>
                ) : (
                  getStatusLabel(data?.status)
                )}
              </div>
            </div>

            <div className='flex flex-row gap-4 items-end justify-between'>
              <div className='flex flex-col h-full  justify-between'>
                <span className='font-bold leading-8'>Initials</span>
                <span className='text-slate-500'>{data?.ivInitials || ''}</span>
              </div>

              <div className='flex-1 grid grid-cols-4 gap-4'>
                <div className='flex-grow'>
                  <Controller
                    name='ivTime'
                    control={control('onBlur')}
                    render={({ field }) => (
                      <Input
                        disabled={!isSessionStarted}
                        label='IV started @'
                        className='h-8'
                        background='white'
                        type='time'
                        {...field}
                        value={field.value ? formatTimeToHHmm(field.value) : ''}
                        onBlur={(e) =>
                          onIvTimeChange(formatTimeToDateTime(e.target.value, data?.startedAt), field.onBlur)
                        }
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </div>
                <div className='flex-grow'>
                  <Controller
                    name='ivGauge'
                    control={control('onChange')}
                    render={({ field }) => (
                      <Dropdown
                        required
                        label={fieldLabel('ivGauge', 'Gauge')}
                        options={fieldValues('ivGauge', gaugeOptions)}
                        className='h-8'
                        background='white'
                        placeholder='Choose gauge'
                        {...field}
                        disabled={!isSessionStarted}
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </div>
                <div className='flex-grow'>
                  <Controller
                    name='ivSite'
                    control={control('onChange')}
                    render={({ field }) => (
                      <Dropdown
                        required
                        label={fieldLabel('ivSite', 'Site')}
                        options={fieldValues('ivSite', ivSiteOptions)}
                        className='h-8'
                        background='white'
                        placeholder='Choose site'
                        {...field}
                        disabled={!isSessionStarted}
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </div>
                <div className='flex-grow'>
                  <Controller
                    name='insulinPump'
                    control={control('onChange')}
                    render={({ field }) => (
                      <Dropdown
                        required
                        label={fieldLabel('insulinPump', 'Insulin Pump')}
                        options={fieldValues('insulinPump', insulinPumpOptions)}
                        className='h-8'
                        background='white'
                        placeholder='Choose Insulin Pump'
                        {...field}
                        disabled={!isSessionStarted}
                        readOnly={isReadOnly}
                      />
                    )}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
