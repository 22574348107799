import React, { useState, useEffect } from 'react';
import { Dropdown } from 'components/Dropdown';
import { FormControl } from 'components/FormControl'
import { Input, InputProps } from 'components/Input';
import clsx from 'clsx';

export const isValidTime = (hour: string, minute: string) => {
  const hourInt = parseInt(hour, 10);
  const minuteInt = parseInt(minute, 10);
  return hourInt >= 0 && hourInt <= 23 && minuteInt >= 0 && minuteInt <= 59;
};

type TimeInputProps = InputProps & {
  onChange: (value: string) => void;
}

export const TimeInput: React.FC<TimeInputProps> = ({
  error,
  focused,
  label,
  inline,
  disabled,
  fullWidth,
  value,
  onChange,
  readOnly
}) => {
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [period, setPeriod] = useState('AM');

  useEffect(() => {
    if (value) {
      const [hourValue, minuteValue] = value.split(':');
      const parsedHour = parseInt(hourValue, 10);
      setHour(
        parsedHour > 12
          ? (String(parsedHour - 12).padStart(2, '0'))
          : parsedHour === 0 && period === 'AM' ? '12' : hourValue.padStart(2, '0'));
      setMinute(minuteValue.padStart(2, '0'));
      setPeriod(parsedHour >= 12 ? 'PM' : 'AM');
    } else {
      setHour('')
      setMinute('')
      setPeriod('AM')
    }
  }, [period, value]);

  const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newHour = e.target.value;
    setHour(newHour);
    if (isValidTime(newHour, minute)) {
      const formattedHour = period === 'PM' && parseInt(newHour, 10) < 12 ? String(parseInt(newHour, 10) + 12) : newHour;
      const paddedMinute = minute.padStart(2, '0');
      onChange(`${formattedHour}:${paddedMinute}`);
    }
  };

  const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMinute = e.target.value;
    const paddedMinute = newMinute.padStart(2, '0');
    setMinute(paddedMinute);

    if (isValidTime(hour, paddedMinute)) {
      // const formattedHour = period === 'PM' && hour !== '12' ? String(parseInt(hour, 10) + 12) : period === 'AM' && hour === '12' ? '00' : hour;
      const formattedHour = period === 'PM' && hour === '12' ? '12' : period === 'PM' ? String(parseInt(hour, 10) + 12) : period === 'AM' && hour === '12' ? '00' : hour;
      onChange(`${formattedHour}:${paddedMinute}`);
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPeriod = e.target.value;
    setPeriod(selectedPeriod);

    if (isValidTime(hour, minute)) {
      const formattedHour = selectedPeriod === 'PM' && hour === '12' ? '12' : selectedPeriod === 'PM' ? String(parseInt(hour, 10) + 12) : selectedPeriod === 'AM' && hour === '12' ? '00' : hour;

      const paddedMinute = minute.padStart(2, '0');
      onChange(`${formattedHour}:${paddedMinute}`);
    }
  };

  const getHourOptions = () => {
    const hours = [];
    for (let i = 1; i <= 12; i++) {
      hours.push({ label: i < 10 ? `0${i}` : String(i), value: String(i).padStart(2, '0') });
    }
    return hours;
  };

  const getMinuteOptions = () => {
    const minutes = [];
    for (let i = 0; i < 60; i += 15) {
      minutes.push({ label: i < 10 ? `0${i}` : String(i), value: String(i).padStart(2, '0') });
    }
    return minutes;
  };

  if (readOnly) {
    return (
      <Input readOnly value={`${hour}:${minute}:${period}`} />
    )
  }

  return (
    <FormControl
      focused={focused}
      error={error}
      label={label}
      inline={inline}
      disabled={disabled}
      containerClassName={clsx({
        'w-full': fullWidth
      })}
    >
      <div className="flex gap-1 items-center">
        <div>
          <Dropdown
            required
            value={hour}
            className="h-12 max-w-[100px]"
            background="white"
            placeholder="--"
            onChange={handleHourChange}
            options={getHourOptions()}
          />
        </div>
        <span>:</span>
        <div>
          <Dropdown
            required
            value={minute}
            className="h-12 max-w-[100px]"
            background="white"
            placeholder="--"
            onChange={handleMinuteChange}
            options={getMinuteOptions()}
          />
        </div>
        <span>:</span>
        <div>
          <Dropdown
            required
            withAutocomplete
            value={period}
            className="h-12 max-w-[100px]"
            background="white"
            onChange={handlePeriodChange}
            options={[
              { label: 'AM', value: 'AM' },
              { label: 'PM', value: 'PM' },
            ]}
          />
        </div>
      </div>
    </FormControl>
  );
};
