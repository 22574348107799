import React, { useCallback, useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Combobox as ComboboxUI } from "@headlessui/react"
import { cn } from "lib/cn"
import { ReactComponent as Check } from 'assets/icons/check.svg'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg'
import { FormControl } from "components/FormControl"
import { InputBackground, InputBorder } from "components/Input"
import { Checkbox } from "components/Checkbox"
import { DropdownProps } from "components/Dropdown/types"

export const Dropdown: React.FC<DropdownProps> = ({
  required,
  multiple,
  placeholder,
  readOnly,
  focused,
  error,
  inline,
  disabled,
  fullWidth = true,
  label,
  value,
  options,
  onChange: onChangeProp,
  background = 'default',
  inputClassName,
  border = 'default',
  className,
  transparent,
  icon,
  withAutocomplete,
  onFocus: onFocusProp,
  autoFocus,
  ...props
}) => {
  const [query, setQuery] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const containsValue = Array.isArray(value) ? value.length > 0 : value && value?.length > 0

  const getDisplayValue = useCallback((selectedValue: string | string[] | undefined) => {
    if (Array.isArray(selectedValue) && selectedValue.length > 0) {
      return selectedValue.map(v => options.find(eachOption => eachOption.value === v)?.label).join(', ')
    }

    const foundOption = options.find(eachOption => eachOption.value === selectedValue)
    return foundOption?.label || ''
  }, [options]) 

  useEffect(() => {
    if (!inputRef.current) return

    if (!multiple && typeof value === 'string') {
      if (!value.trim()) {
        inputRef.current.value = ''
      } else {
        inputRef.current.value = getDisplayValue(value) 
      }
    }
  }, [value, getDisplayValue, multiple])

  const onChange = (value: string | string[] | null) => {
    const event = {
      target: {
        value,
        name: props.name,
        tagName: 'SELECT'
      },
    } as React.ChangeEvent<HTMLSelectElement>
    onChangeProp?.(event)
  }

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
        return option.label.toLowerCase().includes(query.toLowerCase())
      })

  const onClear = () => {
    onChange(multiple ? [] : '')
  }

  const focus = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>)  => {
    if (withAutocomplete) {
      setTimeout(() => {
        e.target.select()
      }, 100)
    }

    if (onFocusProp) {
      onFocusProp()
    }
  }

  return (
    <FormControl
      focused={focused}
      error={error}
      label={label}
      inline={inline}
      containerClassName={fullWidth ? 'w-full' : ''}
    >
      {(disabled || readOnly) ? (
        <input
          className={cn(
            'input text-primary relative text-base font-normal h-8 bg-inherit w-full focus:outline-0 cursor-default focus:ring-0 focus:border-inherit hover:border-inherit',
            InputBackground[background],
            InputBorder[border],
            className,
            transparent
              ? 'bg-transparent border-transparent hover:border-transparent focus:border-transparent focus:ring-0'
              : '',
          )}
          value={getDisplayValue(value)}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
      ) : (
        <ComboboxUI
          multiple={multiple}
          immediate
          value={value}
          onChange={onChange}
          onClose={() => setQuery('')}
        >
          <div
            className={cn(
              `w-full input bg-inherit text-primary px-0 relative flex items-center focus-within:outline-none focus-within:ring-primary focus-within:border-primary hover:border-base-content`,
              {
                'input-error focus:ring-error focus:border-error': error,
              },
              InputBackground[background],
              InputBorder[border],
              inputClassName,
              className
            )}
          >
            <ComboboxButton
              className="w-full"
            >
              <ComboboxInput
                ref={inputRef}
                autoComplete="off"
                autoFocus={autoFocus}
                readOnly={!withAutocomplete}
                onClick={focus}
                className={clsx("w-full border-none focus:ring-0 cursor-pointer pr-8 placeholder-gray-300", className)}
                displayValue={getDisplayValue}
                onChange={(event) => {
                  setQuery(event.target.value)
                }}
                placeholder={placeholder}
                onKeyDown={withAutocomplete ? e => e.stopPropagation() : undefined}
                onFocus={onFocus}
              />
            </ComboboxButton>

            <div className={cn("flex items-center gap-1 absolute right-0 pr-2", InputBackground[background])}>
              {!required && containsValue && (
                <div onClick={onClear} className='h-5 w-5 flex items-center justify-center'>
                  <Close className='h-3 w-3' />
                </div>
              )}
              <ComboboxButton>
                {icon || <Arrow />}
              </ComboboxButton>
            </div>

            <ComboboxOptions
              anchor="bottom"
              className={cn('w-[var(--input-width)] mt-1 rounded-xl border border-solid bg-white empty:hidden shadow-lg z-[1000] empty:invisible')}
            >
              <div className='max-h-[200px] overflow-auto'>
                {filteredOptions.map((option) => {
                  const isSelected = Array.isArray(value) ? value.includes(option.value) : value === option.value
                  return (
                    <ComboboxOption
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                      className={clsx("data-[focus]:bg-blue-100 p-2", {
                        'bg-light-blue': isSelected,
                        '!text-gray-300 line-through': option.disabled
                      })}
                    >
                      {multiple ? (
                        <div className="flex gap-2 pointer-events-none">
                          <Checkbox disabled={option.disabled} checked={isSelected} />
                          <span>{option.label}</span>
                        </div>
                      ) : (
                        <div className="flex justify-between">
                          <span>{option.label}</span> <span className={clsx({ 'hidden': !isSelected })}><Check className='p-0 h-5 w-5' /></span>
                        </div>
                      )}
                    </ComboboxOption>
                  )
                })}
              </div>
            </ComboboxOptions>
          </div>
        </ComboboxUI>
      )}
    </FormControl >
  )
}