import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as Toast from 'components/Toast'
import { useAuth, useCreateSession } from 'hooks'
import { APPOINTMENT_TYPE, AppointmentOutputDTO, INSULIN } from 'types'
import { NewInfusionSessionModal } from 'components/Modals/NewInfusionSession'

interface NewInfusionSessionModalContainerProps {
  appointment: AppointmentOutputDTO
  onClose: () => void
}

export const NewInfusionSessionModalContainer: React.FC<NewInfusionSessionModalContainerProps> = ({
  appointment,
  onClose
}) => {
  const { clinicId } = useAuth()
  const { trigger, isMutating } = useCreateSession()
  const navigate = useNavigate()

  const onSubmit = async (type: APPOINTMENT_TYPE) => {
    if (isMutating) return

    try {
      await trigger({
        type,
        insulin: INSULIN['Novolin R'], // default insulin
        patientId: appointment?.patientId,
        appointmentId: appointment?.id,
      })
      navigate(`/clinics/${clinicId}/sessions/${appointment?.id}`)
    } catch (e) {
      Toast.error('Error trying to create session')
    } finally {
      onClose()
    }
  }

  return (
    <NewInfusionSessionModal
      onSubmit={onSubmit}
      onClose={onClose}
      defaultType={appointment.type || undefined}
    />
  )
}
