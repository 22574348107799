import * as Toast from 'components/Toast'
import { APP_SCREEN_NAMES, UserSettings } from "types"
import { users as UserActions } from 'data'
import { useAuth } from "./useAuth"
import { useSWRConfig } from "swr"
import { IColumn } from 'components/Table/types'

export const useUserSettings = () => {
  const { mutate } = useSWRConfig()
  const { userDetail } = useAuth()

  const saveSettings = async (userSettings: UserSettings) => {
    await UserActions.update({
      id: userDetail?.id,
      settings: userSettings
    })
    mutate(['/users', userDetail?.id])
  }

  const saveSortListing = async ({
    screenName,
    fieldName,
    order }: {
      screenName: APP_SCREEN_NAMES,
      fieldName: string,
      order: 'ASC' | 'DESC'
    }) => {
    try {
      const userSettings: UserSettings = {
        ...userDetail?.settings,
        [screenName]: {
          ...userDetail?.settings?.[screenName],
          sortListings: {
            fieldName,
            order,
          }
        },
      }
      await saveSettings(userSettings)
    } catch(error: any) {
      Toast.error(error?.message ?? 'Can not save sorting user preferences')
    }
  }

  const saveColumns = async ({
    screenName,
    columns
  }: {
    screenName: APP_SCREEN_NAMES,
    columns: IColumn<string>[]
  }) => {
    try {
      const userSettings: UserSettings = {
        ...userDetail?.settings,
        [screenName]: {
          ...userDetail?.settings?.[screenName],
          ...(columns.length > 0 ? { columns } : { columns : null })
        }
      }
      await saveSettings(userSettings)
    } catch (error: any) {
      Toast.error(error?.message ?? 'Can not save columns user preferences')
    }
  }

  const getSortListingByScreen = (screenName: APP_SCREEN_NAMES) => userDetail?.settings?.[screenName]?.sortListings
  const getColumns = <T>(screenName: APP_SCREEN_NAMES): T => userDetail?.settings?.[screenName]?.columns as T

  return {
    getSortListingByScreen,
    saveSortListing,
    saveColumns,
    getColumns,
  }
}