export type CheckboxColorType = keyof typeof CheckboxColor
export enum CheckboxColor {
  primary = 'checkbox-primary',
  secondary = 'checkbox-secondary',
  dark = 'checkbox-black border-black',
}

export interface CheckboxProps {
  checked?: boolean
  onChange?: (event: { target: any; type?: any }) => any
  color?: CheckboxColorType
  focused?: boolean
  label?: string
  inline?: boolean
  disabled?: boolean
  readOnly?: boolean
}
