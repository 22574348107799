import React, { useState } from 'react'
import { Input } from 'components/Input'
import { Control } from 'react-hook-form'
import { FileTypes, InfusionSession, SESSION_STATUS_TYPE, USER_ROLE } from 'types'
import { Button } from 'components/Button'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'
import { useAuth } from 'hooks'
import { DownloadFileButton } from './DownloadFileButton'
import { DownloadSessionPDFButton } from './DownloadSessionPDF'

export const SignSessionSection = ({
  control,
  data,
  signSession,
  finishSession,
}: {
  control: (triggerEvent: 'onBlur' | 'onChange') => Control<InfusionSession, any>
  data: InfusionSession
  signSession?: () => Promise<boolean | undefined>
  finishSession?: () => Promise<boolean | undefined>
}) => {
  const [isFinishSessionInProgress, setIsFinishSessionInProgress] = useState(false)
  const { userDetail, clinicId } = useAuth()
  const isSessionCompleted = data.status === SESSION_STATUS_TYPE.COMPLETED
  const showSignButton = userDetail?.role === USER_ROLE.ADMINISTRATOR || userDetail?.role === USER_ROLE.PROVIDER
  const isSessionSigned = data.status === SESSION_STATUS_TYPE.SIGNED
  const [isSignInProgress, setIsSignInProgress] = useState(false)
  const isReadyToFinish = !(data?.ivTime && !data?.ivDcTime)

  const onSignSessionClick = async () => {
    if (signSession) {
      setIsSignInProgress(true)
      await signSession()
      setIsSignInProgress(false)
    }
  }

  const onFinishSessionClick = async () => {
    if (finishSession) {
      setIsFinishSessionInProgress(true)
      await finishSession()
      setIsFinishSessionInProgress(false)
    }
  }

  const providerName = isSessionSigned
    ? data.signingProviderName
    : ''
  const providerNpi = isSessionSigned
    ? data?.signingProviderNpi ?? ''
    : ''

  const isSessionFinished =
    data?.status === SESSION_STATUS_TYPE.COMPLETED || data?.status === SESSION_STATUS_TYPE.SIGNED || data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED

  return (
    <>
      <div>
        <div className='p-2 shadow-md rounded-md my-3 grid grid-cols-2 space-x-2 print:grid-cols-1'>
          <div className='flex gap-2 sm:flex-wrap xl:flex-nowrap print:flex-nowrap'>
            <Input inline readOnly className='h-8 min-w-[250px]' label='Provider Name' value={providerName} />
            <Input inline readOnly className='h-8 min-w-[130px]' label='NPI' value={providerNpi} />
          </div>

          {isSessionCompleted && showSignButton && (
            <Button disabled={isSignInProgress} onClick={onSignSessionClick}>
              Sign Session
            </Button>
          )}

          {!isSessionFinished && (
            <Button
              onClick={() => openConfirmationModal('sessionConfirmationModal2')}
              disabled={isFinishSessionInProgress || isSessionFinished}
              background={isReadyToFinish ? 'default' : 'gray'}
            >
              Finish Session {isFinishSessionInProgress ? <span className='loading loading-spinner loading-sm' /> : <></>}
            </Button>
          )}

          {isSessionSigned && data.pdfLocation && (
            <DownloadFileButton
              fileLocation={data.pdfLocation}
              fileType={FileTypes['application/pdf']}
              className="print:hidden"
            >
              Download PDF
            </DownloadFileButton>
          )}

          {isSessionSigned && !data.pdfLocation && (
            <DownloadSessionPDFButton
              sessionId={data.id as string}
              clinicId={clinicId as string}
              className="print:hidden"
            >
              Download PDF
            </DownloadSessionPDFButton>
          )}
        </div>
      </div>

      <ConfirmationModal
        id='sessionConfirmationModal2'
        title={'Finish session'}
        description={'Are you sure you want to finish the session?'}
        onConfirm={onFinishSessionClick}
      />
    </>
  )
}
