import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { getUserInfo, parseHash } from 'lib/auth'
import { useAuth, useTitle } from 'hooks'
import * as Toast from 'components/Toast'
import { me as MeActions } from 'data'
import { trackLogin } from 'data/user-history' 
import useHistoryBack from 'hooks/useHistoryBack'
import { getMaintenanceMode } from 'data/admin'
import { USER_ROLE, UserDetail } from 'types'

export const AuthCallback = () => {
  useTitle('Auth Callback')
  const [loading, setLoading] = useState<boolean>(true)
  const [active, setActive] = useState<boolean>(false)
  const [isMaintenanceModeOn, setIsMaintenanceModeOn] = useState(false)
  const { setSession, userDetail, signout } = useAuth()
  const settingCredentialsStartedRef = useRef(false)

  const navigate = useNavigate()

  useHistoryBack(signout)

  const setCredentials = useCallback(async () => {
    try {
      if (settingCredentialsStartedRef.current) return

      settingCredentialsStartedRef.current = true
      setLoading(true)
      const token = (await parseHash()) as string
      const { user }: any = await getUserInfo(token)

      setSession({ user, token })
      const userDetail = await MeActions.fetch({}) as UserDetail
      const maintenanceMode = await getMaintenanceMode()

      if (maintenanceMode.on && userDetail.role !== USER_ROLE.ADMINISTRATOR) {
        setIsMaintenanceModeOn(maintenanceMode.on)
        return
      }

      setSession({ userDetail })
      setLoading(false)
      await trackLogin(userDetail.id, userDetail.email)
    } catch (error: any) {
      setLoading(false)
      Toast.error(error?.message || error)
      navigate('/login', { replace: true })
    }
  }, [navigate, setSession]) 

  useEffect(() => {
    void setCredentials()
  }, [setCredentials])

  useEffect(() => {
    if (loading || !userDetail) return

    const setClinic = (clinicId: string) => {
      const foundClinic = userDetail?.memberships.find(membership => membership.clinic.id === clinicId)?.clinic

      setSession({
        clinicId,
        enableLocalEmr: !!foundClinic?.enableLocalEmr
      })
      navigate('/', { replace: true })
    }

    if (!userDetail.active || !userDetail.memberships?.length) {
      navigate('/user-no-active', { replace: true })
      return
    }

    if (userDetail && userDetail.active && userDetail.memberships.length > 1) {
      setActive(true)
    }

    if (userDetail?.active && userDetail?.memberships?.length === 1) {
      setClinic(userDetail?.memberships[0]?.clinic.id)
    }
  }, [loading, navigate, setSession, userDetail])

  if (isMaintenanceModeOn) {
    return (
      <Navigate to='/maintenance' replace />
    )
  }

  return (
    <>
      {loading ? (
        <div className='flex w-full h-screen justify-center align-middle items-center'>
          <span className='loader'></span>
        </div>
      ) : active && (
        <Navigate to='/select-clinic' />
      )}
    </>
  )
}
