import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth, useTitle } from "hooks"
import { SelectClinicModal } from 'components/Modals/SelectClinic/SelectClinic'

export const SelectClinicScreen = () => {
  const { setSession, userDetail } = useAuth()
  const navigate = useNavigate()

  useTitle('Select Clinic')

  const setClinic = (clinicId: string) => {
    const foundClinic = userDetail?.memberships.find(membership => membership.clinic.id === clinicId)?.clinic

    setSession({
      clinicId,
      enableLocalEmr: !!foundClinic?.enableLocalEmr
    })
    navigate('/', { replace: true })
  }

  return (
    <>
      <SelectClinicModal setSelectedClinic={setClinic} />
    </>
  )
}