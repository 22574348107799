import React, { useRef, useState } from 'react'
import _ from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'components/Button'
import { Search } from 'components/Search'
import { Table } from 'components/Table'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { PatientOutputDTO, PatientStatus } from 'types'
import { useAuth, usePatients, useTitle } from 'hooks'
import Pagination from 'components/Pagination'
import { useUserSettings } from 'hooks/useUserSettings'
import { displayDate } from 'lib/day'
import { Checkbox } from 'components/Checkbox'

interface PatientModelTable extends Partial<PatientOutputDTO> {
  action?: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<PatientModelTable>[] = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'DOB', key: 'dob' },
  { label: 'Phone', key: 'phone' },
  { label: 'Email', key: 'email' },
]

const PAGE_SIZE = 10

export const Patients: React.FC = () => {
  useTitle('Patients')
  const {
    getSortListingByScreen,
    getColumns,
    saveSortListing,
    saveColumns,
  } = useUserSettings()
  const [columns, setColumns] = useState(getColumns<IColumn<PatientModelTable>[]>('patients') || defaultColumns)
  const sortListingDefaults = getSortListingByScreen('patients')
  const auth = useAuth()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { clinicId } = useAuth()
  const queryParamSearch = searchParams.get('search') || ''
  const queryParamPage = +(searchParams.get('page') || '1') - 1
  const queryParamStatus = searchParams.get('status')
  const orderBy = {
    fieldName: searchParams.get('orderby_field') as IOrderBy<PatientModelTable>['fieldName'] || sortListingDefaults?.fieldName || 'firstName',
    order: searchParams.get('orderby_order') as IOrderBy<PatientModelTable>['order'] || sortListingDefaults?.order || 'ASC'
  }
  const [query, setQuery] = useState<any>({
    search: queryParamSearch,
    ...(queryParamStatus === PatientStatus.Inactive ? {} : { status: PatientStatus.Active }),
    size: PAGE_SIZE,
    page: queryParamPage,
    'clinicIds[0]': clinicId,
    orderby_field: orderBy.fieldName,
    orderby_order: orderBy.order,
  })
  const { data, paginationData } = usePatients(JSON.parse(JSON.stringify(query)), {})
  const inputSearchRef = useRef<HTMLInputElement>(null)

  const goToAddPatient = () => {
    navigate(`/clinics/${auth.clinicId}/patients/new`)
  }
  const goToEditPatient = (id: string) => {
    navigate(`/clinics/${clinicId}/patients/${id}`)
  }
  const onSearch = (text: string) => {
    setSearchParams(params => {
      params.set('search', text)
      return params
    }, { replace: true })

    setQuery({
      ...query,
      page: 0,
      search: encodeURIComponent(text),
    })
  }
  const onActiveSearch = (showInactive: boolean) => {
    setSearchParams(params => {
      params.set('status', showInactive ? PatientStatus.Inactive : PatientStatus.Active)
      return params
    }, { replace : true })
    setQuery((prevQueryArg: any) => {
      const query = _.omit(prevQueryArg, 'status')
      return {
      ...query,
      ...(!showInactive && { status: PatientStatus.Active })
      } 
    })
  }
  const onPageChange = (nextPage: number) => {
    setSearchParams(params => {
      params.set('page', nextPage.toString())
      params.set('search', params.get('search') || '')
      return params
    }, { replace: true })
    setQuery({ ...query, page: nextPage - 1 })
  }

  const onOrderBy = (options: IOrderBy<PatientModelTable>) => {
    setSearchParams(params => {
      params.set('orderby_field', options.fieldName)
      params.set('orderby_order', options.order)
      return params
    }, {
      replace: true
    })
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })

    saveSortListing({
      screenName: 'patients',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const onInputSearchClear = () => {
    if (inputSearchRef.current)
      inputSearchRef.current.value = ''
    onSearch('')
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<PatientModelTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'patients',
      columns: tableSettings.columns,
    })
  }

  const patients = data as PatientOutputDTO[]

  return (
    <div className='w-full'>
      <div className='flex px-6 py-4 justify-between align-middle self-stretch shadow-md border-2 border-gray-50 my-6 rounded-lg'>
        <span className='text-2xl font-medium mr-8'>Patients</span>
        <div className='flex gap-4'>
          <Search
            ref={inputSearchRef}
            className='xl:w-60'
            placeHolder='Search for patients'
            defaultValue={queryParamSearch}
            onSearch={onSearch}
            onClear={onInputSearchClear}
          />
          <div>
            <Checkbox
              inline
              label="Show Inactive"
              checked={!query.status}
              onChange={e => {
                onActiveSearch(e.target.checked)
              }}
            />
          </div>
          {auth.enableLocalEmr && (
            <Button className='min-h-8 h-8 bg-secondary font-light lg:px-6' type='button' onClick={goToAddPatient}>
              Add New Patient
            </Button>
          )}
        </div>
      </div>

      <div className='w-full lg:h-5/6 xl:h-4/6 overflow-scroll hide-scrollbar'>
        <Table
          onRowClick={(patientId) => { goToEditPatient(patientId) }}
          className='w-full'
          title={'Patients'}
          showSearch={false}
          columns={columns}
          rows={(patients as any || []).map((patient: PatientOutputDTO) => ({
            ...patient,
            dob: patient.dob ? displayDate(patient.dob) : patient.dob
          }))}
          orderBy={orderBy}
          onOrderBy={onOrderBy}
          onSaveSettings={onSaveTableSettings}
        />
      </div>

      <div className='flex justify-end'>
        <Pagination
          currentPage={paginationData.page + 1}
          totalPages={Math.ceil(paginationData.total / paginationData.size)}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  )
}