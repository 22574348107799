import { APPOINTMENT_TYPE, INSULIN, INSULIN_CONCENTRATION, IV_GAUGE_TYPE, IV_SITE, SESSION_INSULIN_PUMP, TARGET_TYPE } from 'types'

export const targetDropdownOptions = [
  {
    label: '180-220 (Resistant) 200',
    value: TARGET_TYPE.RESISTANT,
  },
  {
    label: '150-190 (Moderate) 170',
    value: TARGET_TYPE.MODERATE,
  },
  {
    label: '120-160 (Sensitive) 140',
    value: TARGET_TYPE.SENSITIVE,
  },
  {
    label: 'Custom ...',
    value: TARGET_TYPE.CUSTOM,
  },
]

export const insulinConcentrationOptions = Object.values(INSULIN_CONCENTRATION)
  .filter((v) => isNaN(Number(v)))
  .map((key) => ({ label: key as string, value: key as string }))

export const appointmentTypeOptions = Object.values(APPOINTMENT_TYPE)
  .filter((v) => isNaN(Number(v)))
  .map((key) => ({ label: key as string, value: key as string }))

export const generalMonitoringOptions = {
  sleep: [...Array(9)].map((_, index) => ({
    label: index.toString(),
    value: index.toString(),
  })),
  pain: [...Array(11)].map((_, index) => ({
    label: index.toString(),
    value: index.toString(),
  })),
  energy: [...Array(11)].map((_, index) => ({
    label: index.toString(),
    value: index.toString(),
  })),
  nocturia: [...Array(9)].map((_, index) => ({
    label: index.toString(),
    value: index.toString(),
  })),
}

export const pumpingSessionOptions = {
  drValue: [...Array(11)].map((_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  })),
  qValue: [4, 5, 6, 7, 8].map((v) => ({
    label: v.toString(),
    value: v.toString(),
  })),
  pumpId: [...Array(9)].map((_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  })),
}

export const gaugeOptions = Object.values(IV_GAUGE_TYPE)
  .filter((v) => isNaN(Number(v)))
  .map((key) => ({ label: key as string, value: key as string }))

export const insulinPumpOptions = Object.values(SESSION_INSULIN_PUMP)
  .filter((v) => isNaN(Number(v)))
  .map((key) => ({ label: key as string, value: key as string }))

export const ivSiteOptions = Object.values(IV_SITE)
.filter((v) => isNaN(Number(v)))
.map((key) => ({ label: key as string, value: key as string }))

export const insulinOptions = Object.values(INSULIN)
.filter((v) => isNaN(Number(v)))
.map((key) => ({ label: key as string, value: key as string }))

export const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
export const datetimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
