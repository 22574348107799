import React, { useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { APPOINTMENT_STATUS, Appointment, SESSION_STATUS_TYPE } from 'types'
import { Table } from 'components/Table'
import { getSessionStatusLabel, sessionStatusesLabels } from 'constants/AppointmentStatuses'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { useAppointments, useAuth, useTitle } from 'hooks'
import { getTodayData } from 'lib/day'
import { NewInfusionSessionModalContainer } from '../Flowsheet/NewInfusionSessionModalContainer'
import { AppointmentStatusLabel } from './AppointmentStatusLabel'
import { useUserSettings } from 'hooks/useUserSettings'
import Pagination from 'components/Pagination'
import { Search } from 'components/Search'
import { Dropdown } from 'components/Dropdown'
import { IOption } from 'components/Dropdown/types'
import { Checkbox } from 'components/Checkbox'

interface AppointmentModelTable {
  id: string
  age: string
  start: string
  startTime: string
  patientName: string
  dob: string
  reason: string
  account: string
  status: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<AppointmentModelTable>[] = [
  { label: 'Date', key: 'start' },
  { label: 'Time', key: 'startTime' },
  { label: 'Name', key: 'patientName' },
  { label: 'DOB', key: 'dob' },
  { label: 'Age', key: 'age' },
  { label: 'Status', key: 'status' },
]
const statusFilters: IOption[] = Object.values(sessionStatusesLabels).map((option) => ({ label: option.label, value: option.value }))

export const getStatusLabel = (status?: SESSION_STATUS_TYPE | null) => {
  if (!status) return <span className='text-black'>Session not started</span>
  const { label, color } = sessionStatusesLabels[status]
  return <span className={color}>{label}</span>
}

const PAGE_SIZE = 10

export const TodayAppointments = () => {
  useTitle('Today\'s Appointments')
  const { clinicId } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    getSortListingByScreen,
    getColumns,
    saveSortListing,
    saveColumns
  } = useUserSettings()
  const navigate = useNavigate()
  const today = getTodayData()
  const sortListingDefaults = getSortListingByScreen('today-appointments')
  const [columns, setColumns] = useState(getColumns<IColumn<AppointmentModelTable>[]>('today-appointments') || defaultColumns)

  const orderBy = {
    fieldName: searchParams.get('orderby_field') as IOrderBy<AppointmentModelTable>['fieldName'] || sortListingDefaults?.fieldName as keyof AppointmentModelTable || 'startTime',
    order: searchParams.get('orderby_order') as IOrderBy<AppointmentModelTable>['order'] || sortListingDefaults?.order || 'ASC'
  }

  const inputSearchRef = useRef<HTMLInputElement>(null)
  const queryParamSearch = searchParams.get('search') || ''
  const queryParamPage = +(searchParams.get('page') || '1') - 1
  const queryParamStatus = searchParams.get('status')
  const queryParamOnlyToday = JSON.parse(searchParams.get('only_today') || 'false')

  const [query, setQuery] = useState<any>({
    search: queryParamSearch,
    ...(queryParamStatus ? { status: queryParamStatus} : {}),
    page: queryParamPage,
    size: PAGE_SIZE,
    in_progress: true,
    only_today: queryParamOnlyToday,
    start_date: today.start_date,
    end_date: today.end_date,
    orderby_field: orderBy.fieldName,
    orderby_order: orderBy.order,
  }) 
  const { data: paginatedData } = useAppointments(query)
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>()

  const data = useMemo(() => paginatedData?.data || [], [paginatedData?.data]) 

  const rows = useMemo(
    () =>
      data.map((appointment: any) => {
        const result: AppointmentModelTable = {
          id: appointment.id || '',
          start: dayjs(appointment.start).format('MMM DD, YYYY'),
          startTime: dayjs(appointment.start).format('hh:mm A'),
          patientName: `${appointment.patient.lastName}, ${appointment.patient.firstName}`,
          dob: dayjs(appointment.patient.dob).format('MM/DD/YYYY'),
          age: dayjs().diff(dayjs(appointment.patient.dob), 'year').toString(),
          reason: appointment.reason,
          account: appointment.patient.account,
          status: {
            value: getSessionStatusLabel(appointment?.session?.status || appointment.status)?.label,
            render: <AppointmentStatusLabel status={appointment?.session?.status || appointment.status} />,
          },
        }
        return result
      }) ?? [],
    [data]
  )

  const onRowClick = (id: string) => {
    const appointment = data?.find((appointment) => appointment.id === id)

    if ([
      APPOINTMENT_STATUS.SESSION_CANCELED,
      APPOINTMENT_STATUS.SESSION_DELETED,
      APPOINTMENT_STATUS.SESSION_NOSHOW
    ].includes(appointment?.status as APPOINTMENT_STATUS)) return
    if (!appointment?.session?.status) {
      setSelectedAppointment(appointment)
    } else {
      navigate(`/clinics/${clinicId}/sessions/${id}`)
    }
  }

  const onOrderBy = (options: IOrderBy<AppointmentModelTable>) => {
    setSearchParams(params => {
      params.set('orderby_field', options.fieldName)
      params.set('orderby_order', options.order)
      return params
    }, {
      replace: true
    })
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })
    saveSortListing({
      screenName: 'today-appointments',
      fieldName: options.fieldName,
      order: options.order
    })
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<AppointmentModelTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'today-appointments',
      columns: tableSettings.columns,
    })
  }

  const onPageChange = (nextPage: number) => {
    setSearchParams(params => {
      params.set('page', nextPage.toString())
      params.set('search', params.get('search') || '')
      return params
    }, { replace: true })
    setQuery({ ...query, page: nextPage - 1 })
  }

  const onSearch = (text: string) => {
    setSearchParams(params => {
      params.set('search', text)
      params.set('page', '1')
      return params
    }, { replace: true })

    setQuery({
      ...query,
      page: 0,
      search: encodeURIComponent(text),
    })
  }
  const onInputSearchClear = () => {
    if (inputSearchRef.current)
      inputSearchRef.current.value = ''
    onSearch('')
  }
  const onStatusChange = (status: string) => {
    setSearchParams(params => {
      params.set('status', status)
      params.set('page', '1')
      return params
    }, { replace: true })
    setQuery({
      ...query,
      page: 0,
      status,
    })
  }

  const onTodayOnly = (isChecked: boolean) => {
    setSearchParams((params => {
      params.set('only_today', String(isChecked))
      params.set('page', '1')
      return params
    }), { replace: true })
    setQuery({
      ...query,
      page: 0,
      only_today: isChecked,
    })
  }

  return (
    <>
      <div className='w-full space-y-4'>
        <div className='flex px-6 py-4 justify-between align-middle self-stretch shadow-md border-2 border-gray-50 my-6 rounded-lg'>
        <span className='text-2xl font-medium mr-8'>Appointments</span>
          <div className='flex gap-4'>
            <Search
              ref={inputSearchRef}
              placeHolder='Search'
              defaultValue={queryParamSearch}
              onSearch={onSearch}
              onClear={onInputSearchClear}
              className='min-w-[215px]'
            />

            <Checkbox
              inline
              label="Only Today"
              checked={queryParamOnlyToday}
              onChange={e => {
                onTodayOnly(e.target.checked)
              }}
            />

            <Dropdown
              placeholder='Filter by status'
              options={statusFilters}
              className="h-8 min-w-[215px]"
              value={queryParamStatus || ''}
              onChange={event => onStatusChange(event.target.value)}
            />
          </div>
      </div>

        {data && (
          <Table
            onRowClick={onRowClick}
            className='w-full'
            title={"Today's and active appointments"}
            rows={rows}
            columns={columns}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onSaveSettings={onSaveTableSettings}
            showSearch={false}
          />
        )}

        {!!paginatedData && (
          <div className='flex justify-end'>
            <Pagination
              currentPage={paginatedData.page + 1}
              totalPages={Math.ceil(paginatedData.total / paginatedData.size)}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>

      {!!selectedAppointment && (
        <NewInfusionSessionModalContainer
          appointment={selectedAppointment}
          onClose={() => setSelectedAppointment(null)}
        />
      )}
    </>
  )
}
