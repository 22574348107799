import { useAuth } from 'hooks'
import React from 'react'
import { Navigate } from 'react-router-dom'

export const Dashboard = () => {
  const { clinicId } = useAuth()

  return (
    <div className='h-screen flex justify-center items-center pb-32'>
      <Navigate to={`/clinics/${clinicId}/today-appointments`} replace />
      <h3>Dashboard Page</h3>
    </div>
  )
}
