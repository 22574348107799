import { FieldErrors, FieldValues } from "react-hook-form";

export type FormErrorMessage = {
  key: string,
  message: string
}

export const formErrorsToArray = <T extends FieldValues>(errors: FieldErrors<T>): FormErrorMessage[]  => {
  const errorsArray = Object.keys(errors).map(key => ({ message: errors[key]?.message as string, key }))

  return errorsArray
}