import { useEffect, useRef, useState } from "react";

export const useScrollIndicator = (): [React.RefObject<HTMLDivElement>, boolean] => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targetElement = targetRef.current 
    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0, // When the entire target is visible
    };

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setShowScrollIndicator(!entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return [targetRef, showScrollIndicator];
};