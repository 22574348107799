import * as api from 'data/appointment/api'

export const fetch = (data: any) => {
  return api.fetch(data)
}

export const create = (data: any) => {
  return api.create(data)
}

export const update = (data: any & { id: string }) => {
  return api.update(data)
}

export const cancel = (data: any & { id: string }) => {
  return api.cancel(data)
}