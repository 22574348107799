import React, { useMemo, useState } from 'react'
import Pagination from 'components/Pagination'
import { Table } from 'components/Table'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import dayjs from 'dayjs'
import { useUserSettings } from 'hooks/useUserSettings'
import { useAuth, useSessions } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { SESSION_STATUS_TYPE, Session } from 'types'

const PAGE_SIZE = 10

interface PreviousAppointmentsProps {
  patientId: string
}

interface AppointmentSessionTable extends Pick<Session, 'type' | 'target' | 'landing' | 'insulinConcentration'> {
  created: string
  provider: string
}

const defaultColumns: IColumn<AppointmentSessionTable>[] = [
  { label: 'Date', key: 'created' },
  { label: 'Infusion Type', key: 'type' },
  { label: 'Target', key: 'target' },
  { label: 'Landing', key: 'landing' },
  { label: 'Insulin concentration', key: 'insulinConcentration' },
  { label: 'Provider', key: 'provider' },
]

export const PreviousAppointments: React.FC<PreviousAppointmentsProps> = ({ patientId }) => {
  const { clinicId } = useAuth()
  const navigate = useNavigate()
  const {
    getSortListingByScreen,
    getColumns,
    saveSortListing,
    saveColumns,
  } = useUserSettings()
  const [columns, setColumns] = useState(getColumns<IColumn<AppointmentSessionTable>[]>('patient/previous-sessions') || defaultColumns)
  const sortListingDefaults = getSortListingByScreen('patient/previous-sessions')
  const [query, setQuery] = useState({
    size: PAGE_SIZE,
    page: 0,
    patientId,
    status: SESSION_STATUS_TYPE.COMPLETED,
    orderby_field: sortListingDefaults?.fieldName || 'created',
    orderby_order: sortListingDefaults?.order || 'DESC'
  })

  const { data, isLoading } = useSessions(JSON.parse(JSON.stringify(query)))
  const sessions = data?.data.map((session: Session) => ({
    id: session.id,
    created: dayjs(session.created).format('MMM DD, YYYY'),
    type: session.type,
    target: session.target,
    landing: session.landing,
    insulinConcentration: session.insulinConcentration,
    provider: session.signedByUser
      ? `${session.signedByUser?.firstName} ${session.signedByUser?.lastName}`
      : `${session.user?.firstName} ${session.user?.lastName}`
  }))

  const orderBy = useMemo(() => {
    return { fieldName: query.orderby_field, order: query.orderby_order } as IOrderBy<AppointmentSessionTable>
  }, [query.orderby_field, query.orderby_order])

  const onSessionClick = (id: string) => {
    navigate(`/clinics/${clinicId}/sessions/${id}`)
  }

  const onOrderBy = (options: IOrderBy<AppointmentSessionTable>) => {
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })

    saveSortListing({
      screenName: 'patient/previous-sessions',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<AppointmentSessionTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'patient/previous-sessions',
      columns: tableSettings.columns,
    })
  }

  if (isLoading || !data.data) {
    return null
  }

  return (
    <>
      <div className='shadow-md rounded-lg'>
        <div>
          <Table
            onRowClick={onSessionClick}
            className='w-full'
            title={'Previous Appointments'}
            showSearch={false}
            columns={columns}
            rows={(sessions || [])}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onSaveSettings={onSaveTableSettings}
          />
        </div>
      </div>
      <div className='w-full flex justify-end'>
        <Pagination
          currentPage={data.page + 1}
          totalPages={Math.ceil(data.total / data.size)}
          onPageChange={n => setQuery({ ...query, page: n - 1 })}
        />
      </div>
    </>
  )
}
