import dayjs from "dayjs"
import { InfusionSession, IV_GAUGE_TYPE, IV_SITE, SESSION_INSULIN_PUMP } from "types"

export const hasValidIvTime = (ivTime: string | undefined) => !!ivTime && dayjs(ivTime).isValid()
export const hasIvGauge = (ivGauge: string | undefined) => Object.values(IV_GAUGE_TYPE).includes(ivGauge as IV_GAUGE_TYPE)
export const hasIvSite = (ivSite: string | undefined) => Object.values(IV_SITE).includes(ivSite as IV_SITE)
export const hasInsulinPump = (insulinPump: string | undefined) => Object.values(SESSION_INSULIN_PUMP).includes(insulinPump as SESSION_INSULIN_PUMP)

export const doesSessionStartedFieldsWereProvided = (data: InfusionSession) => {
  if ([
    hasValidIvTime(data.ivTime),
    hasIvGauge(data.ivGauge),
    hasIvSite(data.ivSite),
    hasInsulinPump(data.insulinPump)
  ].some(value => !value)) return false

  return true
}