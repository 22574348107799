interface Auth0Config {
  domain: string
  clientId: string
  redirect: string
  audience: string
  scope?: string
  realm: string
  state?: string
}

interface ApiConfig {
  url: string
}

interface Config {
  auth0: Auth0Config
  api: ApiConfig
}

export const config: Config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirect: process.env.REACT_APP_AUTH0_REDIRECT || `${window.location.origin}/auth-callback`,
    scope: process.env.REACT_APP_AUTH0_SCOPE || 'openid profile email user_metadata',
    realm: process.env.REACT_APP_AUTH0_REALM || 'Username-Password-Authentication',
    state: process.env.REACT_APP_AUTH0_STATE || '251b862f-4754-4d2d-8700-e1698a307af5',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://wellcell-dev.us.auth0.com/api/v2/',
  },
  api: {
    url: process.env.REACT_APP_API_URL || 'http://localhost:3001',
  },
}

console.log(config)
