import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { FormResult, SelectQuestion as SelectQuestionType } from 'types/dist'
import { Select } from '../../../../components/Select'

interface Props {
  question: SelectQuestionType
  value: string | number
  setValue: UseFormSetValue<FormResult>
  disabled?: boolean
}

export const SelectQuestion = ({ question, value, setValue, disabled }: Props) => {
  const handleSelectChange = (variant: string) => {
    if (question.multipleAnswers) {
      const currentValue: string[] = Array.isArray(value) ? value : []
      const updatedValue = currentValue.includes(variant)
        ? currentValue.filter((v) => v !== variant)
        : [...currentValue, variant]

      setValue(question.key, updatedValue.length ? updatedValue : undefined)
    } else {
      setValue(question.key, variant)
    }
  }

  return question.multipleAnswers ? (
    <div>
      {question.variants.map((variant, i) => (
        <div className='flex items-center my-3 text-lg' key={`${question.key}_${i}`}>
          <input
            id={`${question.key}_${i}`}
            type='checkbox'
            disabled={disabled}
            className={`checkbox rounded-none border-2 checkbox-primary`}
            checked={Array.isArray(value) && value.includes(variant.value)}
            onChange={() => handleSelectChange(variant.value)}
          />
          <label
            className={`ml-3 ${Array.isArray(value) && value.includes(variant.value) ? 'text-black' : ''} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            htmlFor={`${question.key}_${i}`}
          >
            {variant.label}
          </label>
        </div>
      ))}
    </div>
  ) : (
    <Select
      value={value}
      options={question.variants.map(({ label, value }) => ({ label, value }))}
      onSelect={(variant) => setValue(question.key, variant)}
      name={question.key}
      disabled={disabled}
    />
  )
}
