import { useEffect, useState, useCallback } from "react"

export const useTitle = (titleProp: string) => {
  const [title, setTitle] = useState(titleProp)

  const changeTitle = useCallback((value: string) => {
    setTitle(value)
  }, []) 

  useEffect(() => {
    document.title = title || 'WellCell Global'
  }, [title])

  useEffect(() => {
    setTitle(titleProp)
  }, [titleProp])

  return [title, changeTitle] 
}