import React, { forwardRef, useEffect, useState } from 'react'
import { FormControl } from 'components/FormControl'
import { CheckboxColor, CheckboxProps } from 'components/Checkbox/types'
import clsx from 'clsx'

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      color = 'primary',
      focused,
      label,
      checked = false,
      inline = false,
      disabled = false,
      readOnly = false,
      ...props
    },
    ref
  ) => {
    const [isChecked, setIsChecked] = useState(checked)
    const onCheckboxClick = (event: { target: any; type?: any }) => {
      setIsChecked(!isChecked)
      props.onChange?.({ target: { ...event.target, value: event.target.checked } })
    }

    useEffect(() => {
      setIsChecked(checked)
    }, [checked])

    return (
      <FormControl
        disabled={!readOnly && disabled}
        focused={focused}
        label={label}
        containerClassName={'p-0'}
        inline={inline}
      >
        <input
          type='checkbox'
          disabled={disabled || readOnly}
          className={clsx(`checkbox rounded-none border-2 ${CheckboxColor[color]}`, {
            'ml-2': !!label
          })}
          checked={isChecked}
          ref={ref}
          {...props}
          onChange={onCheckboxClick}
        ></input>
      </FormControl>
    )
  }
)
