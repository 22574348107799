import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { CreateClinicDTO } from 'types'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import * as Toast from 'components/Toast'
import { clinics as ClinicActions } from 'data'
import { states } from 'constants/State'
import { Checkbox } from 'components/Checkbox'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { Autocomplete } from 'components/Autocomplete'
import { timezones } from 'constants/Timezone'

interface Props {
  data?: CreateClinicDTO
  type: 'edit' | 'add'
  setTaskDone: (done: boolean) => void
}

export const AddNewClinicModal: React.FC<Props> = ({ data, type, setTaskDone }) => {
  const [loading, setLoading] = useState(false)
  const dialogRef = useRef<HTMLFormElement | null>(null)
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    watch,
    control,
  } = useForm({ defaultValues: type === 'edit' ? data : {} })
  const enableLocalEmr = watch('enableLocalEmr')

  const onClose = useCallback(() => {
    setTaskDone(false)
  }, [setTaskDone])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose])

  const onSubmit = async (data: CreateClinicDTO) => {
    try {
      setLoading(true)
      if (type === 'add') {
        await ClinicActions.create(data)
        setTaskDone(true)
      } else {
        await ClinicActions.update(data)
        setTaskDone(true)
      }
      Toast.success('Clinic saved successfully')
    } catch (err: any) {
      Toast.error(err?.message ?? 'Clinic Update/Creation Failed!')
    } finally {
      setLoading(false)
    }
  }

  const onCancelClick: React.MouseEventHandler<HTMLFormElement> = e => {
    e.stopPropagation()
  }

  return (
    <dialog open className='modal backdrop-blur-sm' onClick={onClose}>
      <form
        ref={dialogRef}
        method='dialog'
        className='modal-box overflow-scroll h-auto hide-scrollbar relative'
        onSubmit={handleSubmit(onSubmit, Toast.formErrors)}
        onClick={onCancelClick}
      >
        <div className='absolute right-4 top-4'>
          <Button type="button" className='bg-white border-none hover:bg-white' onClick={onClose}>
            <Close />
          </Button>
        </div>

        <div className='flex flex-col space-y-2'>
          <p className='font-medium text-2xl my-4'>Add new Clinic</p>
          <Input
            label='Name'
            background='white'
            {...register('name', { required: 'Name is required' })}
            error={errors.name?.message}
          />
          <Input
            label='Street Address'
            background='white'
            {...register('streetAddress', { required: 'Street Address is required' })}
            error={errors.streetAddress?.message}
          />
          <Input label='Street Address 2' background='white' {...register('streetAddress2')} />
          <div className='grid grid-cols-2 gap-4'>
            <Input
              label='City'
              background='white'
              {...register('city', { required: 'City is required' })}
              error={errors.city?.message}
            />
            <Controller
              name='state'
              rules={{ required: 'Select a State' }}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  label='State'
                  value={field.value || ''}
                  background='white'
                  onChange={value => field.onChange(value)}
                  options={states}
                  error={errors.state?.message}
                />
              )}
            />
          </div>

          <Controller
            name="timezone"
            rules={{ required: 'Select a Timezone' }}
            control={control}
            render={({ field }) => (
              <Autocomplete
                label='Timezone'
                value={field.value || ''}
                background='white'
                onChange={value => field.onChange(value)}
                options={timezones}
                error={errors.timezone?.message}
              />
            )}
          />

          <Input
            label='Zip'
            background='white'
            {...register('zip', {
              required: 'Zip code is required',
              pattern: {
                value: /^(?!00000)(\d{5})(?!-0000)(-\d{4})?$/,
                message: 'Invalid Zip code',
              },
            })}
            error={errors.zip?.message}
          />
          <Input
            label='EMR ID'
            disabled={enableLocalEmr}
            background='white'
            {...register('sevocityId', { required: !enableLocalEmr && 'Sevocity ID is required' })}
            error={errors.sevocityId?.message}
          />

          <Controller
              name='enableLocalEmr'
              control={control}
              render={({ field }) => (
                <Checkbox
                  inline
                  label='Pre-EMR Clinic'
                  checked={field.value}
                  onChange={({ target: { checked } }) => {
                    field.onChange(checked)
                  }}
                />
              )}
            />

          <Button className='mt-4' disabled={!isDirty || loading}>
            {type === 'add' ? 'Add New Clinic' : 'Update Clinic'}
          </Button>
        </div>
      </form>
    </dialog>
  )
}
