import { Clinic } from './clinics'
import { PaginatedQuery } from './pagination'
import { Patient } from './patients'
import { SessionOutputDTO } from './sessions'

export enum APPOINTMENT_TYPE {
  UR1 = 'UR1',
  UR2 = 'UR2',
  UR3 = 'UR3',
  UR4 = 'UR4',
  UR5 = 'UR5',
  UR6 = 'UR6',
  UR7 = 'UR7',
  UR8 = 'UR8',
  UR9 = 'UR9',
  UR10 = 'UR10',
  T7 = 'T7',
  T14 = 'T14',
  T21 = 'T21',
  T28 = 'T28',
  T35 = 'T35',
  T42 = 'T42'
}

export enum APPOINTMENT_STATUS {
  SESSION_NOT_STARTED = 'session_not_started',
  SESSION_STARTED = 'session_started',
  PUMP_STARTED = 'pump_started',
  PUMP_PAUSED = 'pump_paused',
  SESSION_COMPLETED = 'session_completed',
  SESSION_CANCELED = 'session_canceled',
  SESSION_DELETED = 'session_deleted',
  SESSION_NOSHOW = 'session_no_show'
}

export type Appointment = {
  id: string
  externalId: string
  status: APPOINTMENT_STATUS
  clinicId: string
  patientId: string
  comment?: string | null
  created?: Date
  updated?: Date
  start: Date
  weight: number | null
  height: number | null
  a1c: number | null
  a1cDate: string | null
  end: Date
  duration?: Duration | null
  type?: APPOINTMENT_TYPE | null
  diabetes: DiabetesData | null
  session?: SessionOutputDTO | null
  allergies?: AllergyInfo[] | null
  emrData?: Record<string, unknown> | null | undefined
}

export type AppointmentDetail = Appointment & {
  patient: Patient
}

export type GenerateAppointmentDTO = Appointment

export type GenerateAppointmentsDTO = {
  count?: number
  start?: string
  clinicId?: string
  patientIds?: string[]
  type?: APPOINTMENT_TYPE
  status?: APPOINTMENT_STATUS
}

export type AppointmentOutputDTO = Appointment & {
  patient?: Patient | null
  clinic?: Clinic | null
}

export type SevocityAppointmentUpdateRequest = {
  medOrgId: string
  appointmentId: string
}

export type SevocityAppointmentUpdateRaw = {
  data: string
}

export const SEVOCITY_APPOINTMENT_QUEUE = 'SEVOCITY_APPOINTMENT_QUEUE'
export const EMR_EXPORT_QUEUE = 'EMR_EXPORT_QUEUE'

export type AppointmentListQuery = PaginatedQuery<Partial<
  Pick<Appointment, 'clinicId' | 'patientId' | 'status'> & {
    startDate: Date
    endDate: Date
    lastName: string
    type: APPOINTMENT_TYPE
    status: APPOINTMENT_STATUS
    clinicIds: string[]
    inProgress: boolean
    cancelled: boolean
    search: string
    onlyToday: boolean
  }
>>

export type AppointmentOrderByQuery = {
  fieldName: 'patientName' | 'start' | 'startTime' | 'duration' | 'type' | 'clinicName' | 'status' | 'dob' | 'age'
  order: 'ASC' | 'DESC'
}

export type Duration = {
  label: string,
  minutes: number
}

export enum IcdSystem {
  ICD10 = 'http://hl7.org/fhir/sid/icd-10-cm',
  ICD11 = 'http://hl7.org/fhir/sid/icd-11-cm',
} // or whatever else values are here

export type DiabetesData = Partial<Record<IcdSystem, string[]>>

export type AllergyInfo = {
  code?: string
  display: string
  system?: string
  confirmed: string
}
