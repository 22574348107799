import clsx from 'clsx'
import React from 'react'
import toast from 'react-hot-toast'

interface ToastMessageProps {
  id: string
  visible: boolean
  title: string
  children: React.ReactNode
  icon: React.ReactNode
  type: 'success' | 'info' | 'error' | 'warning'
}

export const ToastMessage: React.FC<ToastMessageProps> = ({
  type,
  icon,
  id,
  visible,
  title,
  children
}) => {
  return (
    <div
      className={clsx(`flex w-full justify-between
      alert bg-neutral text-primary-content max-w-md sm:max-w-xl shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 
      `, {
        'animate-enter': visible,
        'animate-leave': !visible
      })}
    >
      <div className='flex gap-x-4'>
        {icon}
        <div>
          <h3 className='font-bold'>{title}</h3>
          <div>{children}</div>
        </div>
      </div>
      <div className={clsx('flex-none border-l pl-2', {
       'border-success/50': type === 'success',
       'border-info/50': type === 'info',
       'border-warning/50': type === 'warning',
       'border-error/50': type === 'error'
      })}>
        <button
          className='btn btn-ghost'
          onClick={(e) => {
            e.stopPropagation()
            toast.dismiss(id)
          }}
        >
          Close
        </button>
      </div>
    </div>
  )
}