import React from 'react'
import { cn } from 'lib/cn'

export const Label = ({ name, className, hintText, children, required, ...props }: any) => {
  return (
    <label htmlFor={name} className={cn('label font-text py-1 flex flex-row justify-between', className)} {...props}>
      <span className={cn(`label-text text-base w-max mr-2`, className)}>
        {children} {required ? <span className='text-red-600'>**</span> : ''}
      </span>
    </label>
  )
}
