import { config } from 'config'
import * as qs from 'lib/qs'
import * as storage from 'lib/storage'
import { debounce } from './utils'
import { trackLogout } from 'data/user-history'

const tearDown = debounce(async () => {
  await trackLogout()
  storage.del('user')
  storage.del('userDetail')
  storage.del('token')
  window.location.href = '/login'
}, 500)

const send = async ({ path, method = 'GET', body, query, headers = {}, isBlob = false }: any) => {
  const token = storage.get('token', {})

  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    ...headers,
  }

  try {
    body = body ? JSON.stringify(body) : body
  } catch (error) {
    console.error(error)
  }

  let url = /https?/.test(path) ? path : `${config.api.url}${path}`

  if (query && Object.keys(query).length > 0) {
    url += (url.includes('?') ? '&' : '?') + qs.format(query)
  }

  try {
    const response = await fetch(url, { body, method, headers })
    const type = headers['Content-Type'] || ''

    if (response.status >= 200 && response.status < 300 && isBlob) return response.blob()

    if (response.status >= 200 && response.status < 300) {
      return type.includes('text') ? response.text() : response.json()
    }

    if (response.status === 401) {
      tearDown()
      throw new Error('Unauthorized')
    }

    const error = await response.json()
    throw new Error(error.message || response.statusText)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export default send
