import * as api from 'data/user/api'

export const fetch = (data: any) => {
  return api.fetch(data)
}

export const create = (data: any) => {
  return api.create(data)
}

export const update = (data: any) => {
  return api.update(data)
}

export const forgotPassword = (email: string) => {
  return api.forgotPassword({ email })
}