import React, { forwardRef, useEffect, useState } from 'react'
import { CheckboxProps } from 'components/Checkbox/types'

export const ToggleSwitch = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      color = 'primary',
      focused,
      label,
      checked = false,
      inline = false,
      disabled = false,
      readOnly = false,
      ...props
    },
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(checked)
    const onCheckboxClick = (event: { target: any; type?: any }) => {
      setIsChecked(!isChecked)
      props.onChange?.({ target: { ...event.target, value: event.target.checked } })
    }

    useEffect(() => {
      setIsChecked(checked)
    }, [checked])

    return (
      <label className='inline-flex items-center cursor-pointer'>
        <input
          type='checkbox'
          disabled={disabled || readOnly}
          checked={isChecked}
          ref={ref}
          onChange={onCheckboxClick}
          className={`sr-only peer bg-primary`}
        />
        <div className="relative w-11 h-6 bg-slate-300 focus:outline-none peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
        <span className='ms-3 text-sm font-medium text-black'>{label}</span>
      </label>
    )
  },
)
