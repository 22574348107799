import React, { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { APPOINTMENT_TYPE, AppointmentOutputDTO, Clinic, SESSION_STATUS_TYPE } from 'types'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { Input } from 'components/Input'
import { useAppointments, useAuth, useTitle } from 'hooks'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { Table } from 'components/Table'
import Pagination from 'components/Pagination'
import { getTodayData, resetShortEndDate, resetShortStartDate } from 'lib/day'
import { appointmentTypeOptions } from 'pages/private/Flowsheet/constants'
import { sessionStatusesLabels } from 'constants/AppointmentStatuses'
import { AppointmentStatusLabel } from './AppointmentStatusLabel'
import { InputDate } from 'components/Input/InputDate'
import { useUserSettings } from 'hooks/useUserSettings'
import { IOption } from 'components/Dropdown/types'

interface AppointmentTable {
  id: string
  patientName: string
  clinicName?: string
  start: string
  startTime: string
  duration: string
  type: APPOINTMENT_TYPE | ''
  status: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<AppointmentTable>[] = [
  { label: 'Patient Name', key: 'patientName' },
  { label: 'Date', key: 'start' },
  { label: 'Start Time', key: 'startTime' },
  { label: 'Duration', key: 'duration' },
  { label: 'Type', key: 'type' },
  {
    label: 'Clinic',
    key: 'clinicName'
  },
  { label: 'Status', key: 'status' },
]

const statusFilters: IOption[] = Object.values(sessionStatusesLabels).map((option) => ({ label: option.label, value: option.value }))

interface AppointmentSearch {
  fromDate?: string
  toDate?: string
  type?: string
  lastName?: string
  clinicIds?: string[]
  status?: string
}

const PAGE_SIZE = 10

export const Appointments = () => {
  useTitle('Appointments')
  const {
    getSortListingByScreen,
    getColumns,
    saveSortListing,
    saveColumns,
  } = useUserSettings()
  const [columns, setColumns] = useState(getColumns<IColumn<AppointmentTable>[]>('appointments') || defaultColumns)
  const sortListingDefaults = getSortListingByScreen('appointments')
  const [searchParams, setSearchParams] = useSearchParams()
  const today = getTodayData()
  const navigate = useNavigate()
  const defaultStartDateRef = useRef(dayjs(today.start_date).format('YYYY-MM-DD'))
  const { userDetail, clinicId } = useAuth()
  const defaultSearch: AppointmentSearch = {
    fromDate: searchParams.get('fromDate') || '',
    toDate: searchParams.get('toDate') || '',
    type: searchParams.get('type') || '',
    lastName:  searchParams.get('lastName') || '',
    clinicIds: searchParams.get('clinicIds') ? searchParams.get('clinicIds')?.split(',') : [clinicId || ''],
    status: searchParams.get('status') || ''
  }
  const orderBy = {
    fieldName: searchParams.get('orderby_field') as IOrderBy<AppointmentTable>['fieldName'] || sortListingDefaults?.fieldName || 'start',
    order: searchParams.get('orderby_order') as IOrderBy<AppointmentTable>['order'] || sortListingDefaults?.order || 'DESC'
  }
  const clinics = useMemo(() => userDetail?.memberships.map(membership => ({
    id: membership.clinic.id,
    name: membership.clinic.name
  })) || [], [userDetail?.memberships]) 

  const clinicsDic = useMemo(() => {
    return clinics.reduce((acc: Record<string, Omit<Clinic, "enableLocalEmr" | "timezone">>, clinic) => {
      acc[clinic.id] = clinic
      return acc
    }, {})
  }, [clinics])


  const query = {
    page: +(searchParams.get('page') || '1') - 1,
    size: PAGE_SIZE,
    start_date: resetShortStartDate(searchParams.get('fromDate') || defaultSearch.fromDate || ''),
    ...(searchParams.get('toDate') && { end_date: resetShortEndDate(searchParams.get('toDate') || '') }),
    ...(searchParams.get('type') && { type: searchParams.get('type') }),
    ...(defaultSearch.clinicIds?.reduce((acc: Record<string, string>, clinicId, idx) => {
      acc[`clinicIds[${idx}]`] = clinicId
      return acc
    }, {})),
    ...(searchParams.get('lastName') && { lastName: searchParams.get('lastName') }),
    ...(searchParams.get('status') && { status: searchParams.get('status')}),
    orderby_field: orderBy.fieldName,
    orderby_order: orderBy.order,
  }
  const { data: paginatedData } = useAppointments(query)
  const data = useMemo(() => paginatedData?.data || [], [paginatedData?.data]) 

  const {
    register,
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: defaultSearch
  })

  const appointmentRows = useMemo(() => {
    return data.map((appointment: AppointmentOutputDTO) => {
      const clinic = clinicsDic[appointment.clinicId]
      const row: AppointmentTable = {
        id: appointment.id,
        clinicName: clinic.name || '',
        patientName: `${appointment.patient?.lastName}, ${appointment.patient?.firstName}`,
        start: dayjs(appointment.start).format('MMM DD, YYYY'),
        startTime: dayjs(appointment.start).format('hh:mm A'),
        duration: appointment.duration?.label || '',
        type: appointment.type || '',
        status: {
          value: sessionStatusesLabels[(appointment?.session?.status || appointment.status) as SESSION_STATUS_TYPE]?.label,
          render: <AppointmentStatusLabel status={appointment?.session?.status || appointment.status} />,
        },
      }

      return row
    })
  }, [clinicsDic, data])

  const onSubmit = (data: AppointmentSearch) => {
    const searchInClinicIds = data.clinicIds?.length ? data.clinicIds : clinics.map(clinic => clinic.id)

    setSearchParams({
      clinicIds: searchInClinicIds.join(','),
      ...(!!data.fromDate && { fromDate: data.fromDate }),
      ...(!!data.toDate && { toDate: data.toDate }),
      ...(!!data.type && { type: data.type }),
      ...(!!data.lastName && { lastName: data.lastName }),
      ...(!!data.status && { status: data.status }),
      orderby_field: orderBy.fieldName,
      orderby_order: orderBy.order,
    }, { replace: true })
  }

  const onOrderBy = (options: IOrderBy<AppointmentTable>) => {
    setSearchParams(params => {
      params.set('orderby_field', options.fieldName)
      params.set('orderby_order', options.order)
      return params
    }, { replace: true })

    saveSortListing({
      screenName: 'appointments',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const onPageChange = (nextPage: number) => {
    setSearchParams(params => {
      params.set('page', nextPage.toString())
      return params
    }, { replace: true })
  }

  const onAppointmentClick = (id: string) => {
    const foundAppointment = data.find(appointment => appointment.id === id)

    if (!foundAppointment?.session?.status) {
      navigate(`/clinics/${clinicId}/appointments/${id}`)
    } else {
      navigate(`/clinics/${clinicId}/sessions/${id}`)
    }
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<AppointmentTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'appointments',
      columns: tableSettings.columns,
    })
  }

  return (
    <div className='w-full space-y-4 pb-4'>
      {/* Top Bar */}
      <div className='flex px-6 py-4 justify-between align-middle self-stretch items-center shadow-md border-2 border-gray-50 my-6 rounded-lg'>
        <span className='text-2xl font-medium mr-8'>
          Appointments
        </span>
      </div>

      {/* Search */}
      <form
        className='px-6 py-4 bg-light-blue rounded-lg space-y-4'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='flex justify-between space-y-2'>
          <h4 className='font-medium text-base'>Search for Appointments</h4>

          <div>
            <Button className='min-h-8 h-8 bg-secondary font-light lg:px-6' type='submit'>
              Search
            </Button>
          </div>
        </div>

        <div className='flex gap-4 flex-wrap'>
          <div>
            <InputDate
              label='From Date'
              placeholder='Enter Date'
              onClear={() => {
                defaultStartDateRef.current = ''
                setValue('fromDate', '')
              }}
              showClearOnStart={!!defaultSearch.fromDate?.length}
              {...register('fromDate')}
            />
          </div>

          <div>
            <InputDate
              label='To Date'
              placeholder='Enter Date'
              onClear={() => {
                setValue('toDate', '')
              }}
              showClearOnStart={!!defaultSearch.toDate?.length}
              {...register('toDate')}
            />
          </div>

          <div>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <Dropdown
                  value={field.value}
                  label='Type'
                  className='h-12'
                  background='white'
                  placeholder='Choose Type'
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  options={appointmentTypeOptions}
                />
              )}
            />
          </div>

          <div className='flex-1'>
            <Controller
              name='clinicIds'
              control={control}
              render={({ field }) => {
                return (
                  <Dropdown
                    value={Array.isArray(field.value) && field.value.length === 0 ? [] : field.value}
                    label="Clinic"
                    placeholder="All Clinics"
                    multiple
                    options={
                      Array.isArray(clinics)
                        ? clinics.map((clinic) => {
                          return { value: clinic?.id ?? '', label: clinic?.name ?? '' }
                        })
                        : []
                    }
                    className='h-12 min-w-[280px]'
                    onChange={({ target: { value } }) => {
                      field.onChange(Array.isArray(value) ? value : [])
                    }}
                  />
                )
              }}
            />

          </div>

          <div className='flex-1'>
            <Input
              type='text'
              label="Last Name"
              placeholder='Enter Last Name'
              autoComplete='last-name'
              onClear={() => {
                setValue('lastName', '')
              }}
              showClearOnStart={!!defaultSearch.lastName?.length}
              {...register('lastName')}
            />
          </div>

          <div className='flex-1'>
            <Controller
              name='status'
              control={control}
              render={({ field }) => {
                return (
                  <Dropdown
                    label='Status'
                    placeholder='Filter by status'
                    options={statusFilters}
                    className="h-12 min-w-[215px]"
                    value={field.value}
                    onChange={event => field.onChange(event.target.value)}
                  />
                )
              }}
            />
          </div>
        </div>
      </form>

      {/* List */}
      <Table
        onRowClick={onAppointmentClick}
        className='w-full'
        showSearch={false}
        title={"Appointments"}
        rows={appointmentRows}
        columns={columns}
        orderBy={orderBy}
        onOrderBy={onOrderBy}
        onSaveSettings={onSaveTableSettings}
      />

      {!!paginatedData && (
        <div className='flex justify-end'>
          <Pagination
            currentPage={paginatedData.page + 1}
            totalPages={Math.ceil(paginatedData.total / paginatedData.size)}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}
