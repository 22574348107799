import { useEffect, RefObject } from 'react'

function useDisableKeyboardNavigation(containerRef: RefObject<HTMLElement>) {
  useEffect(() => {
    const handleTabKey: EventListener = (e: Event) => {
      if (e instanceof KeyboardEvent && e.key === 'Tab') {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    const handleFocus: EventListener = (e: Event) => {
      e.preventDefault()
    }

    const attachEventListeners = (elements: Element[]) => {
      elements.forEach((element) => {
        element.addEventListener('keydown', handleTabKey)
        element.addEventListener('focus', handleFocus)
      })
    }

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const addedNodes = Array.from(mutation.addedNodes) as HTMLElement[]
          for (const node of addedNodes) {
            if (node?.querySelectorAll) {
              const newInputs = node.querySelectorAll('*')
              attachEventListeners(Array.from(newInputs))
            }
          }
        }
      }
    })

    if (containerRef.current) {
      const initialInputs = containerRef.current.querySelectorAll('*')
      attachEventListeners(Array.from(initialInputs))

      observer.observe(containerRef.current, { childList: true, subtree: true })

      return () => {
        initialInputs.forEach((element) => {
          element.removeEventListener('keydown', handleTabKey)
          element.removeEventListener('focus', handleFocus)
        })
        observer.disconnect()
      }
    }
  })
}

export default useDisableKeyboardNavigation
