export type ButtonBackgroundType = keyof typeof ButtonBackground
export enum ButtonBackground {
  'default' = 'bg-primary',
  'green' = 'bg-secondary border-secondary hover:bg-secondary hover:border-secondary',
  'gray' = 'bg-gray-300 border-gray-300 hover:border-gray-300 hover:bg-gray-200',
  'red' = 'bg-red-600 border-red-600 hover:border-red-600 hover:bg-red-500',
}

export interface ButtonProps {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  background?: ButtonBackgroundType
  children: React.ReactNode
  fullWidth?: boolean
  href?: string
  type?: 'button' | 'submit'
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase'
  disabled?: boolean
  className?: string
}
