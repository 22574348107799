import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { CurrentSessionInfoDTO, InfusionSession, NextSessionInfoDTO, PreviousSessionInfoDTO, SESSION_STATUS_TYPE } from 'types'
import { Dropdown } from './Dropdown'
import { Control, Controller } from 'react-hook-form'
import { appointmentTypeOptions } from 'pages/private/Flowsheet/constants'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'
import { useAuth } from 'hooks'

interface TopSectionProps {
  clinicName: string
  lastTimeSaved: string
  control: (triggerEvent: 'onBlur' | 'onChange') => Control<InfusionSession, any>
  finishSession?: () => Promise<boolean | undefined>
  refreshData?: () => Promise<void>
  data?: InfusionSession
  previousSession: PreviousSessionInfoDTO
  nextSession: NextSessionInfoDTO
  currentSession: CurrentSessionInfoDTO
}
export const TopSection = ({
  clinicName,
  lastTimeSaved,
  control,
  finishSession,
  data,
  refreshData,
  previousSession,
  nextSession,
  currentSession
}: TopSectionProps) => {
  const { clinicId } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<'finish' | 'refresh' | undefined>()
  const isReadyToFinish = !(data?.ivTime && !data?.ivDcTime)

  const onFinishSessionClick = async () => {
    if (finishSession) {
      setIsLoading('finish')
      await finishSession()
      setIsLoading(undefined)
    }
  }

  const onRefreshDataClick = async () => {
    if (refreshData) {
      setIsLoading('refresh')
      await refreshData()
      setIsLoading(undefined)
    }
  }

  const isSessionFinished =
    data?.status === SESSION_STATUS_TYPE.COMPLETED || data?.status === SESSION_STATUS_TYPE.SIGNED || data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED

  return (
    <div id='top-nav' className='flex flex-col'>
      <ConfirmationModal
        id='sessionConfirmationModal'
        title={'Finish session'}
        description={'Are you sure you want to finish the session?'}
        onConfirm={onFinishSessionClick}
      />
      <div className='fixed top-[30px] z-[70] right-10 m-2 print:hidden'>{lastTimeSaved}</div>
      <div className='flex justify-between items-center gap-10'>
        <div className='flex md:space-x-2 space-x-4 w-full print:hidden'>
          <Button className='h-10' onClick={() => navigate(-1)}>
            <ArrowLeft />
          </Button>
          {previousSession.previous && (
            <Button
              className='h-10 font-light text-md'
              onClick={() => navigate(`/clinics/${clinicId}/sessions/${previousSession.previous?.id}`, { replace: true })}
            >
              Previous
            </Button>
          )}
          {nextSession.next && (
            <Button
              className='h-10 font-light text-md'
              onClick={() => navigate(`/clinics/${clinicId}/sessions/${nextSession.next?.id}`, { replace: true })}
            >
              Next
            </Button>
          )}
          {currentSession.current && (
            <Button
              className='h-10 font-light text-md'
              onClick={() => navigate(`/clinics/${clinicId}/sessions/${currentSession.current?.id}`, { replace: true })}
            >
              Current
            </Button>
          )}
          <Button
            disabled={isLoading === 'refresh'}
            className='h-10 bg-secondary font-light'
            onClick={onRefreshDataClick}
          >
            Refresh Data {isLoading === 'refresh' ? <span className='loading loading-spinner loading-sm' /> : <></>}
          </Button>
          <Button
            onClick={() => openConfirmationModal('sessionConfirmationModal')}
            disabled={isLoading === 'finish' || isSessionFinished}
            className='h-10 font-light text-md grow max-w-md'
            background={isReadyToFinish ? 'default' : 'gray'}
          >
            Finish Session {isLoading === 'finish' ? <span className='loading loading-spinner loading-sm' /> : <></>}
          </Button>
        </div>
        <div className='flex flex-wrap flex-col-reverse align-middle items-center lg:flex-nowrap lg:flex-row md:space-x-2 lg:space-x-4 gap-2 print:flex-1 print:justify-between print:flex-row-reverse print:items-start'>
          <div className='flex flex-col'>
            <div className='hidden print:block text-lg font-bold text-right print:mb-4'>{clinicName}</div>
            <span className='md:text-sm lg:text-lg font-bold'>Infusion Flow Sheet</span>
            {isSessionFinished ? (
              <span className='md:text-sm lg:text-lg lg:whitespace-nowrap'>
                Appointment Type: <b>{data.type}</b>
              </span>
            ) : (
              <Controller
                name='type'
                control={control('onChange')}
                render={({ field }) => (
                  <Dropdown
                    inline
                    label='Appointment Type'
                    options={appointmentTypeOptions}
                    className='h-8 [&>label]:pr-8 min-w-[100px]'
                    background='white'
                    placeholder='Choose target'
                    required
                    {...field}
                  />
                )}
              />
            )}
          </div>
          <Logo className='min-w-[180px] w-[180px]' />
        </div>
      </div>
    </div>
  )
}
