import React, { useEffect } from 'react'
import { useAuth } from 'hooks'

export const Maintenance = () => {
  const { cleanupSession } = useAuth()

  useEffect(() => {
    cleanupSession()
  }, [cleanupSession])

  return (
    <div className='w-full h-full flex flex-col justify-center items-center space-y-8 py-8'>
      <h1 className='text-xl font-bold'>The system is undergoing maintenenance. It will be back online shortly.</h1>
      <img className='h-full' src="/maintenance.png" alt='Sorry, We are under Maintenance' />
    </div>
  )
}