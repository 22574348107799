import React, { forwardRef } from 'react'
import { cn } from 'lib/cn'
import { debounce } from 'lib/utils'
import { Input, InputProps } from './Input'
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifying-glass.svg'

interface SearchProps extends InputProps {
  onSearch: (query: string) => any
  placeHolder?: string
  className?: string
  defaultValue?: string
}

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      className,
      onSearch,
      placeHolder,
      ...props
    },
    ref
  ) => {
    const debounceOnSearch = debounce(onSearch, 500)

    return (
      <div className={cn('relative', className)}>
        <div className='w-auto flex justify-end items-center absolute left-2 top-0 bottom-0 z-10'>
          <MagnifyingGlass />
        </div>

        <Input
          ref={ref}
          className='input-md w-full h-8 pl-12'
          type='text'
          placeholder={placeHolder ?? 'Search'}
          {...props}
          onChange={(event: any) => debounceOnSearch(event?.target?.value)}
        />
      </div>
    )

  }
)