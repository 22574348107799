import useSWR from 'swr'
import { appointments } from 'data'
import { AppointmentOutputDTO, PaginatedResponse } from 'types'
import { useAuth } from 'hooks/useAuth'

export const useAppointment = (id?: string, options = {}) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<AppointmentOutputDTO, any>(
    id && clinicId ? [`/clinics/${clinicId}/appointments/${id}`] : null,
    () => appointments.fetch({ clinicId, id }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}

export const useAppointments = (query: any = {}, { ignore, ...options } = { ignore: false }) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<PaginatedResponse<AppointmentOutputDTO>, any>(
    !ignore && clinicId ? [`/clinics/${clinicId}/appointments`, clinicId, query] : null,
    () => appointments.fetch({ clinicId, ...query }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}

export const useCancelledAppointments = (query: any = {}, { ignore, ...options } = { ignore: false }) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<PaginatedResponse<AppointmentOutputDTO>, any>(
    !ignore && clinicId ? [`/clinics/${clinicId}/appointments/cancelled`, clinicId, query] : null,
    () => appointments.fetch({ clinicId, cancelled: true, ...query }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}
