import { matchSorter } from 'match-sorter'
import { IComplexCell, IRow, isInstanceOfComplexCell } from 'components/Table/types'

export const filterByQuery = <T>(searchQuery: string, rows: IRow<T>[]) => {
  const matchSorterKeysSet = new Set(
    rows
      .flatMap((row) => Object.entries(row))
      .map(([key, value]) => {
        if (isInstanceOfComplexCell(value)) {
          return `${key}.value`
        }
        return key
      })
  )

  if (searchQuery.length === 0) {
    return rows
  }
  return matchSorter(rows, searchQuery, {
    keys: Array.from(matchSorterKeysSet),
    // Disable sorting entirely
    sorter: rankedItems => rankedItems
  })
}

export const filterByKeyValue = <T>(key: keyof T, value: string | number, rows: IRow<T>[]) => {
  return rows.filter((row) => {
    const cell = row[key]
    if (isInstanceOfComplexCell(cell)) {
      return (cell as IComplexCell).value === value
    }
    return cell === value
  })
}
