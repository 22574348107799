import React, { useEffect, useState } from 'react'
import * as Toast from 'components/Toast'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { useForm } from 'react-hook-form'
import { useCreatePump } from 'hooks'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { AddNewPumpModalProps, AddNewPumpValues } from './types'

export const AddNewPumpModal = ({ onSave, onClose }: AddNewPumpModalProps) => {
  const [reqError, setReqError] = useState<string | null>(null)
  const { trigger: createPump, isMutating } = useCreatePump()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AddNewPumpValues>({
    defaultValues: {
      serialNumber: '',
      pumpNumber: undefined,
      hoursUsed: 0,
      sessionsUsed: 0,
    },
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose])

  const onSubmit = async (values: AddNewPumpValues) => {
    try {
      await createPump({
        ...values,
        active: 1
      })
      setReqError(null)
      onSave()
    } catch (e: any) {
      setReqError(e.message ?? `Error trying to create pump`)
    }
  }

  const onCancelClick: React.MouseEventHandler<HTMLFormElement> = e => {
    e.stopPropagation()
  }

  return (
    <dialog open className='modal backdrop-blur-sm' onClick={onClose}>
      <form
        onSubmit={handleSubmit(onSubmit, Toast.formErrors)}
        method='dialog'
        className='modal-box overflow-scroll relative'
        onClick={onCancelClick}
      >
        <div className='absolute right-4 top-4'>
          <Button type="button" className='bg-transparent border-none hover:bg-transparent' onClick={onClose}>
            <Close />
          </Button>
        </div>

        <h2 className='text-2xl mb-4'>
          Add new Pump {isMutating ? <span className='loading loading-spinner loading-sm' /> : <></>}
        </h2>
        <div className='flex flex-col gap-3'>
          {reqError && (
            <div className='text-error p-1'>
              {reqError}
            </div>
          )}

          <Input
            type='text'
            min={0}
            label='Serial number'
            error={errors.serialNumber?.message}
            {...register('serialNumber', {
              required: 'Serial Number is required',
              validate: (value: string) => {
                const serialNumberRegex = /^(?!0)(?!-)(?!.*-$)[0-9-]+$/
                if (!serialNumberRegex.test(value)) {
                  return 'Invalid serial number'
                }
                return true
              },
              onChange: e => setValue('serialNumber', e.target.value.trim())
            })}
          />
          <Input
            type='text'
            min={0}
            label='Pump number'
            error={errors.pumpNumber?.message}
            {...register('pumpNumber', {
              validate: (value: string) => {
                const pumpNumberRegex = /^[a-zA-Z0-9]+$/
                if (!pumpNumberRegex.test(value)) {
                  return 'Invalid pump number'
                }
                return true
              },
              required: 'Pump number is required',
            })}
          />
          <Input
            type='number'
            min={0}
            label='Hours used'
            error={errors.hoursUsed?.message}
            {...register('hoursUsed', {
              valueAsNumber: true,
              min: { value: 0, message: 'Minimum value is 0' },
              required: 'Hours used is required',
            })}
          />
          <Input
            type='number'
            min={0}
            label='Session used'
            error={errors.sessionsUsed?.message}
            {...register('sessionsUsed', {
              valueAsNumber: true,
              min: { value: 0, message: 'Minimum value is 0' },
              required: 'Session used is required',
            })}
          />
          <Button disabled={isMutating} className='mt-4' fullWidth>
            Add
          </Button>
        </div>
      </form>

      <button onClick={onClose}>close</button>
    </dialog>
  )
}
