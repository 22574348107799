import useSWR from 'swr'
import { assignmentRules } from 'data'

export const useAssignmentRules = (query: any = {}, { ignore, ...options } = { ignore: false }) => {
  const { data, error, mutate, isLoading } = useSWR(
    !ignore ? ['/assignment-rules', query] : null,
    () => assignmentRules.fetch({ ...query }),
    options,
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}
