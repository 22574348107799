// useBoot.ts
import { Field } from "types"
import { useFields } from "./useFields"
import { useEffect, useMemo } from "react"
import { useAuth } from "./useAuth"
import { useUser } from "./useUser"

type FieldObject = Record<string, Field>

const useNormalizeFields = (fields: Field[]) => {
  const fieldsObject = useMemo((): FieldObject | undefined => {
    if (!fields) return
    const fieldsArray = fields as Field[]

    return fieldsArray.reduce((acc: FieldObject, field: Field) => {
      acc[field.fieldId] = field

      return acc
    }, {})
  }, [fields])

  return fieldsObject
}

export const useBoot = () => {
  const { setSession, userDetail } = useAuth()
  const { data: fieldsData, isLoading: isLoadingFieldsLoading } = useFields({})
  const { data: userData, loading: isUserLoading } = useUser({
    id: userDetail?.id
  })
  const fieldsObject = useNormalizeFields(fieldsData)
  const isLoading = [isUserLoading, isLoadingFieldsLoading].some(isLoading => isLoading)

  // Init app fields
  useEffect(() => {
    if (isLoadingFieldsLoading || !fieldsObject) return
    setSession({
      fieldsDictionary: fieldsObject
    })
  }, [fieldsObject, isLoadingFieldsLoading, setSession])

  // Refresh user in session
  useEffect(() => {
    if (isUserLoading || !userData) return
    setSession({ userDetail: userData })
  }, [isUserLoading, setSession, userData])

  return {
    isBooting: isLoading
  }
}