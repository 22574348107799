import React, { useEffect, useState } from 'react'
import * as Toast from 'components/Toast'
import { useForm } from 'react-hook-form'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { pumps as PumpActions } from 'data'
import { ReactComponent as Close } from 'assets/icons/close.svg'

export type EditPumpValues = {
  id: string
  clinicId: string
  serialNumber: string
  pumpNumber: string
}

interface EditPumpModalProps {
  onSave: () => void,
  onClose: () => void,
  pump: EditPumpValues 
}

export const EditPumpModal: React.FC<EditPumpModalProps> = ({ pump, onSave, onClose }) => {
  const [reqError, setReqError] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm<EditPumpValues>({
    defaultValues: {
      ...pump,
      pumpNumber: pump.pumpNumber !== '0' ? pump.pumpNumber : undefined
    },
  })

  const onSubmit = async (values: EditPumpValues) => {
    try {
      await PumpActions.update({
        id: values.id,
        clinicId: values.clinicId,
        pumpNumber: values.pumpNumber,
      })
      onSave()
    } catch (e: any) {
      setReqError(e.message ?? `Error trying to create pump`)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose])

  const onCancelClick: React.MouseEventHandler<HTMLFormElement> = e => {
    e.stopPropagation()
  }

  return (
    <dialog open className='modal backdrop-blur-sm' onClick={onClose}>
      <form
        onSubmit={handleSubmit(onSubmit, Toast.formErrors)}
        method='dialog'
        className='modal-box overflow-visible relative'
        onClick={onCancelClick}
      >
        <div className='absolute right-4 top-4'>
          <Button type="button" className='bg-transparent border-none hover:bg-transparent' onClick={onClose}>
            <Close />
          </Button>
        </div>

        <h2 className='text-2xl mb-4'>
          Edit Pump
        </h2>
        <div className='flex flex-col gap-3'>
          {reqError && (
            <div className='text-error p-1'>
              {reqError}
            </div>
          )}

          <Input
            type='text'
            min={0}
            disabled
            label='Serial number'
            error={errors.serialNumber?.message}
            {...register('serialNumber', {
              required: 'Serial Number is required',
              onChange: e => setValue('serialNumber', e.target.value.trim())
            })}
          />
          <Input
            type='text'
            min={0}
            label='Pump number'
            error={errors.pumpNumber?.message}
            {...register('pumpNumber', {
              validate: (value: string) => {
                const pumpNumberRegex = /^[a-zA-Z0-9]+$/
                if (!pumpNumberRegex.test(value)) {
                  return 'Invalid pump number'
                }
                return true
              },
              required: 'Pump Number is required',
            })}
          />
          <Button disabled={!isDirty} className='mt-4' fullWidth>
            Save
          </Button>
        </div>
      </form>
    </dialog>
  )
}
