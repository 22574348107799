import { Patient, PatientOutput } from './patients'
import { APPOINTMENT_TYPE, AppointmentOutputDTO } from './appointments'
import { PaginatedQuery } from './pagination'
import { User } from './users'
import { FileLocation } from './common'

export type SessionQuery = PaginatedQuery<{
  patientId?: string
  clinicId?: string
}>

export type SessionOrderByQuery = {
  fieldName: 'created' | 'type' | 'target' | 'landing' | 'insulinConcentration' | 'provider',
  order: 'ASC' | 'DESC'
}

export enum TARGET_TYPE {
  RESISTANT = '180-220/Resistant/200',
  MODERATE = '150-190/Moderate/170',
  SENSITIVE = '120-160/Sensitive/140',
  CUSTOM = 'CUSTOM'
}

export enum INSULIN_CONCENTRATION {
  U = 'U',
  C = 'C',
  W = 'W',
  B = 'B',
  G = 'G',
  Y = 'Y',
  O = 'O',
  R = 'R',
}

export enum IV_GAUGE_TYPE {
  '18g' = '18g',
  '20g' = '20g',
  '22g' = '22g',
  '24g' = '24g',
  'Portacath' = 'Portacath',
}

export enum IV_SITE {
  'Forearm - Right' = 'Forearm - Right',
  'Forearm - Left' = 'Forearm - Left',
  'Hand - Right' = 'Hand - Right',
  'Hand - Left' = 'Hand - Left',
  'Wrist - Right' = 'Wrist - Right',
  'Wrist - Left' = 'Wrist - Left',
  'Antecubital - Right' = 'Antecubital - Right',
  'Antecubital - Left' = 'Antecubital - Left'
}

export enum INSULIN {
  'Novolin R' = 'Novolin R',
  'Humulin R' = 'Humulin R'
}

export enum SESSION_STATUS_TYPE {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  PUMP_RUNNING = 'PUMP_RUNNING',
  PUMP_PAUSED = 'PUMP_PAUSED',
  COMPLETED = 'COMPLETED',
  SIGNED = 'SIGNED',
  SESSION_CANCELED = 'SESSION_CANCELED'
}

export enum SESSION_UPDATE_FIELDS {
  'type' = 'type',
  'patientWeight' = 'patientWeight',
  'meal' = 'meal',
  'mealTime' = 'mealTime',
  'npo' = 'npo',
  'target' = 'target',
  'targetMin' = 'targetMin',
  'targetMax' = 'targetMax',
  'landing' = 'landing',
  'insulin' = 'insulin',
  'insulinPump' = 'insulinPump',
  'insulinConcentration' = 'insulinConcentration',
  'ivGauge' = 'ivGauge',
  'ivSite' = 'ivSite',
  'ivTime' = 'ivTime',
  'ivDcTime' = 'ivDcTime',
  'changeInMeds' = 'changeInMeds',
  'flushedPerProtocol' = 'flushedPerProtocol',
  'dressingClear' = 'dressingClear',
  'sleep' = 'sleep',
  'pain' = 'pain',
  'energy' = 'energy',
  'nocturia' = 'nocturia',
  'commentsFood' = 'commentsFood',
  'commentsForNextSession' = 'commentsForNextSession',
  'medicineAdministered' = 'medicineAdministered',
  'preFlightBsReadings' = 'preFlightBsReadings',
  'infusionSessionHours' = 'infusionSessionHours',
  'infusionSessionComments' = 'infusionSessionComments',
  'pdfLocation' = 'pdfLocation',
  'hr1ForceProceedInfusion' = 'hr1ForceProceedInfusion',
  'exportStatus' = 'exportStatus'
}

export enum SESSION_INSULIN_PUMP {
  NA='N/A',
  OFF='OFF',
  ON='ON'
}

export interface MedicineAdministered {
  name: string
  dose?: number
  unit?: string
  route?: string
  comments?: string
  time: string
  initials: string
  userId: number
}

export interface PreFlightBsReading {
  sequenceNumber: number
  time: string
  value: number
  glucose: number
  initials: string
  userId: number
}

export interface InfusionSessionInterval {
  sequenceNumber: number
  time: string
  intervalDuration: number
  bloodSugar: number
  glucose: number
  drValue: number
  qValue: number
  pumpId: string
  pumpPaused: boolean
  initials: string
  userId: number
  completed?: boolean
  isDeleted?: boolean
}

export interface InfusionSessionHour {
  sequenceNumber: number
  initials: string
  userId: number
  time: string
  temperature: number
  bloodPressure: string
  pulse: number
  respiratoryRate: number
  infusionSessionIntervals: InfusionSessionInterval[]
  completed?: boolean
}

export interface InfusionSessionComment {
  initials: string
  userId: string
  time: string
  comment: string
  isAddendum: boolean
}

export type SessionExportStatusSuccess = {
  status: 'success'
  data: Record<string, unknown>
}

export type SessionExportStatusError = {
  status: 'error'
  message: string
}

export type SessionExportStatusResult = (SessionExportStatusSuccess | SessionExportStatusError) & { timestamp: Date }

export type SessionExportStatus = Partial<{
  [id in keyof typeof SESSION_PUBLISHERS]: SessionExportStatusResult
}>

export interface InfusionSession {
  id?: string
  patientId: string
  currentPumpId?: string
  userId?: string
  patient: Patient
  type: APPOINTMENT_TYPE
  patientWeight?: number
  meal?: string,
  mealTime?: string,
  insulinPump?: SESSION_INSULIN_PUMP,
  npo?: boolean,
  target?: TARGET_TYPE
  targetMin?: string
  targetMax?: string
  landing?: number
  lastSessionId?: string
  nextSessionComments?: string
  insulin?: INSULIN
  insulinConcentration?: INSULIN_CONCENTRATION
  ivGauge?: IV_GAUGE_TYPE
  ivTime?: string
  ivInitials?: string
  ivUserId?: string
  ivSite?: IV_SITE
  ivDcTime?: string
  ivDcInitials?: string
  ivDcUserId?: string
  changeInMeds?: boolean
  flushedPerProtocol?: boolean
  dressingClear?: boolean
  startedAt?: string
  startedBy?: number
  status?: SESSION_STATUS_TYPE
  sleep?: number
  pain?: number
  energy?: number
  nocturia?: number
  commentsFood?: string
  commentsForNextSession?: string
  finishedAt?: string
  signedAt?: string
  signedBy?: string
  canceledAt?: Date
  canceledBy?: string
  signingProviderName?: string
  signingProviderNpi?: string
  medicineAdministered?: MedicineAdministered[]
  preFlightBsReadings?: PreFlightBsReading[]
  infusionSessionHours?: InfusionSessionHour[]
  infusionSessionComments?: InfusionSessionComment[]
  updated?: string
  created?: string
  appointment: AppointmentOutputDTO
  pdfLocation?: FileLocation
  hr1ForceProceedInfusion?: boolean
  exportStatus?: SessionExportStatus
  exportStartedAt?: Date
  exportScheduledAt?: Date
  isClosedForComments?: Date
}

/**
 * MODEL
 *
 * updated by events, queried by queries
 * can have sub-objects, should use references (clinicId: number) to external entities
 * can use Maps for easier access to compound properties
 */

export type SessionModelEntity = {
  id?: string
  patientId?: string
  currentPumpId?: string
  userId?: string | null
  nextSessionComments?: string
  type?: APPOINTMENT_TYPE | null
  patient?: PatientOutput
  patientWeight?: number
  target?: TARGET_TYPE | null
  targetMin?: string
  targetMax?: string
  landing?: number | null
  insulin?: INSULIN
  insulinConcentration?: INSULIN_CONCENTRATION | null
  ivGauge?: IV_GAUGE_TYPE
  ivTime?: string
  ivInitials?: string
  ivUserId?: string
  ivSite?: IV_SITE
  ivDcTime?: string
  ivDcInitials?: string
  ivDcUserId?: string
  changeInMeds?: boolean
  flushedPerProtocol?: boolean
  dressingClear?: boolean
  startedAt?: Date
  startedBy?: string
  status?: SESSION_STATUS_TYPE | null
  sleep?: number
  pain?: number
  energy?: number
  nocturia?: number
  commentsFood?: string
  commentsForNextSession?: string
  finishedAt?: Date
  finishedBy?: string
  canceledAt?: Date
  canceledBy?: string
  signedAt?: Date
  signedBy?: string | null
  signingProviderName?: string
  signingProviderNpi?: string
  medicineAdministered?: MedicineAdministered[]
  preFlightBsReadings?: PreFlightBsReading[]
  infusionSessionHours?: InfusionSessionHour[]
  infusionSessionComments?: InfusionSessionComment[]
  updated?: Date
  created?: Date
  appointment?: AppointmentOutputDTO
  pdfLocation?: FileLocation
  hr1ForceProceedInfusion?: boolean
  exportStatus?: SessionExportStatus
  exportStartedAt?: Date
  exportScheduledAt?: Date
}

export type EventMeta = {
  userId: string
  userInitials: string
}

/**
 * CREATE & UPDATE
 */

export type CreateSessionDTO = Omit<SessionModelEntity, 'created' | 'updated'> & {
  appointmentId?: string
}

export type UpdateSessionDTO = SessionModelEntity & {
  userId?: string
}

export type UpdateSessionFieldDTO = {
  key: keyof SessionModelEntity | string
  value: unknown
}

export type Session = Pick<SessionModelEntity, 'id' | 'type' | 'patientId' | 'created' | 'updated' | 'target' | 'landing' | 'insulinConcentration' | 'status' | 'patient' | 'appointment'> & {
  user?: User
  signedByUser?: User
}

/**
 * PROJECTION
 *
 * projection, used in SQL queries (search/aggregation), must be plain sql-compatible object
 * should have references to linked entities (e.g. companyId: number instead of company: Company
 * can have extra fields not stored in the model
 */

export type SessionProjection = Omit<SessionModelEntity, 'type'>

/**
 * OUTPUT
 *
 *
 */

export type SessionOutputDTO = Omit<SessionModelEntity, 'patientId' | 'appointmentId'> & {
  isClosedForComments?: boolean
}
// & {
//   patient: PatientOutput
//   appointment: AppointmentOutputDTO
// }

/**
 * SHOW
 *
 * end result sent to HTTP output by interceptor
 * removes "clinicId: number" and replaces by "clinic: Clinic"
 * eliminates maps (converts to arrays)
 */

export type SessionShowDTO = SessionOutputDTO

/**
 * SEARCH
 *
 * used as search request
 * may include ranges, conditions, filters, sorts etc
 */

export type SessionSearchDTO = SessionModelEntity

export type PreviousSessionInfoDTO = {
  id: string
  previous: null | {
    id: string
    comment?: string
  }
}

export type NextSessionInfoDTO = {
  id: string
  next: null | {
    id: string
    comment?: string
  }
}

export type CurrentSessionInfoDTO = {
  id: string
  current: null | {
    id: string
    comment?: string
  }
}

export type PumpUsageDelta = Record<string, number>

export type SevocityExportDTO = {
  client: 'WellCell'
  resourceType: 'Infusion'
  identifier: string
  infusionData: string
}

export const SESSION_EXPORT_PDF_QUEUE = 'SESSION_EXPORT_PDF_QUEUE'
export type SessionExportPDFJob = {
  sessionId: string
  clinicId: string
}
export type EMRExportJob = {
  sessionId: string
  publisher: string,
  userId?: string
}

export enum SESSION_PUBLISHERS {
  'WellcellPublisher' = 'WellcellPublisher',
  'SevocityPublisher' = 'SevocityPublisher',
  'PDFPublisher' = 'PDFPublisher'
}
