import { get, post, patch, del } from 'lib/api'
import { toId } from 'lib/utils'

export const fetch = ({ id, clinicId, ...data }: any = {}) => get(`/clinics/${clinicId}/sessions${toId(id)}`, data)
export const create = ({ clinicId, ...data }: any) => post(`/clinics/${clinicId}/sessions`, data)
export const update = ({ id, clinicId, data }: any = {}) => patch(`/clinics/${clinicId}/sessions/${id}`, data)
export const start = ({ id, clinicId, ...data }: any = {}) => patch(`/clinics/${clinicId}/sessions/${id}/start`, data)
export const finish = ({ id, clinicId, ...data }: any = {}) => patch(`/clinics/${clinicId}/sessions/${id}/finish`, data)
export const sign = ({ id, clinicId, ...data }: any = {}) => patch(`/clinics/${clinicId}/sessions/${id}/sign`, data)
export const previous = ({ id, clinicId, ...data }: any = {}) =>
  get(`/clinics/${clinicId}/sessions/${id}/previous`, data)
export const next = ({ id, clinicId, ...data }: any = {}) =>
  get(`/clinics/${clinicId}/sessions/${id}/next`, data)
export const current = ({ id, clinicId, ...data }: any = {}) =>
  get(`/clinics/${clinicId}/sessions/${id}/current`, data)
export const genPDF = ({ id, clinicId, ...data }: any) => post(`/clinics/${clinicId}/sessions/${id}/gen-pdf`, data, { isBlob: true })
export const deleteInfusionInterval = ({ id, clinicId, ...data }: any = {}) => del(`/clinics/${clinicId}/sessions/${id}/delete-infusion-interval`, data)