import React, { useCallback, useState } from 'react'
import { useAuth } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { SelectClinicModal } from 'components/Modals/SelectClinic/SelectClinic'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'

interface SelectClinicContainerProps {
  title?: string
  onClose?: () => void
}

export const SelectClinicContainer: React.FC<SelectClinicContainerProps> = ({ title, onClose }) => {
  const [selectedClinicId, setSelectedClinicId] = useState<string | null>(null)
  const navigate = useNavigate()
  const { setSession, userDetail } = useAuth()

  const setClinic = () => {
    if (!selectedClinicId) return
    const foundClinic = userDetail?.memberships.find(membership => membership.clinic.id === selectedClinicId)?.clinic

    setSession({
      clinicId: selectedClinicId,
      enableLocalEmr: !!foundClinic?.enableLocalEmr
    })

    if (onClose)
      onClose()
    navigate('/')
  }

  const confirmProcess = (clinicId: string) => {
    setSelectedClinicId(clinicId)
    openConfirmationModal('selectClinicConfirmationModal')
  }

  const onCancelConfirmation = useCallback(() => setSelectedClinicId(null), [])

  return (
    <>
      <SelectClinicModal
        title={title}
        onClose={onClose}
        setSelectedClinic={confirmProcess}
      />

      <ConfirmationModal
        id='selectClinicConfirmationModal'
        title={'Confirm Clinic'}
        description={'Please be aware. By changing clinics, the entire view of patients and their appointments will change to reflect the new clinic you are logging into. Proceed?'}
        onConfirm={setClinic}
        onCancel={onCancelConfirmation}
      />
    </>
  )
}