import * as api from 'data/session/api'

export const fetch = (data: any) => {
  return api.fetch(data)
}

export const create = (data: any) => {
  return api.create(data)
}

export const update = (data: any) => {
  const { id, clinicId, ...rest } = data
  data = Object.entries(rest).map(([key, value]) => ({ key, value }))
  return api.update({ id, clinicId, data })
}

export const start = (data: any) => {
  return api.start(data)
}

export const deleteInfusionInterval = (data: any) => {
  return api.deleteInfusionInterval(data)
}

export const finish = (data: any) => {
  return api.finish(data)
}

export const sign = (data: any) => {
  return api.sign(data)
}

export const previous = (data: any) => {
  return api.previous(data)
}

export const next = (data: any) => {
  return api.next(data)
}

export const current = (data: any) => {
  return api.current(data)
}

export const genPDF = (data: any) => {
  return api.genPDF(data)
}