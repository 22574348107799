import useSWR from 'swr'
import { clinics } from 'data'
import { useState } from 'react'
import { ClinicDetail } from 'types'

interface PaginationOptions {
  pageSize: number
  currentPage: number
}

export const useClinics = (query: any, options = {}) => {
  const { data, error, mutate } = useSWR(['/clinics', query], ([, query]: any[]) => clinics.fetch(query), options)

  return {
    data,
    error,
    mutate,
    loading: !error && !data,
  }
}

export const useFilteredClinics = (clinics: ClinicDetail[]) => {
  const [searchText, setSearchText] = useState<string>('')
  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
    pageSize: 10,
    currentPage: 1,
  })

  const setSearch = (text: string) => {
    setSearchText(text)
    setPaginationOptions({ ...paginationOptions, currentPage: 1 })
  }

  const filteredList = clinics.filter((clinic) =>
    Object.values(clinic).some(
      (value) => typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
    )
  )

  const pageCount = Math.ceil(filteredList.length / paginationOptions.pageSize)
  const offset = (paginationOptions.currentPage - 1) * paginationOptions.pageSize
  const paginatedList = filteredList.slice(offset, offset + paginationOptions.pageSize)

  const goToPage = (page: number) => {
    setPaginationOptions({ ...paginationOptions, currentPage: page })
  }

  return { filteredList: paginatedList, setSearch, pageCount, goToPage, currentPage: paginationOptions.currentPage }
}
