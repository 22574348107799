import React, { useMemo, useState } from 'react'
import * as Toast from 'components/Toast'
import { useFields } from 'hooks/useFields'
import { Field } from 'types'
import { FieldRecord } from './FieldRecord'
import { fields as FieldActions } from 'data'
import { useTitle } from 'hooks'

type FieldObject = Record<string, Field>

export const FieldsManagement = () => {
  useTitle('Fields management')
  const [collapsedFields, setCollapsedFields] = useState<Map<string, boolean>>(new Map<string, boolean>())
  const { data, mutate } = useFields({})

  const fieldsObject = useMemo((): FieldObject => {
    if (!data) return {}
    const fieldsArray = data as Field[]

    return fieldsArray.reduce((acc: FieldObject, field: Field) => {
      acc[field.fieldId] = field

      return acc
    }, {})
  }, [data])

  const onSave = async (field: Field): Promise<Boolean> => {
    try {
      // Update
      if (field.id) {
        await FieldActions.update(field)
      }

      // Create
      if (!field.id) {
        await FieldActions.create(field)
      }

      Toast.success(`Field ${field.fieldLabel} saved successfully`)
      mutate()
      return true
    } catch(err: any) {
      Toast.error(err?.message ?? 'Field Update/Creation Failed!')
    }

    return false
  }

  const onToggle = (fieldId: string, value: boolean) => {
    setCollapsedFields(map => {
      return new Map(map.set(fieldId, value))
    })
  }

  return (
    (
      <div className='w-full pb-4'>
        <div className='flex px-6 py-4 bg-white sticky z-50 top-14 justify-between align-middle self-stretch shadow-md border-2 border-gray-50 my-6 rounded-lg'>
          <span className='text-2xl font-medium mr-8'>Fields Management</span>
        </div>

        <div className='space-y-2'>
          <FieldRecord
            key={`field-race-${fieldsObject.race?.fieldId}`}
            id={fieldsObject.race?.id}
            fieldLabel={fieldsObject.race?.fieldLabel || 'Race'}
            fieldId='race'
            values={fieldsObject.race?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('race')}
          />

          <FieldRecord
            key={`field-gender-${fieldsObject.gender?.fieldId}`}
            id={fieldsObject.gender?.id}
            fieldLabel={fieldsObject.gender?.fieldLabel || 'Gender'}
            fieldId='gender'
            values={fieldsObject.gender?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('gender')}
          />

          <FieldRecord
            key={`field-insulin-${fieldsObject.insulin?.fieldId}`}
            id={fieldsObject.insulin?.id}
            fieldLabel={fieldsObject.insulin?.fieldLabel || 'Insulin'}
            fieldId='insulin'
            values={fieldsObject.insulin?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('insulin')}
          />

          <FieldRecord
            key={`field-insulinConcentration-${fieldsObject.insulinConcentration?.fieldId}`}
            id={fieldsObject.insulinConcentration?.id}
            fieldLabel={fieldsObject.insulinConcentration?.fieldLabel || 'Concentration'}
            fieldId='insulinConcentration'
            values={fieldsObject.insulinConcentration?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('insulinConcentration')}
          />

          <FieldRecord
            key={`field-ivGauge-${fieldsObject.ivGauge?.fieldId}`}
            id={fieldsObject.ivGauge?.id}
            fieldLabel={fieldsObject.ivGauge?.fieldLabel || 'Gauge'}
            fieldId='ivGauge'
            values={fieldsObject.ivGauge?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('ivGauge')}
          />

          <FieldRecord
            key={`field-ivSite-${fieldsObject.ivSite?.fieldId}`}
            id={fieldsObject.ivSite?.id}
            fieldLabel={fieldsObject.ivSite?.fieldLabel || 'Site'}
            fieldId='ivSite'
            values={fieldsObject.ivSite?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('ivSite')}
          />

          <FieldRecord
            key={`field-insulinPump-${fieldsObject.insulinPump?.fieldId}`}
            id={fieldsObject.insulinPump?.id}
            fieldLabel={fieldsObject.insulinPump?.fieldLabel || 'Insulin Pump'}
            fieldId='insulinPump'
            values={fieldsObject.insulinPump?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('insulinPump')}
          />

          <FieldRecord
            key={`field-med-${fieldsObject.med?.fieldId}`}
            id={fieldsObject.med?.id}
            fieldLabel={fieldsObject.med?.fieldLabel || 'Med'}
            fieldId='med'
            values={fieldsObject.med?.values || []}
            onSave={onSave}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('med')}
          />

          <FieldRecord
            key={`field-qInterval-${fieldsObject.qInterval?.fieldId}`}
            id={fieldsObject.qInterval?.id}
            fieldLabel={fieldsObject.qInterval?.fieldLabel || 'Q'}
            fieldId='qInterval'
            values={fieldsObject.qInterval?.values || []}
            onSave={onSave}
            inputValueProps={{
              type: 'number',
              min: 1
            }}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('qInterval')}
          />

          <FieldRecord
            key={`field-vInterval-${fieldsObject.vInterval?.fieldId}`}
            id={fieldsObject.vInterval?.id}
            fieldLabel={fieldsObject.vInterval?.fieldLabel || 'V'}
            fieldId='vInterval'
            values={fieldsObject.vInterval?.values || []}
            onSave={onSave}
            inputValueProps={{
              type: 'number',
              min: 1
            }}
            onToggle={onToggle}
            isCollapsed={!!collapsedFields.get('vInterval')}
          />
        </div>
      </div>
    )
  )
}