import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { sessions } from 'data'
import { CreateSessionDTO, UpdateSessionDTO, PreviousSessionInfoDTO, SESSION_STATUS_TYPE, NextSessionInfoDTO, CurrentSessionInfoDTO } from 'types'
import { useAuth } from 'hooks/useAuth'

export const useSession = (id?: string, options = {}) => {
  return useSessions({ id }, { ...options, ignore: !id })
}

export const useSessions = (query: any = {}, { ignore, ...options } = { ignore: false }) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR(
    !ignore && clinicId ? ['/sessions', clinicId, query] : null,
    () => sessions.fetch({ clinicId, ...query }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}

export const useCreateSession = (options = {}) => {
  const { clinicId } = useAuth()
  const { error, trigger, isMutating } = useSWRMutation(
    ['/sessions', clinicId],
    (_, { arg }: { arg: CreateSessionDTO }) => {
      return clinicId ? sessions.create({ ...arg, clinicId }) : null
    },
    options
  )

  return {
    error,
    trigger,
    isMutating,
  }
}

export const useHasCompletedSessions = (patientId: string) => {
  const { clinicId } = useAuth()

  const { data } = useSWR(!patientId ? null : ['has-prev-sessions', clinicId, patientId], () => sessions.fetch({
    clinicId,
    patientId,
    page: 0,
    size: 1,
    status: SESSION_STATUS_TYPE.COMPLETED
  }))

  return data?.total > 0
}

export const useUpdateSession = (id: string | undefined, options = {}) => {
  const { clinicId } = useAuth()
  const { error, trigger, isMutating } = useSWRMutation(
    ['/sessions/update', clinicId, { id }],
    (_, { arg }: { arg: UpdateSessionDTO }) => {
      return clinicId && id ? sessions.update({ ...arg, id, clinicId }) : null
    },
    options
  )

  return {
    error,
    trigger,
    isMutating,
  }
}

export const useStartSession = (id: string | undefined, options = {}) => {
  const { clinicId } = useAuth()
  const { error, trigger, isMutating } = useSWRMutation(
    ['/sessions/start', clinicId, { id }],
    () => {
      return clinicId && id ? sessions.start({ id, clinicId }) : null
    },
    options
  )

  return {
    error,
    trigger,
    isMutating,
  }
}

export const useFinishSession = (id: string | undefined, options = {}) => {
  const { clinicId } = useAuth()
  const { error, trigger, isMutating } = useSWRMutation(
    ['/sessions/finish', clinicId, { id }],
    () => {
      return clinicId && id ? sessions.finish({ id, clinicId }) : null
    },
    options
  )

  return {
    error,
    trigger,
    isMutating,
  }
}

export const useSignSession = (id: string | undefined, options = {}) => {
  const { clinicId } = useAuth()
  const { error, trigger, isMutating } = useSWRMutation(
    ['/sessions/sign', clinicId, { id }],
    () => {
      return clinicId && id ? sessions.sign({ id, clinicId }) : null
    },
    options
  )

  return {
    error,
    trigger,
    isMutating,
  }
}

export const usePreviousSession = (id?: string, options = {}) => {
  return usePreviousSessions(id, { ...options, ignore: !id })
}

export const usePreviousSessions = (id: string | undefined, { ignore, ...options } = { ignore: false }) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<PreviousSessionInfoDTO, any>(
    !ignore && clinicId && id ? ['/sessions/previous', clinicId, { id }] : null,
    () => sessions.previous({ clinicId, id }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}

export const useNextSession = (id?: string, options = {}) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<NextSessionInfoDTO, any>(
    clinicId && id ? ['/sessions/next', clinicId, { id }] : null,
    () => sessions.next({ clinicId, id }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}

export const useCurrentSession = (id?: string, options = {}) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<CurrentSessionInfoDTO, any>(
    clinicId && id ? ['/sessions/current', clinicId, { id }] : null,
    () => sessions.current({ clinicId, id }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}