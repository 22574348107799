import useSWR from 'swr'
import { UserDetail } from 'types'
import { users } from 'data'

export const useUsers = (
  query: any,
  options = {}
): {
  data: UserDetail[] | UserDetail | undefined
  error: any
  paginationData: {
    size: number
    page: number
    total: number
  }
  mutate: (path?: string) => Promise<any>
  loading: boolean
} => {
  const { data, error, mutate } = useSWR(['/users', query], ([, query]: any[]) => users.fetch(query), options)
  return {
    data: data?.data ?? data ?? undefined,
    paginationData: {
      size: data?.size,
      page: data?.page,
      total: data?.total,
    },
    error,
    mutate,
    loading: !error && !data,
  }
}

export const useUser = (
  query: any,
  optionProps: any = {}
): {
  data: UserDetail[] | UserDetail | undefined
  error: any
  paginationData: {
    size: number
    page: number
    total: number
  }
  mutate: (path?: string) => Promise<any>
  loading: boolean
} => {
  const omit = optionProps.omit || !query.id
  const { data, error, mutate } = useSWR(omit ? null : ['/users', query.id], () => {
    return users.fetch({ id: query.id })
  })
  return {
    data: data?.data ?? data ?? undefined,
    paginationData: {
      size: data?.size,
      page: data?.page,
      total: data?.total,
    },
    error,
    mutate,
    loading: !error && !data && !omit,
  }
}