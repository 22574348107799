import useSWR from 'swr'
import { PatientOutputDTO } from 'types'
import { patients } from 'data'

export const usePatients = (
  query: any,
  options = {},
  skip = false
): {
  data: PatientOutputDTO[] | PatientOutputDTO | undefined
  error: any
  paginationData: {
    size: number
    page: number
    total: number
  }
  mutate: (path?: string) => Promise<any>
  loading: boolean
} => {
  const key = !skip ? ['/patients', query] : null;
  const { data, error, mutate } = useSWR(key, ([, query]: any[]) => patients.fetch(query), options)

  return {
    data: data?.data ?? data ?? undefined,
    paginationData: {
      size: data?.size,
      page: data?.page,
      total: data?.total,
    },
    error,
    mutate,
    loading: !error && !data && !skip,
  }
}