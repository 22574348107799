import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonBackground, ButtonProps } from 'components/Button/types'
import clsx from 'clsx'

export const Button = ({
  onClick,
  children,
  fullWidth,
  href,
  type,
  disabled,
  background = 'default',
  textTransform = 'capitalize',
  className = '',
}: ButtonProps) => {
  return href ? (
    <Link
      to={href}
      className={clsx(`btn btn-primary text-white ${ButtonBackground[background]} ${textTransform} ${className}`, {
        'w-full': fullWidth
      })}
    >
      {children}
    </Link>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={clsx(`btn btn-primary text-white ${ButtonBackground[background]} ${textTransform} ${className}`, {
        'w-full': fullWidth
      })}
    >
      {children}
    </button>
  )
}
