import React, { useState } from 'react'
import { TooltipProps } from 'components/Tooltip/types'

const Tooltip = ({ children, text, className, position = 'top' }: TooltipProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div className='relative inline-block'>
      <div className='inline-block cursor-pointer' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <span className={className}>{text}</span>
      </div>
      {isHovered && (
        <>
          <div className={`absolute z-10 bg-white text-black rounded-lg whitespace-nowrap left-0 ${position}-full `}>
            <div className='inline-flex p-4 flex-col gap-2 rounded-md shadow-md'>{children}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default Tooltip
