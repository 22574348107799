import React, { useCallback, useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'hooks'
import { ConfirmationModal, openConfirmationModal } from './Modals/ConfirmationModal'

export const ClinicProtectedRoute = () => {
  const auth = useAuth()
  const { clinicId } = useParams()
  const navigate = useNavigate()
  const clinicTo = auth.userDetail?.memberships.find(membership => membership.clinic.id === clinicId)?.clinic
  const clinicFrom = auth.userDetail?.memberships.find(membership => membership.clinic.id === auth.clinicId)?.clinic
  const hasAccess = auth.userDetail?.memberships.some(membership => membership.clinic.id === clinicId)
  const isSwitchingClinic = hasAccess && auth.clinicId !== clinicId

  useEffect(() => {
    if (isSwitchingClinic) {
      openConfirmationModal('switchClinicConfirmationModal')
    }
  }, [isSwitchingClinic])


  const setClinic = () => {
    if (!clinicId) return
    const foundClinic = auth.userDetail?.memberships.find(membership => membership.clinic.id === clinicId)?.clinic

    auth.setSession({
      clinicId,
      enableLocalEmr: !!foundClinic?.enableLocalEmr
    })
  }

  const onGoBack = useCallback(() => {
    navigate(-1)

    setTimeout(() => {
      if ((isSwitchingClinic || !hasAccess)) {
        navigate(2)
      }
    }, 100)
  }, [hasAccess, isSwitchingClinic, navigate])

  return (
    <>
      {(isSwitchingClinic || !hasAccess) ? null : <Outlet />}
      {!hasAccess && <div>Unauthorized</div>}

      <ConfirmationModal
        id='switchClinicConfirmationModal'
        title={'Confirm'}
        description={<>This will change active clinic from <strong>{clinicFrom?.name}</strong> to <strong>{clinicTo?.name}</strong>. <span className='block mt-4'>Do you want to proceed?</span></>}
        onConfirm={setClinic}
        onCancel={onGoBack}
      />
    </>
  )
}