import React from 'react'

interface SelectOption {
  label: string
  value: string | number
}

interface SelectProps {
  value: string | number | undefined
  name: string
  options: SelectOption[]
  onSelect: (value: string | number) => void
  disabled?: boolean
}

export const Select = ({ value, options, name, disabled, onSelect }: SelectProps) => {
  return (
    <div>
      {options.map((option, i) => (
        <div key={`${name}_${i}`} className='flex items-center my-3 text-lg'>
          <input
            type='radio'
            id={`${name}_${i}`}
            value={option.value}
            className={`peer radio-primary h-6 w-6 appearance-none rounded-full border border-slate-500 checked:border-slate-400 checked:radio-primary focus:ring-transparent transition-all ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            name={name}
            onChange={(e) => onSelect(e.target.value)}
            required={true}
            disabled={disabled}
            checked={value === option.value}
          />
          <label
            className={`ml-3 ${value === option.value ? 'text-black' : ''} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            htmlFor={`${name}_${i}`}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}
