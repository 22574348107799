import React, { useState } from 'react'
import * as Toast from 'components/Toast'
import { Logo } from 'components/Logo'
import { useForm } from 'react-hook-form'
import { Input } from 'components/Input'
import { isEmail } from 'lib/utils'
import { Button } from 'components/Button'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { forgotPassword } from 'data/user'

export const ForgotPassword = () => {
  const [wasReset, setWasReset] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      email: ''
    }
  })

  const onSubmit = async (data: { email: string}) => {
    try {
      await forgotPassword(data.email)
      setWasReset(true)
    } catch(error: any) {
      Toast.error(error?.message ?? `Can't reset password, please contact the administrator`)
    }
  }

  return (
    <div className='flex w-full flex-col xl:flex-row-reverse h-full justify-center'>
      <div className='flex items-center w-full justify-center md:shadow-none xl:shadow-[-12px_0px_40px_-10px_rgba(109,109,109,0.08)]'>
        <div className='max-w-xs transform duration-200 hover:scale-110 cursor-pointer '>
          <Logo />
        </div>
      </div>
      <div className='flex items-center w-full xl:max-w-screen-sm  h-full pb-40 xl:pb-0'>
        <div className='prose lg:prose-lg xl:prose max-w-none md:px-12 xl:px-24 xl:max-w-2xl w-full'>
          <h1>Forgot your password</h1>
          <div className='mt-12 w-full'>

            <p className={clsx({ 'hidden': wasReset })}>
              Please enter the email address you'd like your password reset information sent to
            </p>

            {wasReset && (
              <p>
                Password reset link sent! Please check the specified email address in a few minutes. If the email doesn't arrive, ensure the address is correct and registered in our system.
              </p>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={clsx({ 'hidden': wasReset })}>
                <Input
                  type='email'
                  label='Email address'
                  placeholder='E-mail Address'
                  {...register('email', {
                    validate: (value) => isEmail(value) || 'Enter a valid e-mail address',
                  })}
                  error={errors?.email?.message}
                />
              </div>
              <div className='mt-10 space-y-4 flex flex-col items-center'>
                <Button
                  fullWidth
                  type='submit'
                  disabled={isSubmitting}
                  className={clsx({
                    'hidden': wasReset
                  })}
                >
                  Request reset link
                </Button>

                <Link to="/login">
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
