import React from 'react'

interface MySvgComponentProps {
  color: string
  direction: 'forward' | 'previous'
}

const PaginationButton: React.FC<MySvgComponentProps> = ({ color, direction }) => {
  const getIconPath = () => {
    if (direction === 'forward') {
      return 'M10.9653 9.71659L16.4714 15.2347L10.9653 20.7528L12.6604 22.448L19.8737 15.2347L12.6604 8.02148L10.9653 9.71659Z'
    } else {
      return 'M19.0347 9.71659L13.5286 15.2347L19.0347 20.7528L17.3396 22.448L10.1263 15.2347L17.3396 8.02148L19.0347 9.71659Z'
    }
  }

  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill={color} xmlns='http://www.w3.org/2000/svg'>
      <path d={getIconPath()} />
    </svg>
  )
}

export default PaginationButton
