import React, { useState } from 'react'
import { Button } from 'components/Button'
import { useNavigate, useParams } from "react-router-dom"
import { Controller, useForm } from 'react-hook-form'
import * as Toast from 'components/Toast'
import { ReactComponent as ArrowLeft } from 'assets/icons/left-caret.svg'
import { useAppointment, useAuth, useTitle } from 'hooks'
import { Label } from 'components/Label'
import { Input } from 'components/Input'
import { CreateNextAppointment } from './NextAppointmentDetails'
import dayjs from 'dayjs'
import { Dropdown } from 'components/Dropdown'
import { appointmentTypeOptions } from 'pages/private/Flowsheet/constants'
import { DurationList } from './constants'
import { appointments as AppointmentActions } from 'data'
import { APPOINTMENT_STATUS, AppointmentOutputDTO } from 'types'
import { TimeInput, isValidTime } from 'components/TimeInput'
import { InputDate } from 'components/Input/InputDate'
import { isBeforeTodayEod } from 'lib/day'
import { NewInfusionSessionModalContainer } from '../Flowsheet/NewInfusionSessionModalContainer'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'

const getDefaultValues = (appointment: AppointmentOutputDTO): CreateNextAppointment => {
  return {
    type: appointment.type || '',
    start: dayjs(appointment.start).format('YYYY-MM-DD'),
    startTime: dayjs(appointment.start).format('HH:mm'),
    durationInMinutes: appointment.duration?.minutes?.toString() || '',
    comment: appointment.comment || null
  }
}

interface AppointmentFormProps {
  appointment: AppointmentOutputDTO
  onSave: () => void
  isReadOnly?: boolean
}

const AppointmentForm: React.FC<AppointmentFormProps> = ({
  appointment,
  onSave,
  isReadOnly = false
}) => {
  const [showNewInfusion, setShowNewInfusion] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: getDefaultValues(appointment)
  })

  const onSubmit = async (data: CreateNextAppointment) => {
    try {
      setIsSubmitting(true)
      const duration = DurationList.find(durationItem => durationItem.minutes === +(data.durationInMinutes || 0))
      const start = new Date(`${data.start} ${data.startTime}`)

      const modifiedAppointment = {
        id: appointment.id,
        patientId: appointment.patientId,
        clinicId: appointment.clinicId,
        start: start.toISOString(),
        type: data.type,
        duration,
        comment: data.comment
      }

      await AppointmentActions.update(modifiedAppointment)
      reset(data)
      onSave()
      Toast.success('Appointment saved successfully')
    } catch (err: any) {
      Toast.error(err?.message ?? 'Appointment Update/Creation Failed!')
    } finally {
      setIsSubmitting(false)
    }
  }

  const onCancel = async () => {
    try {
      await AppointmentActions.cancel(appointment)
      setIsSubmitting(true)
      onSave()
    } catch (err: any) {
      Toast.error(err?.message ?? 'Appointment cancellation Failed!')
    } finally {
      setIsSubmitting(false)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  return (
    <>
      <div className='w-full'>
        {/* Top Bar */}
        <div className='flex px-6 py-4 justify-between align-middle self-stretch items-center shadow-md border-2 border-gray-50 my-6 rounded-lg'>
          <div className='flex space-x-4 items-center'>
            <div>
              <Button type="button" className='bg-white w-9 min-h-9 h-[36px] p-0' onClick={onBack}>
                <ArrowLeft />
              </Button>
            </div>

            <div className='flex items-baseline gap-2'>
              <span className='text-2xl font-medium'>
                Appointment for {appointment.patient?.firstName} {appointment.patient?.lastName},
              </span>

              <span className='text-dark-gray text-2xl font-medium'>
                {dayjs(appointment.start).format('MMM DD, YYYY, hh:mm A')}
              </span>
            </div>
          </div>
        </div>

        {/* Content */}
        <form onSubmit={handleSubmit(onSubmit, Toast.formErrors)}>
          {/* More Information */}
          <div className='px-6 py-4 shadow-md border-2 border-gray-50 rounded-lg'>
            <div className='flex justify-between'>
              <h2 className='text-xl mb-8'>Appointment Information</h2>

              <Button disabled={!isDirty || isSubmitting || isReadOnly} className='min-h-8 h-8 bg-secondary font-light lg:px-6' type='submit'>
                Save Appointment
              </Button>
            </div>

            <table className='w-full'>
              <tbody>
                {/* First Name */}
                <tr>
                  <td className='py-3 align-top' width="15%">
                    <Label
                      name="firstName"
                    >
                      First Name
                    </Label>
                  </td>
                  <td>
                    <Input
                      inline
                      type="text"
                      disabled
                      value={appointment.patient?.firstName}
                    />
                  </td>
                </tr>

                {/* Last Name */}
                <tr>
                  <td className='py-3 align-top'>
                    <Label
                      name="lastName"
                    >
                      Last Name
                    </Label>
                  </td>
                  <td>
                    <Input
                      inline
                      type="text"
                      disabled
                      value={appointment.patient?.lastName}
                    />
                  </td>
                </tr>

                {/* Start Date */}
                <tr>
                  <td className='py-3 align-top'>
                    <Label
                      name="start"
                    >
                      Date*
                    </Label>
                  </td>
                  <td>
                    <InputDate
                      inline
                      readOnly={isReadOnly}
                      placeholder='Enter Date'
                      {...register('start', {
                        required: 'start date is required'
                      })}
                      error={errors?.start?.message}
                    />
                  </td>
                </tr>

                {/* Time */}
                <tr>
                  <td className='py-3 align-top'>
                    <Label
                      name="startTime"
                    >
                      Time*
                    </Label>
                  </td>
                  <td>
                    <Controller
                      name="startTime"
                      rules={{
                        required: 'Start time should be selected',
                        validate: value => {
                          const [hour, minute] = value.split(':')
                          const isValid = isValidTime(hour, minute)
                          return isValid
                        }
                      }}
                      control={control}
                      render={({ field }) => {
                        return (
                          <TimeInput
                            inline
                            readOnly={isReadOnly}
                            value={field.value}
                            onChange={field.onChange}
                            error={errors.startTime?.message}
                          />
                        )
                      }}
                    />
                  </td>
                </tr>

                {/* Duration */}
                <tr>
                  <td className='py-3 align-top'>
                    <Label
                      name="durationInMinutes"
                    >
                      Duration*
                    </Label>
                  </td>
                  <td>
                    <Controller
                      name='durationInMinutes'
                      rules={{
                        required: 'Duration is required'
                      }}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          inline
                          required
                          readOnly={isReadOnly}
                          value={field.value}
                          className='h-12'
                          background='white'
                          placeholder='Choose Duration'
                          onChange={(event) =>
                            event?.target?.value && !Array.isArray(event?.target?.value) && field.onChange(event.target.value)
                          }
                          options={DurationList.map(duration => ({
                            label: duration.label,
                            value: duration.minutes.toString()
                          }))}
                          error={errors.durationInMinutes?.message}
                        />
                      )}
                    />
                  </td>
                </tr>

                {/* Type */}
                <tr>
                  <td className='py-3 align-top'>
                    <Label
                      name="type"
                    >
                      Type*
                    </Label>
                  </td>
                  <td>
                    <Controller
                      name='type'
                      rules={{
                        required: 'Type is required'
                      }}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          required
                          inline
                          readOnly={isReadOnly}
                          value={field.value}
                          className='h-12'
                          background='white'
                          placeholder='Choose Type'
                          onChange={(event) =>
                            event?.target?.value && !Array.isArray(event?.target?.value) && field.onChange(event.target.value)
                          }
                          options={appointmentTypeOptions}
                          error={errors.type?.message}
                        />
                      )}
                    />
                  </td>
                </tr>

                {/* Comment */}
                <tr>
                  <td className='py-3 align-top'>
                    <Label
                      name="comment"
                    >
                      Comment
                    </Label>
                  </td>
                  <td>
                    <Controller
                      name='comment'
                      control={control}
                      render={({ field }) => (
                        <Input
                          className='h-12'
                          type='text'
                          placeholder='Enter Comment'
                          value={field.value || ''}
                          onChange={(event) => {
                            field.onChange(event?.target?.value || null)
                          }}
                          onClear={() => {
                            field.onChange(null)
                          }}
                          showClearOnStart={!!field?.value?.length}
                        />
                      )}
                    />
                  </td>
                </tr>

              </tbody>
            </table>

            <div className='flex justify-end pt-8 gap-2'>
              {!appointment.session?.status && appointment.status !== APPOINTMENT_STATUS.SESSION_CANCELED && (
                <Button
                  className='min-h-8 h-8 font-light lg:px-6'
                  background='red'
                  type='button'
                  disabled={isReadOnly}
                  onClick={() => openConfirmationModal('cancelAppointmentConfirmationModal')}
                >
                  Cancel Appointment
                </Button>
              )}

              {!appointment.session?.status && isBeforeTodayEod(appointment.start) && (
                <Button
                  className='min-h-8 h-8 bg-primary font-light lg:px-6'
                  type='button'
                  disabled={[APPOINTMENT_STATUS.SESSION_CANCELED, APPOINTMENT_STATUS.SESSION_DELETED, APPOINTMENT_STATUS.SESSION_NOSHOW].includes(appointment.status)}
                  onClick={() => setShowNewInfusion(true)}
                >
                  Start Session
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>

      {showNewInfusion && (
        <NewInfusionSessionModalContainer
          onClose={() => setShowNewInfusion(false)}
          appointment={appointment}
        />
      )}

      <ConfirmationModal
        id='cancelAppointmentConfirmationModal'
        title={'Cancel appointment'}
        description={'Are you sure you want to cancel this appointment?'}
        onConfirm={onCancel}
      />
    </>
  )
}

export const AppointmentFormScreen = () => {
  const { id } = useParams()
  const { data: appointment, error, isLoading, mutate } = useAppointment(id)
  const auth = useAuth()
  const isLocalEMREnabled = auth.enableLocalEmr

  useTitle(`Appointment for ${appointment?.patient?.firstName} ${appointment?.patient?.lastName}, ${dayjs(appointment?.start).format('MMM DD, YYYY, hh:mm A')}, ${appointment?.type}`)

  const onSave = () => {
    mutate()
  }

  if (isLoading) {
    return <div>
      <div className='loading loading-spinner loading-sm' />
    </div>
  }

  if (error) {
    return (
      <div>
        <span className='text-2xl font-medium mr-8'>
          Appointment not found
        </span>
      </div>
    )
  }

  if (appointment) {
    const isReadOnly = !isLocalEMREnabled || [APPOINTMENT_STATUS.SESSION_CANCELED, APPOINTMENT_STATUS.SESSION_DELETED].includes(appointment.status)

    return (
      <AppointmentForm
        appointment={appointment}
        onSave={onSave}
        isReadOnly={isReadOnly}
      />
    )
  }
}
