import React, { useMemo, useState } from 'react'
import Pagination from 'components/Pagination'
import { Table } from 'components/Table'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import dayjs from 'dayjs'
import { useUserSettings } from 'hooks/useUserSettings'
import { useAuth, useCancelledAppointments } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { getSessionStatusLabel } from '../../../constants/AppointmentStatuses'
import { AppointmentStatusLabel } from './AppointmentStatusLabel'

const PAGE_SIZE = 10

interface CancelledAppointmentsProps {
  patientId: string
}

interface AppointmentModelTable {
  id: string
  start: string
  startTime: string
  reason: string
  account: string
  type: string
  duration: string
  status: {
    value: string
    render: JSX.Element
  }
}

const defaultColumns: IColumn<AppointmentModelTable>[] = [
  { label: 'Date', key: 'start' },
  { label: 'Infusion Type', key: 'type' },
  { label: 'Duration', key: 'duration' },
  { label: 'Time', key: 'startTime' },
  { label: 'Status', key: 'status' },
]

export const CancelledAppointments: React.FC<CancelledAppointmentsProps> = ({ patientId }) => {
  const { clinicId } = useAuth()
  const navigate = useNavigate()
  const { getSortListingByScreen, getColumns, saveSortListing, saveColumns } = useUserSettings()
  const [columns, setColumns] = useState(
    getColumns<IColumn<AppointmentModelTable>[]>('patient/cancelled-appointments') || defaultColumns,
  )
  const sortListingDefaults = getSortListingByScreen('patient/cancelled-appointments')

	const [query, setQuery] = useState({
		page: 0,
		size: PAGE_SIZE,
		patientId,
		orderby_field: sortListingDefaults?.fieldName || 'created',
		orderby_order: sortListingDefaults?.order || 'DESC'
	})

	const orderBy = useMemo(() => {
		return { fieldName: query.orderby_field, order: query.orderby_order } as IOrderBy<AppointmentModelTable>
	}, [query.orderby_field, query.orderby_order])

	const { data: paginatedData, isLoading } = useCancelledAppointments(query)

  const data = useMemo(() => paginatedData?.data || [], [paginatedData?.data]) 

  const rows = useMemo(
    () =>
      data.map((appointment: any) => {
        const result: AppointmentModelTable = {
          id: appointment.id || '',
          start: dayjs(appointment.start).format('MMM DD, YYYY'),
          startTime: dayjs(appointment.start).format('hh:mm A'),
          type: appointment.type,
          reason: appointment.reason,
          duration: appointment.duration?.label || '',
          account: appointment.patient.account,
          status: {
            value: getSessionStatusLabel(appointment?.session?.status || appointment.status)?.label,
            render: <AppointmentStatusLabel status={appointment?.session?.status || appointment.status} />,
          },
        }
        return result
      }) ?? [],
    [data],
  )

  const onAppointmentClick = (id: string) => {
    const foundAppointment = data.find((appointment) => appointment.id === id)

    if (!foundAppointment?.session?.status) {
      navigate(`/clinics/${clinicId}/appointments/${id}`)
    } else {
      navigate(`/clinics/${clinicId}/sessions/${id}`)
    }
  }

  const onOrderBy = (options: IOrderBy<AppointmentModelTable>) => {
    setQuery({
      ...query,
      orderby_field: options.fieldName,
      orderby_order: options.order,
    })

    saveSortListing({
      screenName: 'patient/cancelled-appointments',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<AppointmentModelTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'patient/cancelled-appointments',
      columns: tableSettings.columns,
    })
  }

  if (isLoading || !paginatedData?.data) {
    return null
  }

  return (
    <>
      <div className='shadow-md rounded-lg'>
        <div>
          <Table
            onRowClick={onAppointmentClick}
            className='w-full'
            title={'Canceled Appointments'}
            showSearch={false}
            columns={columns}
            rows={rows}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onSaveSettings={onSaveTableSettings}
          />
        </div>
      </div>
			<div className='w-full flex justify-end'>
				<Pagination
					currentPage={paginatedData.page + 1}
					totalPages={Math.ceil(paginatedData.total / paginatedData.size)}
					onPageChange={(n) => setQuery({ ...query, page: n - 1 })}
				/>
			</div>
    </>
  )
}
