import useSWR from "swr";
import { assignments } from 'data'

export const useAssignment = (query: any, options = {}) => {
	const { data, error, mutate } = useSWR(['/assignments', query], ([, query]: any[]) => assignments.fetch(query), options)

	return {
		data,
		error,
		mutate,
		loading: !error && !data,
	}
}
