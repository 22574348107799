import { useEffect } from "react"

const version = process.env.REACT_APP_VERSION || ''

export const useAppVersion = () => {

  useEffect(() => {
    const appVersionEl = document.getElementById('app-version')

    if (appVersionEl) {
      appVersionEl.innerHTML = version
    }

    console.log(`APP VERSION: ${version}`)
    localStorage.setItem('appVersion', version)
  }, [])

  return {
    version
  }
}