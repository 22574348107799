import { Button } from 'components/Button'
import { appointmentTypeOptions } from 'pages/private/Flowsheet/constants'
import React, { useState } from 'react'
import { APPOINTMENT_TYPE } from 'types'
import { Modal } from 'components/Modal'
import { Dropdown } from 'components/Dropdown'

interface NewInfusionSessionModalProps {
  onSubmit: (value: APPOINTMENT_TYPE) => void
  onClose: () => void
  defaultType?: APPOINTMENT_TYPE
}
export const NewInfusionSessionModal = ({ onSubmit, defaultType, onClose }: NewInfusionSessionModalProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultType || '')
  const onChooseClick = () => {
    if (selectedOption) {
      onSubmit(selectedOption as unknown as APPOINTMENT_TYPE)
    }
  }

  return (
    <Modal open onClose={onClose}>
      <form method='dialog'>
        <div className='flex flex-col gap-4'>
          <p>Please, select an Appointment Type for this session.</p>
          <Dropdown
            autoFocus={!defaultType}
            value={selectedOption}
            onChange={({ target: { value } }) => setSelectedOption(value)}
            options={appointmentTypeOptions}
            placeholder='Choose an option'
            className='h-8'
          />
          <Button disabled={Boolean(!selectedOption)} onClick={onChooseClick}>
            Choose
          </Button>
        </div>
      </form>
    </Modal>
  )

  // return (
  //   <dialog ref={dialogRef} id='newInfusionSessionModal' className='modal'>
  //     <form method='dialog' className='modal-box overflow-visible h-[200px]'>
  //       <div className='flex flex-col gap-4 h-20'>
  //         <p>Please, select an Appointment Type for this session.</p>
  //         <Dropdown
  //           value={selectedOption}
  //           onChange={({ target: { value } }) => setSelectedOption(value)}
  //           options={appointmentTypeOptions}
  //           placeholder='Choose an option'
  //         />
  //         <Button disabled={Boolean(!selectedOption)} onClick={onChooseClick}>
  //           Choose
  //         </Button>
  //       </div>
  //     </form>
  //     <form method='dialog' className='modal-backdrop'>
  //       <button ref={closeButtonRef}>close</button>
  //     </form>
  //   </dialog>
  // )
}
