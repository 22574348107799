import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Dropdown } from 'components/Dropdown'
import { ReactComponent as DropdownIcon } from 'assets/icons/dropdown.svg'
import Tooltip from 'components/Tooltip'
import { ClinicDetail, UserDetail, USER_ROLE } from 'types'
import { capitalizeFirstLetter } from 'lib/string'
import { useClinics } from 'hooks'
import { resetPassword } from 'lib/auth'
import * as Toast from 'components/Toast'
import { generateRandomPassword } from 'lib/password'
import { users as UserActions } from 'data'

interface UserItemProps {
  type: 'add'
  user?: never
  refreshData: () => void
  setUserAdded: (added: boolean) => void
}

interface UserListProps {
  type: 'list'
  user: UserDetail
  setUserAdded?: never
  refreshData: () => void
}

const defaultUser: Partial<UserDetail & { clinicIds: string[]; active: boolean }> = {
  firstName: '',
  lastName: '',
  email: '',
  initials: '',
  active: true,
  role: undefined,
  clinicIds: [],
  credentials: '',
}

type Props = UserItemProps | UserListProps

const UserItem: React.FC<Props> = ({ user, type, setUserAdded, refreshData }) => {
  const { data: paginatedData } = useClinics({})
  const [resettingPassword, setResettingPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const clinics = paginatedData?.data || []

  const {
    register,
    setValue,
    unregister,
    handleSubmit,
    watch,
    formState: { errors, isDirty, dirtyFields },
    control
  } = useForm({
    defaultValues: type === 'list' ? { ...user, clinicIds: user?.memberships?.map(m => m.clinic.id) } : defaultUser
  })

  const userRole = watch('role')

  const onSubmit = async (data: typeof defaultUser) => {
    try {
      setLoading(true)
      const userData = {
        ...(dirtyFields.email && { email: data.email }),
        ...(dirtyFields.credentials && { credentials: data.credentials }),
        ...(dirtyFields.firstName && { firstName: data.firstName }),
        ...(dirtyFields.lastName && { lastName: data.lastName }),
        ...(dirtyFields.initials && { initials: data.initials }),
        ...(dirtyFields.active && { active: data.active }),
        ...(dirtyFields.clinicIds && { clinicIds: data.clinicIds }),
        role: data.role,
        ...(dirtyFields.npi && { npi: data.npi }),
      }

      if (type === 'add') {
        await UserActions.create({ ...userData, password: generateRandomPassword(8) })
        setUserAdded(false)
        refreshData()
        Toast.success('User saved successfully')
        return
      }

      await UserActions.update({ id: user.id, ...userData })
      refreshData()
      Toast.success('User saved successfully')
    } catch (err: any) {
      Toast.error(err?.message ?? 'User update/create failed!')
    } finally {
      setLoading(false)
    }
  }

  const requestPasswordReset = async () => {
    if (type === 'add') return
    setResettingPassword(true)
    try {
      await resetPassword(user.email)
      Toast.info('Password Reset Requested Successfully!')
    } catch {
      // toast to inform about reset password failure
      Toast.error('Reset Password Request Failed')
    } finally {
      setResettingPassword(false)
    }
  }

  const validateThreeLetters = (value: string | undefined) => {
    if (value && value.length > 3) {
      return '3 letters'
    }
    return true
  }

  useEffect(() => {
    if (type === 'list' && user) {
      // Set initial NPI value
      setValue('npi', user.npi)
    }
    // Add or remove NPI validation based on user's role
    if (userRole === 'provider') {
      register('npi', {
        required: 'Required for providers'
      })
    } else {
      unregister('npi') // Remove validation rule
    }
  }, [type, user, setValue, register, unregister, userRole])

  return (
    <div className='flex w-full rounded-lg shadow-md border-2 border-gray-50 p-6 my-6'>
      <form onSubmit={handleSubmit(onSubmit, Toast.formErrors)} className='flex flex-col md:flex-row justify-center'>
        <div className='grid grid-cols-12 gap-4 auto-rows-min'>
          <div className='col-span-12 md:col-span-3'>
            <Input
              disabled={type === 'list'}
              inline={true}
              type='email'
              label='Email'
              placeholder='deanna.curtis@example.com'
              className='h-8 border-[.5px] disabled:bg-input-gray disabled:border-[0.5px] disabled:border-not-black rounded-md'
              autoComplete='email'
              {...register('email', {
                required: 'Email is required'
              })}
              error={errors?.email?.message}
            />
          </div>
          <div className='col-span-12 md:col-span-3'>
            <Input
              inline={true}
              type='text'
              label='Credentials'
              placeholder='MD'
              className='h-8'
              {...register('credentials', {
                validate: value => {
                  if (userRole === USER_ROLE.PROVIDER && value?.trim().length === 0) {
                    return 'Credentials required'
                  }

                  return true
                }
              })}
              error={errors?.credentials?.message}
            />
          </div>
          <div className='col-span-12 md:col-span-3'>
            <Input
              inline={true}
              type='text'
              label='First Name'
              placeholder='Deanna'
              autoComplete='first-name'
              className='h-8'
              {...register('firstName', {
                required: 'First Name is required'
              })}
              error={errors?.firstName?.message}
            />
          </div>
          <div className='col-span-12 md:col-span-3'>
            <Input
              inline={true}
              type='text'
              label='Last Name'
              placeholder='Curtis'
              autoComplete='last-name'
              className='h-8 border-[0.5px]'
              {...register('lastName', {
                required: 'Last Name is required'
              })}
              error={errors?.lastName?.message}
            />
          </div>
          <div className='col-span-12 md:col-span-6 lg:col-span-2'>
            <Input
              inline={true}
              type='text'
              maxLength={3}
              label='3 Letter Initials'
              placeholder='DCS'
              autoComplete='dcs'
              className='h-8 border-[0.5px]'
              {...register('initials', {
                required: 'Initials is required',
                validate: validateThreeLetters
              })}
              error={errors?.initials?.message}
            />
          </div>
          <div className='col-span-12 md:col-span-6 lg:col-span-2 md:mx-auto '>
            <Controller
              name='active'
              control={control}
              render={({ field }) => (
                <Checkbox
                  inline
                  label='Active'
                  color='secondary'
                  checked={field.value}
                  onChange={({ target: { checked } }) => {
                    field.onChange(checked)
                  }}
                />
              )}
            />
          </div>
          <div className='hidden md:col-span-1'></div>
          <div className='col-span-12 md:col-span-6 lg:col-span-5'>
            <Controller
              name='role'
              control={control}
              rules={{ required: 'Select a role' }}
              render={({ field }) => (
                <Dropdown
                  required
                  value={field.value}
                  className='min-h-8 h-8 disabled'
                  background='gray'
                  icon={<DropdownIcon />}
                  label='Select a Role'
                  options={[
                    { value: 'clinical-staff', label: 'Clinical Staff' },
                    { value: 'administrator', label: 'Administrator' },
                    { value: 'provider', label: 'Provider' }
                  ]}
                  placeholder={capitalizeFirstLetter(field?.value ?? '')}
                  onChange={event =>
                    event?.target?.value &&
                    !Array.isArray(event?.target?.value) &&
                    field.onChange(event.target.value as USER_ROLE)
                  }
                  error={errors.role?.message}
                  inline
                />
              )}
            />
          </div>
          <div className='col-span-12 md:col-span-6 lg:col-span-3 md:ml-0  '>
            <Button
              className='min-h-8 h-8'
              type='button'
              disabled={resettingPassword || type === 'add'}
              onClick={requestPasswordReset}
              fullWidth
            >
              Set/Reset User Password
            </Button>
          </div>

          <div className='col-span-12 md:col-span-4 lg:col-span-3'>
            <Tooltip
              text='User Clinic Affiliations'
              className='uppercase font-bold md:text-sm lg:text-md'
              position='bottom'
            >
              <span className='text-primary text-lg font-medium'>Selected Clinic</span>
              <ol type='1'>
                {Array.isArray(user?.memberships) ? (
                  user?.memberships.map((item, index) => (
                    <li className='block' key={index}>{`${1 + index}. ${item?.clinic.name}`}</li>
                  ))
                ) : (
                  <li className='block' key={'none'}>
                    None
                  </li>
                )}
              </ol>
            </Tooltip>
          </div>
          <div className='col-span-12 md:col-span-8 lg:col-span-7'>
            <Controller
              name='clinicIds'
              rules={{
                validate: value => value && value.length > 0,
                required: 'At least one clinic should be selected'
              }}
              control={control}
              render={({ field }) => (
                <Dropdown
                  required
                  value={field.value}
                  className='min-h-8 h-8'
                  icon={<DropdownIcon />}
                  label='Affiliated Clinics'
                  onChange={({ target: { value } }) => {
                    field.onChange(Array.isArray(value) ? value : [])
                  }}
                  options={
                    clinics ? clinics.map((clinic: ClinicDetail) => ({ label: clinic.name, value: clinic.id })) : []
                  }
                  placeholder=''
                  multiple
                  error={errors.clinicIds?.message}
                  inline
                />
              )}
            />
          </div>
          <div className='col-span-12 md:col-span-6 lg:col-span-2'>
            <Input
              inline={true}
              type='text'
              maxLength={10}
              label='NPI'
              placeholder=''
              className='h-8 border-[0.5px]'
              {...register('npi')}
              error={errors?.npi?.message}
            />
          </div>
          <div className='col-span-12 md:col-span-4 lg:col-span-2'>
            <Button
              className='min-h-8 h-8 bg-secondary font-light'
              type='submit'
              fullWidth
              disabled={!isDirty || loading}
            >
              {type === 'add' ? 'Add User' : 'Save Changes'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UserItem
