import { UseFormReturn } from 'react-hook-form'
import { InfusionSession } from 'types'

export type RegisterEventType = 'onBlur' | 'onChange'

export const useRegisterField = (form: UseFormReturn<InfusionSession>, data: any = {}, cb: (obj: any) => void) => {
  const createRegister = (event: RegisterEventType) => (key: any) => {
    const props = form.register(key)

    const onEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      if (((data?.[key] !== value && value !== undefined) || event === 'onChange')) {
        cb({ [key]: e.target.value })

        if (props[event]) {
          props[event](e)
        }
      }
    }

    return { ...props, [event]: onEvent }
  }

  const register = (event: RegisterEventType) => {
    return { ...form.control, register: createRegister(event) }
  }

  return { register }
}
