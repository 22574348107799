import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from 'components/FormControl'
import { Input, InputProps } from 'components/Input'
import { BLOOD_SUGAR_LIMIT, HR1_ROWS_LIMIT } from 'constants/FlowSheet'
import { formatTimeToHHmm } from 'lib/day'
import { InfusionSession, SESSION_STATUS_TYPE } from 'types'
import { getSectionBackground } from 'pages/private'
import useRolePrivilege from 'hooks/useRolePrivilege'
import { Button } from './Button'
import { ConfirmationModal, openConfirmationModal } from './Modals/ConfirmationModal'
// import { useSessionContext } from './SessionProvider'
import clsx from 'clsx'

const fieldList = [
  { value: 'initials', label: 'Initials', preFlightBsReadings: true },
  { value: 'time', label: 'Time', type: 'time', preFlightBsReadings: true },
  { value: 'temperature', label: 'Temp', type: 'number', preFlightBsReadings: false },
  { value: 'bloodPressure', label: 'B/P', type: 'bp', preFlightBsReadings: false },
  { value: 'pulse', label: 'Pulse', type: 'number', preFlightBsReadings: false },
  { value: 'respiratoryRate', label: 'RR', type: 'number', preFlightBsReadings: false },
  { value: 'value', label: 'B/S', type: 'bs', inputMode: 'numeric', preFlightBsReadings: true },
  { value: 'glucose', label: 'Glucose', type: 'number', inputMode: 'numeric', preFlightBsReadings: true },
]

interface HR1Props {
  data: InfusionSession
  onChange: (data: Partial<InfusionSession>) => void
}

export const HR1Section = ({ data, onChange }: HR1Props) => {
  // const { onHr1ForceProceedInfusion, hr1ForceProceedInfusion } = useSessionContext()
  const { control, watch } = useFormContext()
  const { canEdit } = useRolePrivilege()
  const [rowCount, setRowCount] = useState(1)

  const appointmentType = watch('type')
  const bgColor = getSectionBackground(appointmentType)

  const updateContext = (fieldName: string, value: any) => {
    if (fieldName.split('.').pop() === 'time') {
      const sessionDate = data.startedAt ? new Date(data.startedAt) : new Date()
      const [hours, minutes] = value.split(':')
      sessionDate.setHours(Number(hours))
      sessionDate.setMinutes(Number(minutes))
      value = sessionDate.toISOString()
    }
    console.log('fieldName: ', fieldName, ' value: ', value)
    onChange({ [fieldName]: value })
  }

  const [hasLowBS, lastBSValue] = useMemo((): [boolean, number] => {
    if (!data.preFlightBsReadings) return [false, 0]
    const lastValue = data.preFlightBsReadings[data.preFlightBsReadings?.length - 1]
    if (!lastValue) return [false, 0]

    return [+lastValue.value <= BLOOD_SUGAR_LIMIT, +lastValue.value]
  }, [data.preFlightBsReadings])

  // useEffect for Row Count and slicing rows incase the data is updated in some previous row
  useEffect(() => {
    let row = 1
    if (!data?.preFlightBsReadings) return

    for (let r = 0; r < data.preFlightBsReadings.length; r++) {
      const totalValues = Object.values(data.preFlightBsReadings[r]).filter((v) => v && !!v)
      if (
        totalValues.length > 0 &&
        data.preFlightBsReadings[r].value &&
        +data.preFlightBsReadings[r].value <= BLOOD_SUGAR_LIMIT
      ) {
        row += 1
      }
    }
    setRowCount(() => row > HR1_ROWS_LIMIT ? HR1_ROWS_LIMIT : row)
  }, [data])

  const dataToShow = (isPreHourBsReadingData: boolean, attribute: string, key: number) => {
    if (key > 0) return 'preFlightBsReadings'
    if (attribute !== 'initials' && attribute !== 'time') {
      return isPreHourBsReadingData ? 'preFlightBsReadings' : 'infusionSessionHours'
    }

    const preFlightBsReadingTime = data?.preFlightBsReadings?.[0]?.time
    const infusionSessionHourTime = data?.infusionSessionHours?.[0]?.time

    if (!preFlightBsReadingTime || !infusionSessionHourTime) {
      return preFlightBsReadingTime ? 'preFlightBsReadings' : 'infusionSessionHours'
    }

    const preFlightBsTime = new Date(preFlightBsReadingTime)
    const infusionSessionTime = new Date(infusionSessionHourTime)

    return preFlightBsTime > infusionSessionTime ? 'preFlightBsReadings' : 'infusionSessionHours'
  }

  const onForceProceed = () => {
    onChange({
      'hr1ForceProceedInfusion': true
    })
  }

  const isSessionSigned = data?.status === SESSION_STATUS_TYPE.SIGNED
  const isSessionCanceled = data?.status === SESSION_STATUS_TYPE.SESSION_CANCELED
  const isReadOnly = isSessionSigned || isSessionCanceled

  return (
    <>
      <div>
        <div className={`flex flex-row ${bgColor} p-2`}>
          <span className='text-dark-gray font-bold'>HR1</span>
        </div>

        <div className='mb-8'>
          <div className='mb-4 rounded-md shadow-md grid-cols-8'>
            {Array.from({ length: rowCount }, (_, index) => index).map((key) => (
              <div key={key} className='grid grid-flow-col grid-cols-10 justify-between space-x-0'>
                {fieldList.map((item) => {
                  return (
                    <div
                      key={item.label}
                      className={clsx(`col-span-1 flex-grow`, {
                        'pointer-events-none': !canEdit({ rowNumber: key, activeRow: rowCount, row: data?.preFlightBsReadings?.[key] }), 
                        'col-span-2': item.value === 'time' || item.value === 'bloodPressure'
                      })}
                    >
                      {key === 0 || item.preFlightBsReadings ? (
                        <FormControl
                          label={key === 0 ? item.label : undefined}
                          labelClassName='bg-gray-50 font-bold text-gray-500'
                          containerClassName='border-r'
                        >
                          <div className='p-1 border-b border-gray-200 '>
                            <Controller
                              name={`${dataToShow(item.preFlightBsReadings, item.value, key)}.${key}.${item.value}`}
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  value={item.value === 'time' ? formatTimeToHHmm(field.value) : field.value}
                                  disabled={item.value === 'initials'}
                                  className={clsx(`h-8`, {
                                      'min-w-8': item.value === 'time',
                                      'disabled:bg-white disabled:border-none disabled:text-primary': item.value === 'initials'
                                    })}
                                  type={item.type as InputProps['type']}
                                  placeholder=''
                                  background={
                                    field.value && item.value === 'value'
                                      ? +field.value > BLOOD_SUGAR_LIMIT
                                        ? 'light-green'
                                        : 'light-red'
                                      : 'default'
                                  }
                                  onBlur={(e) => updateContext(field.name, e.target.value)}
                                  readOnly={isReadOnly}
                                  transparent={isReadOnly}
                                  {...(item.inputMode && { inputMode: item.inputMode as InputProps['inputMode'] })}
                                />
                              )}
                            />
                          </div>
                        </FormControl>
                      ) : (
                        <div className='border-r border-b min-h-full'></div>
                      )}
                    </div>
                  )
                })}
              </div>
            ))}
          </div>

          {hasLowBS && !data.hr1ForceProceedInfusion && !isSessionSigned && (
            <div className='text-center'>
              <Button
                onClick={() => openConfirmationModal('confirmHR1ProceedToInfusionModal')}
                className='min-h-8 h-8Please be aware.'
              >Proceed to infusion</Button>
            </div>
          )}
        </div>

      </div>

      <ConfirmationModal
        id='confirmHR1ProceedToInfusionModal'
        title={'Confirm'}
        description={
          <>
            <div>The patient's B/S should be <strong>120</strong> or higher.</div>
            <div>This patient's B/S is <strong>{lastBSValue}</strong>.</div>
            <div className='mt-4'>Are you sure you want to continue?</div>
          </>
        }
        onConfirm={onForceProceed}
      />
    </>
  )
}
