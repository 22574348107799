import * as api from 'data/clinic/api'
import { CreateClinicDTO, UpdateClinicDTO } from 'types'

export const fetch = (data: any) => {
  return api.fetch(data)
}

export const search = (data: any) => {
  return api.search(data)
}

export const create = (data: CreateClinicDTO) => {
  return api.create(data)
}

export const update = (data: UpdateClinicDTO) => {
  return api.update(data)
}
