import React, { useMemo } from 'react'
import { Input } from 'components/Input'
import { BsInputProps } from 'components/BsInput/types'
import { InputBackgroundType } from 'components/Input/types'

export const BsInput = ({ value, target, showColorCode = true, ...otherProps }: BsInputProps) => {
  const getColor = useMemo(() => {
    return (): InputBackgroundType => {
      if (!value || !showColorCode) return 'default'
      switch (target) {
        case '180-220/Resistant/200':
          if (value >= 180 && value <= 200) return 'light-green'
          if (value >= 150 && value <= 230) return 'light-orange'
          return 'light-red'
        case '150-190/Moderate/170':
          if (value >= 150 && value <= 190) return 'light-green'
          if (value >= 120 && value <= 220) return 'light-orange'
          return 'light-red'
        case '120-160/Sensitive/140':
          if (value >= 120 && value <= 160) return 'light-green'
          if (value >= 160 && value <= 190) return 'light-orange'
          return 'light-red'
        default:
          return 'default'
      }
    }
  }, [showColorCode, target, value])
  return <Input {...otherProps} value={value} type='number' background={getColor()} />
}
