export const timezones = [
  { label: 'Alaska Time (AKT)', value: 'America/Anchorage' },
  // { label: 'Alaska Time - Juneau', value: 'America/Juneau' },
  // { label: 'Alaska Time - Nome', value: 'America/Nome' },
  // { label: 'Alaska Time - Sitka', value: 'America/Sitka' },
  // { label: 'Alaska Time - Yakutat', value: 'America/Yakutat' },
  { label: 'Atlantic Time (AT)', value: 'America/Puerto_Rico' },
  { label: 'Central Time (CT)', value: 'America/Chicago' },
  // { label: 'Central Time - Beulah', value: 'America/North_Dakota/Beulah' },
  // { label: 'Central Time - Center', value: 'America/North_Dakota/Center' },
  // { label: 'Central Time - Knox', value: 'America/Indiana/Knox' },
  // { label: 'Central Time - Menominee', value: 'America/Menominee' },
  // { label: 'Central Time - New Salem', value: 'America/North_Dakota/New_Salem' },
  // { label: 'Central Time - Tell City', value: 'America/Indiana/Tell_City' },
  { label: 'Eastern Time (ET)', value: 'America/New_York' },
  // { label: 'Eastern Time - Detroit', value: 'America/Detroit' },
  // { label: 'Eastern Time - Indianapolis', value: 'America/Indiana/Indianapolis' },
  // { label: 'Eastern Time - Louisville', value: 'America/Kentucky/Louisville' },
  // { label: 'Eastern Time - Marengo', value: 'America/Indiana/Marengo' },
  // { label: 'Eastern Time - Monticello', value: 'America/Kentucky/Monticello' },
  // { label: 'Eastern Time - Petersburg', value: 'America/Indiana/Petersburg' },
  // { label: 'Eastern Time - Vincennes', value: 'America/Indiana/Vincennes' },
  // { label: 'Eastern Time - Vevay', value: 'America/Indiana/Vevay' },
  // { label: 'Eastern Time - Winamac', value: 'America/Indiana/Winamac' },
  // { label: 'Hawaii-Aleutian Time (HAT)', value: 'Pacific/Honolulu' },
  { label: 'Mountain Time (MT)', value: 'America/Denver' },
  // { label: 'Mountain Time - Arizona (MT)', value: 'America/Phoenix' },
  // { label: 'Mountain Time - Boise', value: 'America/Boise' },
  { label: 'Pacific Time (PT)', value: 'America/Los_Angeles' },
  // { label: 'Samoa Time (ST)', value: 'Pacific/Pago_Pago' },
  // { label: 'Chamorro Time (ChT)', value: 'Pacific/Guam' },
  // { label: 'Chamorro Time - Saipan', value: 'Pacific/Saipan' },
];