import { ComponentPropsWithoutRef } from 'react'

export type InputBackgroundType = keyof typeof InputBackground
export type InputBorderType = keyof typeof InputBorder
export enum InputBackground {
  'default' = 'bg-athens-gray',
  'white' = 'bg-white',
  'light-yellow' = 'bg-chilean-heath',
  'light-red' = 'bg-linen',
  'light-orange' = 'bg-buttery-orange',
  'light-green' = 'bg-swans-down',
  'gray' = 'bg-input-gray',
  'green' = 'bg-green-50',
}

export enum InputBorder {
  'default' = 'border border-cloud',
  'yellow' = 'border border-mustard',
  'blue' = 'border border-dark-blue',
  'slate' = 'border border-slate-500',
  'dark' = 'border border-not-black',
  'light-orange' = 'border border-buttery-orange',
}

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  error?: string
  focused?: boolean
  className?: string
  label?: string
  background?: InputBackgroundType
  border?: InputBorderType
  inline?: boolean
  type?: 'text' | 'password' | 'email' | 'number' | 'tel' | 'time' | 'bp' | 'bs' | 'date'
  fullWidth?: boolean
  value?: any
  readonly?: boolean
  onlyTextWhenReadOnly?: boolean
  transparent?: boolean
  onClear?: () => void
  showClearOnStart?: boolean
  avoidNumberSpecialChars?: boolean
  labelClassName?: string
}
