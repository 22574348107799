const EMAIL_RE =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export const isEmail = (email: string) => {
  if (!email) return false

  const emailParts = email.split('@')

  if (emailParts.length !== 2) return false

  const account = emailParts[0]
  const address = emailParts[1]

  if (account.length > 64) return false
  else if (address.length > 255) return false

  const domainParts = address.split('.')
  if (
    domainParts.some(function (part) {
      return part.length > 63
    })
  )
    return false

  if (!EMAIL_RE.test(email)) return false

  return true
}

export const debounce = (fn: (a?: any, b?: any, c?: any) => void, delay = 1000) => {
  let timer: any = null
  return (a?: any, b?: any, c?: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(a, b, c), delay)
  }
}

export const toId = (id: string) => (id ? `/${id}` : '')

export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const noop = () => {}
