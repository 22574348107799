import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useClinics, useTitle } from 'hooks'
import { IColumn, IOrderBy, TableSettings } from 'components/Table/types'
import { Button } from 'components/Button'
import { Search } from 'components/Search'
import { Table } from 'components/Table'
import { AddNewClinicModal } from 'components/Modals/AddNewClinic'
import { ClinicModelTable } from 'pages/private/Clinics/types'
import { ClinicDetail, CreateClinicDTO } from 'types'
import Pagination from 'components/Pagination'
import { useFilteredClinics } from 'hooks'
import { useUserSettings } from 'hooks/useUserSettings'

const defaultColumns: IColumn<ClinicModelTable>[] = [
  { label: 'Name', key: 'name' },
  { label: 'Street Address', key: 'streetAddress' },
  { label: 'Street Address 2', key: 'streetAddress2' },
  { label: 'Clinic City', key: 'city' },
  { label: 'State', key: 'state' },
  { label: 'Zip', key: 'zip' },
  { label: 'Sevocity ID', key: 'sevocityId' },
]

export const Clinics: React.FC = () => {
  useTitle('Clinics')
  const {
    getSortListingByScreen,
    getColumns,
    saveSortListing,
    saveColumns,
  } = useUserSettings()
  const [columns, setColumns] = useState(getColumns<IColumn<ClinicModelTable>[]>('clinics') || defaultColumns)
  const sortListingDefaults = getSortListingByScreen('clinics')
  const [orderBy, setOrderBy] = useState<IOrderBy<ClinicModelTable>>({
    fieldName: sortListingDefaults?.fieldName as keyof ClinicModelTable || 'name',
    order: sortListingDefaults?.order || 'ASC'
  })

  const query = {
    orderby_field: orderBy.fieldName,
    orderby_order: orderBy.order,
  }

  const { data: paginatedData, mutate } = useClinics(query)
  const data = paginatedData?.data || []
  const { filteredList, setSearch, pageCount, goToPage, currentPage } = useFilteredClinics(data ?? [])

  const [type, setType] = useState<'edit' | 'add'>('add')
  const [selected, setSelected] = useState<CreateClinicDTO | undefined>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const inputSearchRef = useRef<HTMLInputElement>(null)

  const editClinic = (clinicId: string) => {
    const clinic = filteredList.find(clinic => clinic.id === clinicId)
    setType('edit')
    setSelected(clinic)
    setShowModal(true)
  }
  const addClinic = () => {
    setType('add')
    setShowModal(true)
  }
  const setTaskDone = useCallback((done: boolean) => {
    done && mutate()
    setShowModal(false)
  }, [mutate])

  const clinics = useMemo(
    () =>
      filteredList?.map((clinic: ClinicDetail) => ({
        id: clinic.id,
        name: clinic.name,
        streetAddress: clinic.streetAddress || undefined,
        streetAddress2: clinic.streetAddress2 || undefined,
        city: clinic.city || undefined,
        state: clinic.state || undefined,
        zip: clinic.zip || undefined,
        sevocityId: clinic.sevocityId || undefined,
      })) || [],
    [filteredList]
  )

  const onOrderBy = (options: IOrderBy<ClinicModelTable>) => {
    setOrderBy(options)
    saveSortListing({
      screenName: 'clinics',
      fieldName: options.fieldName,
      order: options.order,
    })
  }

  const onInputSearchClear = () => {
    if (inputSearchRef.current)
      inputSearchRef.current.value = ''
    setSearch('')
  }

  const onSaveTableSettings = (tableSettings: TableSettings) => {
    const columns = tableSettings.columns as unknown as IColumn<ClinicModelTable>[]
    const columnsToSave = columns.length === 0 ? defaultColumns : columns
    setColumns(columnsToSave)
    saveColumns({
      screenName: 'clinics',
      columns: tableSettings.columns,
    })
  }

  return (
    <>
      {showModal && <AddNewClinicModal data={selected} type={type} setTaskDone={setTaskDone} />}
      <div className='w-full h-full'>
        <div className='flex px-6 py-4 justify-between align-middle self-stretch shadow-md border-2 border-gray-50 my-6 rounded-lg'>
          <span className='text-2xl font-medium mr-8'>Clinics</span>
          <div className='flex gap-2'>
            <Search
              ref={inputSearchRef}
              className='xl:w-60'
              placeHolder='Search for clinics'
              onSearch={(text) => setSearch(text)}
              onClear={onInputSearchClear}
            />
            <Button className='min-h-8 h-8 bg-secondary font-light lg:px-6' type='button' onClick={addClinic}>
              Add New Clinic
            </Button>
          </div>
        </div>
        <div className='w-full lg:h-5/6 xl:h-4/6 overflow-scroll hide-scrollbar'>
          <Table
            onRowClick={(clinicId) => editClinic(clinicId)}
            className='w-full'
            title={'Clinics'}
            showSearch={false}
            columns={columns}
            rows={clinics || []}
            orderBy={orderBy}
            onOrderBy={onOrderBy}
            onSaveSettings={onSaveTableSettings}
          />
        </div>
        <div className='flex justify-end'>
          <Pagination currentPage={currentPage} totalPages={pageCount} onPageChange={(page) => goToPage(page)} />
        </div>
      </div>
    </>
  )
}
