import { useEffect } from 'react'

function useHistoryBack(callback: () => void) {
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Your specific function to run when the user moves back
      callback()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [callback])
}

export default useHistoryBack
