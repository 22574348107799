import React, { forwardRef, useState } from 'react'
import { FormControl } from 'components/FormControl'
import { InputBackground, InputBorder, InputProps } from 'components/Input/types'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { cn } from 'lib/cn'
import clsx from 'clsx'

export * from 'components/Input/types'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      focused,
      className,
      label,
      autoComplete = 'off',
      background = 'default',
      border = 'default',
      inline = false,
      type = 'text',
      avoidNumberSpecialChars = false,
      fullWidth = false,
      readOnly = false,
      onlyTextWhenReadOnly = false,
      placeholder = '',
      transparent = false,
      showClearOnStart = false,
      labelClassName,
      onClear,
      ...props
    },
    ref
  ) => {
    const [showClear, setShowClear] = useState(showClearOnStart || !!props.defaultValue)
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onClear)
        setShowClear(!!e.target.value.length)

      if (type === 'bp') {
        const inputValue = e.target.value
        const bloodPressureRegex = /^\d{0,3}(\/\d{0,3})?$/

        if (inputValue.length === 3 && !inputValue.includes('/')) {
          e.target.value = `${inputValue}/`
        }
        if (!bloodPressureRegex.test(inputValue)) {
          return
        }
      }
      if (props.onChange) {
        props.onChange(e)
      }
    }

    const handleOnClear = () => {
      if (onClear) {
        onClear()
        setShowClear(false)
      }
    }

    return (
      <FormControl
        focused={focused}
        error={error}
        label={label}
        inline={inline}
        disabled={props.disabled}
        containerClassName={clsx({
          'w-full': fullWidth
        })}
        labelClassName={labelClassName}
      >
        <div className='flex items-center relative'>
          {onlyTextWhenReadOnly && readOnly ? (
            <span className={cn('flex items-center justify-center', className)}>{props.value}</span>
          ) : (
            <input
              ref={ref}
              id={props.name}
              autoComplete={autoComplete}
              className={cn(
                'input bg-inherit w-full focus:outline-0 text-primary focus:ring-primary hover:border-base-content focus:border-primary cursor-pointer placeholder-gray-300',
                {
                  'input-error focus:ring-error focus:border-error': error,
                  'cursor-default focus:ring-0 focus:border-inherit hover:border-inherit': readOnly || props.disabled
                },
                InputBackground[background],
                InputBorder[border],
                className,
                transparent
                  ? 'bg-transparent border-transparent hover:border-transparent focus:border-transparent focus:ring-0'
                  : '',
              )}
              type={type}
              {...props}
              placeholder={readOnly ? undefined : placeholder}
              onChange={!readOnly ? handleInputChange : undefined}
              onBlur={!readOnly ? props.onBlur : undefined}
              readOnly={readOnly}
                {...(type === 'number' && avoidNumberSpecialChars && (
                  {
                    onKeyDown: (e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab') {
                        e.preventDefault();
                      }
                    }
                  }
                ))}
            />
          )}


          {showClear && (
            <div onClick={handleOnClear} className='absolute h-5 w-5 z-10 right-2 cursor-pointer flex items-center justify-center'>
              <Close className='h-3 w-3' />
            </div>
          )}
        </div>
      </FormControl>
    )
  }
)
