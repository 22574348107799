import React from 'react'
import { cn } from 'lib/cn'
import { Label } from 'components/Label'

export const FormControl = ({
  containerClassName,
  labelClassName,
  inline,
  label,
  prefix,
  suffix,
  children,
  name,
  error,
  focused,
  required,
  disabled,
}: any) => {
  return (
    <div className={cn('relative form-control', {
      'flex-row items-center': inline
    }, containerClassName)}>
      {label && (
        <Label
          name={name}
          required={required}
          className={cn('font-medium', labelClassName, {
            'text-opacity-20': disabled
          })}
        >
          {label}
        </Label>
      )}

      <div className='w-full align-top items-start'>
        {prefix || suffix ? (
          <label className={cn('relative', {
            'shadow-lg': focused
          })}>
            {prefix && (
              <span
                className={cn(`bg-base-200 border border-r-0 border-base-300 shadow-sm font-text`, {
                  'border-error': error,
                  'ring-primary ring-1': focused && !error
                })}
              >
                {prefix}
              </span>
            )}
            {children}
            {suffix && (
              <div className='bg-transparent flex items-center h-full absolute right-0 top-0 bottom-0 z-50'>
                {suffix}
              </div>
            )}
          </label>
        ) : (
          <div>{children}</div>
        )}

        {error && <small className='antialiased mx-2 my-1 text-sm text-error'>{error}</small>}
      </div>
    </div>
  )
}
