import React from 'react'
import { useAuth } from "hooks"
import { Navigate } from 'react-router-dom'

interface LocalEMRProps {
  children: React.ReactNode
}
export const LocalEMR: React.FC<LocalEMRProps> = ({ children }) => {
  const auth = useAuth()
  const enableLocalEmr = auth.enableLocalEmr

  if (!enableLocalEmr) return <Navigate to='/today-appointments' replace /> 

  return (
    <>
      {children}
    </>
  )
}