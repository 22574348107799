import React, { useState, useRef, useCallback } from 'react'
import _ from 'lodash'
import { ReactComponent as CommentsIcon } from 'assets/icons/comments.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg'
import { Button } from './Button'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'
import { InfusionSession, SESSION_STATUS_TYPE, USER_ROLE } from 'types'
import { Controller, useFormContext } from 'react-hook-form'
import { Input } from './Input'
import { useAuth } from 'hooks'
import { Dialog } from './Dialog'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { noop } from 'lib/utils'

export interface SessionCommentsProps {
  data: InfusionSession
  onChange: (data: Partial<InfusionSession>) => void
}

export const SessionComments: React.FC<SessionCommentsProps> = ({
  data,
  onChange
}) => {
  const { userDetail } = useAuth()
  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null)
  const [removeCommentIndex, setRemoveCommentIndex] = useState<number>(-1)
  const [isOpen, setIsOpen] = useState(false)
  const { control, reset } = useFormContext()
  const newCommentRef = useRef<HTMLInputElement>(null)
  const commentsContainerRef = useRef<HTMLDivElement>(null)
  const nextIndex = data.infusionSessionComments?.length || 0
  const countOfComments = data.infusionSessionComments?.length || 0
  const isClosedForComments = !!data.isClosedForComments
  const isSigned = data.status === SESSION_STATUS_TYPE.SIGNED

  const onOpen = useCallback(() => setIsOpen(true), [])
  const onClose = useCallback(() => setIsOpen(false), [])

  const canEditComment = (userId: string) => !isClosedForComments && ((userDetail?.role === USER_ROLE.ADMINISTRATOR || userDetail?.role === USER_ROLE.PROVIDER) || (userDetail?.role === USER_ROLE.CLINICAL_STAFF && userDetail?.id === userId))

  const onCommentUpdate = (fieldName: string, valueArg: string) => {
    const value = valueArg.trim()

    setFieldToEdit(null)
    if (!value) {
      reset({ infusionSessionComments: data.infusionSessionComments })
      return
    }
    if (_.get(data, fieldName) !== value) {
      onChange({
        [fieldName]: value
      })
    }
  }
  const onAddNewComment = async (fieldName: string, valueArg: string) => {
    const value = valueArg.trim()
    if (!value) return
    await onChange({
      [fieldName]: value
    })

    setTimeout(() => {
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current?.scrollHeight
      }
    }, 300)
  }
  const handleNewCommentSubmit = (fieldName: string) => (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    onAddNewComment(fieldName, newCommentRef.current?.value || '')
  }
  const handleCommmentUpdateSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget 
    const formElements = form.elements as typeof form.elements & {
      commentInput: { value: string }
    }
    const value = formElements.commentInput.value
    onCommentUpdate(fieldToEdit as string, value)
    setFieldToEdit(null)
  }
  const removeComment = (index: number) => {
    const fieldName = `infusionSessionComments.${index}.comment`
    onChange({
      [fieldName]: null
    })
    setRemoveCommentIndex(-1)
  }
  const onRemoveComment = (index: number) => {
    setRemoveCommentIndex(index)
    openConfirmationModal('removeSessionCommentConfirmationModal')
  }
  const onEdit = (fieldName: string) => {
    setFieldToEdit(fieldName)
  }
  const onCancelConfirmation = useCallback(() => setRemoveCommentIndex(-1), [])

  return (
    <>
      <Dialog
        open={isOpen}
        className="w-[95%] max-w-[1280px] m-h-[600px]"
        onClose={onClose}
      >
        <div
          className="flex flex-col">
          <p className="font-medium text-2xl my-4">Session Comments</p>

          {data.infusionSessionComments?.length ? (
            <div
              ref={commentsContainerRef}
              className="max-h-[300px] h-[300px] overflow-auto">
              {data.infusionSessionComments.map((infusionComment, index) => (
                <form
                  key={`infusion-sesion-comment-${index}`}
                  name={`form-infusion-sesion-comment-${index}`}
                  className="flex gap-2 items-center px-4 py-4"
                  onSubmit={handleCommmentUpdateSubmit}
                >
                  <div className="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-green-200">{infusionComment.initials}</div>
                  <div className="flex-1">
                    <Controller
                      name={`infusionSessionComments.${index}.comment`}
                      control={control}
                      render={({ field }) => {
                        if (fieldToEdit !== field.name) return (
                          <div
                            onDoubleClick={!canEditComment(infusionComment.userId) || (isSigned && !infusionComment.isAddendum) ? noop : () => onEdit(`infusionSessionComments.${index}.comment`)}
                            className='flex-1 py-2 input border-0 border-b-2 border-b-gray-200 rounded-none text-left h-auto'
                          >
                            {infusionComment.isAddendum && (
                              <span className='font-bold mr-2 text-sm'>[Addendum]</span>
                            )}
                            <span>{field.value}</span>
                          </div>
                        )

                        return (
                          <Input
                            {...field}
                            id="commentInput"
                            autoFocus
                            disabled={!canEditComment(infusionComment.userId)}
                            onBlur={e => onCommentUpdate(field.name, _.trim(e.target.value))}
                            className='border-0 border-b-2 border-b-gray-200 bg-white text-black focus:outline-none w-full rounded-none text-left'
                          />
                        )
                      }}
                    />
                  </div>
                  <div className='flex flex-col items-end'>
                    <div className='flex gap-2'>
                      {!isClosedForComments && canEditComment(infusionComment.userId) && (
                        <>
                          {fieldToEdit === `infusionSessionComments.${index}.comment` && (
                            <button
                              type="submit"
                              className={clsx('bg-white border w-[40px] h-[40px] hover:bg-white p-0 flex items-center justify-center rounded-md mb-1')}
                            >
                              <ConfirmIcon />
                            </button>
                          )}

                          <button
                            type="button"
                            onClick={() => onEdit(`infusionSessionComments.${index}.comment`)}
                            className={clsx('bg-white border w-[40px] h-[40px] hover:bg-white p-0 flex items-center justify-center rounded-md mb-1', {
                              'hidden': fieldToEdit === `infusionSessionComments.${index}.comment` || (isSigned && !infusionComment.isAddendum)
                            })}
                          >
                            <EditIcon />
                          </button>
                          <button type="button" className={clsx('bg-white border w-[40px] h-[40px] hover:bg-white p-0 flex items-center justify-center rounded-md mb-1', {
                            'hidden': fieldToEdit === `infusionSessionComments.${index}.comment` || (isSigned && !infusionComment.isAddendum)
                          })} onClick={() => onRemoveComment(index)}>
                            <TrashIcon />
                          </button>
                        </>
                      )}
                    </div>
                    <div className="text-[10px] text-gray-400">{dayjs(infusionComment.time).format('ddd DD, hh:mm A')}</div>
                  </div>
                </form>
              ))}
            </div>
          ) : null}

          {!data.infusionSessionComments?.length &&
            <div className="min-h-[300px] text-slate-300">No comments were added for this session</div>}

          <div className="pt-2">
            <div
              className="bg-gray-200 rounded-lg p-2 px-4 pb-4"
            >
              <div className='flex gap-2 items-end'>
                <div className='w-[40px] h-[40px] flex items-center justify-center rounded-full bg-gray-400'>{userDetail?.initials}</div>
                <div className="flex-1">
                  <Controller
                    name={`infusionSessionComments.${nextIndex}.comment`}
                    control={control}
                    render={({ field }) => (
                      <form className="flex-1 flex gap-2 items-end" onSubmit={handleNewCommentSubmit(field.name)}>
                        <div className="flex-1">
                          <Input
                            {...field}
                            disabled={isClosedForComments}
                            ref={newCommentRef}
                            value={field.value || ''}
                            placeholder="Type your comment"
                            className='border-0 border-b-2 border-b-gray-400 bg-gray-200 text-black focus:outline-none w-full rounded-none'
                          />
                        </div>
                        <div>
                          <Button
                            type="submit"
                            disabled={isClosedForComments}
                            className="min-h-8 h-8"
                          >
                            Comment
                          </Button>
                        </div>
                      </form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="w-full fixed left-0 bottom-4 px-8 print:hidden">
        <div className="w-full mx-auto flex justify-end max-w-screen-2xl">
          <button
            onClick={onOpen}
            data-ripple-dark="true"
            className="px-6 py-2 h-auto w-auto min-h-0 flex flex-row items-center justify-end relative border-blue-950 rounded-btn border"
            style={{ background: '#FFFFFF', boxShadow: '2px 5px 5px #AAA' }}
          >
            {countOfComments > 0 && (
              <div className="text-blue-950 font-bold w-6 h-6 px-4 flex items-center justify-center">
                {countOfComments}
              </div>
            )}
            <CommentsIcon />
          </button>
        </div>
      </div>

      <ConfirmationModal
        id='removeSessionCommentConfirmationModal'
        title={'Confirm'}
        description={'Are you sure you want to remove the comment ?'}
        onConfirm={() => removeComment(removeCommentIndex)}
        onCancel={onCancelConfirmation}
      />
    </>
  )
}
