import request from 'lib/request'

export const get = (path: string, query: any, options: any = {}) => {
  return request({ path, query, method: 'GET', ...options })
}

export const post = (path: string, body: any, options: any = {}) => {
  return request({ path, method: 'POST', body, ...options })
}

export const put = (path: string, body: any, options: any = {}) => {
  return request({ path, method: 'PUT', body, ...options })
}

export const patch = (path: string, body: any, options: any = {}) => {
  return request({ path, method: 'PATCH', body, ...options })
}

export const del = (path: string, body: any, options: any = {}) => {
  return request({ path, method: 'DELETE', body, ...options })
}
