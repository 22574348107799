import React from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { FormResult, TextQuestion as TextQuestionType } from 'types/dist'

interface Props {
  question: TextQuestionType
  value: string | number
  disabled?: boolean
  setValue: UseFormSetValue<FormResult>
}

export const TextQuestion = ({ question, value, disabled, setValue }: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(question.key, e.target.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = e.key

    if (keyCode === 'Enter') {
      e.preventDefault()
    }
  }

  return (
    <div className='font-semibold text-primary'>
      <input
        type='text'
        value={value || ''}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={question.key}
        className={`w-96 border-2 text-black ${disabled ? 'border-slate-400' : 'border-black'} focus:outline-none`}
      />
    </div>
  )
}
