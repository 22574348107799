import React, { HTMLAttributes } from "react"
import { useLongPress } from "use-long-press"

interface LongPressContainerProps extends HTMLAttributes<HTMLDivElement> {
  onLongPress: () => void,
  children: React.ReactNode
}

export const LongPressContainer: React.FC<LongPressContainerProps> = ({ children, onLongPress, ...props }) => {
  const bind = useLongPress(() => {
    onLongPress()
  }, {
    threshold: 2000
  })

  return (
    <div {...props} {...bind()}>
      {children}
    </div>
  )
}