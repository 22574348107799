import React from 'react'
import toast from 'react-hot-toast'
import { FieldErrors, FieldValues } from 'react-hook-form'
import { formErrorsToArray } from 'lib/form'
import { ToastErrorIcon, ToastInfoIcon, ToastSuccessIcon, ToastWarningIcon } from './icons'
import { ToastMessage } from './ToastMessage'

export const success = (text: string) => {
  return toast.custom((t) => (
    <ToastMessage
      id={t.id}
      type="success"
      title="Success!"
      visible={t.visible}
      icon={<ToastSuccessIcon />}
    >
      {text}
    </ToastMessage>
  ))
}

export const info = (text: string) => {
  return toast.custom((t) => (
    <ToastMessage
      id={t.id}
      type="info"
      title="Information"
      visible={t.visible}
      icon={<ToastInfoIcon />}
    >
      {text}
    </ToastMessage>
  ))
}

export const warning = (text: string) => {
  return toast.custom((t) => (
    <ToastMessage
      id={t.id}
      type="warning"
      title="Warning"
      visible={t.visible}
      icon={<ToastWarningIcon />}
    >
      {text}
    </ToastMessage>
  ))
}

export const error = (text: React.ReactNode) => {
  return toast.custom((t) => (
    <ToastMessage
      id={t.id}
      type="error"
      title="An error has occurred"
      visible={t.visible}
      icon={<ToastErrorIcon />}
    >
      {text}
    </ToastMessage>
  ))
}

export const formErrors = (errors: FieldErrors<FieldValues>) => {
  const errorsArray = formErrorsToArray(errors)
  if (!errorsArray.length) return null

  error(
    <div>
      {errorsArray.map(error => (
        <div key={error.key}>{error.message}</div>
      ))}
    </div>
  )
}
