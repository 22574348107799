import React from 'react'
import { cn } from 'lib/cn'

export const Avatar = ({ src, className }: { src: string | null | undefined; className: string }) => {
  return (
    <div className='avatar'>
      <div className={cn('w-8 rounded-full border-2 border-accent/2', className)}>
        <img src={src || 'http://www.gravatar.com/avatar/?d=identicon'} alt='Avatar' />
      </div>
    </div>
  )
}
