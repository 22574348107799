import clsx from 'clsx'
import React, { ReactNode, KeyboardEvent } from 'react'

interface BlurredWrapperProps {
  allow: boolean
  children: ReactNode
  noBlur?: boolean
}

const BlurredWrapper: React.FC<BlurredWrapperProps> = ({ allow, noBlur, children }) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (!allow) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  // return (
  //   <div className={allow ? '' : 'backdrop-blur-xl pointer-events-none bg-white opacity-30'} onKeyDown={handleKeyDown}>
  //     {children}
  //   </div>
  // )
  return (
    <div className={clsx({
      'bg-white opacity-30 backdrop-blur-xl pointer-events-none': !allow && !noBlur,
      'pointer-events-none': !allow && noBlur
    })} onKeyDown={handleKeyDown}>
      {children}
    </div>
  )
}

export default BlurredWrapper
