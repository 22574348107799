import useSWR from 'swr'
import { pumps } from 'data'
import { CreatePumpDTO, Pump } from 'types'
import { useAuth } from 'hooks/useAuth'
import useSWRMutation from 'swr/mutation'

export const usePumps = (query: any = {}, { ignore, ...options } = { ignore: false }) => {
  const { clinicId } = useAuth()

  const { data, error, mutate, isLoading } = useSWR<Pump[], any>(
    !ignore && clinicId ? ['/pumps', clinicId, query] : null,
    () => pumps.fetch({ clinicId, ...query }),
    options
  )

  return {
    data,
    error,
    mutate,
    isLoading,
  }
}

export const useCreatePump = (options = {}) => {
  const { clinicId } = useAuth()
  const { error, trigger, isMutating } = useSWRMutation(
    ['/pumps', clinicId],
    (_, { arg }: { arg: Omit<CreatePumpDTO, 'clinicId'> }) => {
      return clinicId ? pumps.create({ ...arg, clinicId }) : null
    },
    options
  )

  return {
    error,
    trigger,
    isMutating,
  }
}