import React, { useState } from "react"
import { Button } from "components/Button"
import * as Toast from 'components/Toast'
import { ButtonProps } from "components/Button/types"
import { FileLocation, FileTypes } from "types"
import { files as FileActions } from 'data'

interface DownloadFileButtonProps extends ButtonProps {
  children: React.ReactNode
  fileLocation: FileLocation
  fileType: FileTypes
}

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  children,
  fileLocation,
  fileType,
  ...buttonProps
}) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const onDownload = async () => {
    try {
      setIsDownloading(true)

      const blob = await FileActions.download({
        bucket: fileLocation.bucket,
        key: fileLocation.key,
        type: fileType,
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileLocation.key.split('/').pop() as string
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(link.href)
    } catch(error: any) {
      Toast.error(error.message ?? 'Download Failed!')
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Button
      {...buttonProps}
      disabled={isDownloading}
      onClick={onDownload}
    >
      {isDownloading ? `Downloading file ...` : children}
    </Button>
  )
}