import React, {ChangeEvent, useState} from 'react'
import { KeyedMutator } from "swr";
import { QUESTIONNAIRE_ASSIGNMENT_FREQUENCY } from 'types/dist'
import { Dropdown } from '../../../../components/Dropdown'
import { assignmentRules as AssignmentRulesActions } from 'data'

interface Props {
  currentFrequency?: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY
  assignmentRuleId: string
  patientId: string
  isReadOnly: boolean
  mutate: KeyedMutator<any>
}

const options = [
  { label: 'Monthly', value: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.MONTHLY },
  { label: 'Quarterly', value: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.QUARTERLY },
  { label: 'Semi-Annualy', value: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.SEMI_ANNUALLY },
  { label: 'Annually', value: QUESTIONNAIRE_ASSIGNMENT_FREQUENCY.ANNUALLY },
]

export const AssignmentFrequency = ({ currentFrequency, isReadOnly, patientId, assignmentRuleId, mutate }: Props) => {
  const [frequency, setFrequency] = useState<string | null>(currentFrequency || 'na')

  const onChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as QUESTIONNAIRE_ASSIGNMENT_FREQUENCY

    setFrequency(value || null)

    if (value) {
      await AssignmentRulesActions.update({
        patientId,
        id: assignmentRuleId,
        frequency: value,
      })

      await mutate()
    }
  }

  return (
    <Dropdown
      required
      value={frequency || undefined}
      className='h-12'
      background='white'
      placeholder='N/A'
      readOnly={isReadOnly}
      onChange={onChange}
      options={!currentFrequency ? [{ label: 'N/A', value: 'na' }] : options}
    />
  )
}
