import { cn } from 'lib/cn'
import React, { useState, useEffect, useRef } from 'react'

interface Option {
  label: string
  value: string
  className?: string
}

interface DropdownProps {
  options: Option[]
  onSelect: (value: string) => void
  className?: string
  icon?: JSX.Element
  children: React.ReactNode
}

export const DropdownMenu: React.FC<DropdownProps> = ({ options, onSelect, className, icon, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const handleOptionSelect = (value: string) => {
    onSelect(value)
    setIsOpen(false)
  }
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} className={`relative ${className}`}>
      <div onClick={() => setIsOpen(!isOpen)}>{children}</div>
      {isOpen && (
        <div className='absolute top-full right-0 mt-1 bg-white rounded-md shadow-md'>
          {options.map((option) => (
            <button
              key={option.value}
              type='button'
              className={cn(`block w-full py-2 px-4 text-left whitespace-nowrap hover:bg-gray-100 focus:bg-gray-100 focus:outline-none`, option.className)}
              onClick={() => handleOptionSelect(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
