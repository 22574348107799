import React from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { FormResult, Question, QuestionType } from 'types/dist'
import { ScoreQuestion } from './components/ScoreQuestion'
import { SelectQuestion } from './components/SelectQuestion'
import { TextQuestion } from './components/TextQuestion'

interface ComponentProps {
  currentQuestion: number
  disabled?: boolean
  type?: 'question' | 'answer'
  questions: Question[]
  control: Control<FormResult, any>
  value: string | number
  setValue: UseFormSetValue<FormResult>
}

export const FormComponentRenderer = ({
  currentQuestion,
  questions,
  disabled,
  type = 'question',
  control,
  value,
  setValue,
}: ComponentProps) => {
  const question = questions[currentQuestion]

  return type === 'question' ? (
    <div className={`mb-12 ${disabled ? 'text-slate-400' : ''}`}>
      <div className='font-semibold text-xl mb-6 text-black'>{question.title}</div>
      <div>
        {question.type === QuestionType.SELECT ? (
          <SelectQuestion question={question} value={value} setValue={setValue} disabled={disabled} />
        ) : question.type === QuestionType.SCORE ? (
          <ScoreQuestion question={question} value={value} setValue={setValue} disabled={disabled} />
        ) : question.type === QuestionType.TEXT ? (
          <TextQuestion question={question} value={value} setValue={setValue} disabled={disabled} />
        ) : (
          <div></div>
        )}
      </div>
      <div className='whitespace-pre-wrap mt-5'>
        <i>{question.description}</i>
      </div>
    </div>
  ) : (
    <div className='flex justify-between text-lg mb-4'>
      <span
        style={{
          maxWidth: 800,
        }}
      >
        {question.title}
      </span>
      <span className='text-primary font-semibold'>
        {question.type === QuestionType.SELECT ? (
          question.multipleAnswers && Array.isArray(value) ? (
            value.map((val) => question.variants.find((variant) => variant.value === val)?.label || val).join(', ')
          ) : (
            question.variants.find((variant) => variant.value === value)?.label || value
          )
        ) : question.type === QuestionType.SCORE ? (
          <>
            {value} / {question.maxValue}
          </>
        ) : (
          value
        )}
      </span>
    </div>
  )
}
