import React from 'react'
import { QUESTIONNAIRE_ASSIGNMENT_STATUS } from 'types'
import { capitalizeFirstLetter } from '../../../../lib/string'

const ASSIGNMENT_COLORS: Record<QUESTIONNAIRE_ASSIGNMENT_STATUS, string> = {
  [QUESTIONNAIRE_ASSIGNMENT_STATUS.DORMANT]: 'text-primary',
  [QUESTIONNAIRE_ASSIGNMENT_STATUS.QUEUED]: 'text-secondary',
  [QUESTIONNAIRE_ASSIGNMENT_STATUS.OVERDUE]: 'text-error',
  [QUESTIONNAIRE_ASSIGNMENT_STATUS.STARTED]: 'text-info',
  [QUESTIONNAIRE_ASSIGNMENT_STATUS.COMPLETED]: 'text-secondary',
}

interface AssignmentStatusProps {
  status: QUESTIONNAIRE_ASSIGNMENT_STATUS
}

export const AssignmentStatus: React.FC<AssignmentStatusProps> = ({ status }) => {
  if (!status) return <span className='text-primary'>Dormant</span>

  const color = ASSIGNMENT_COLORS[status]

  return <span className={`${color} text-base`}>{capitalizeFirstLetter(status.toLowerCase())}</span>
}
