import React, { useRef, forwardRef } from 'react';
import { Input, InputProps } from './index';
import dayjs from 'dayjs';

type InputDateProps = InputProps & {
  auto?: boolean
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(({ auto = true, ...inputProps}, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isPickerOpenRef = useRef<boolean>(false)
  
  const openCalendar = () => {
    if (isPickerOpenRef.current) return
    if (!auto) return

    if (inputRef.current && !inputProps.readOnly && typeof inputRef.current.showPicker === 'function') {
      isPickerOpenRef.current = true
      inputRef.current.showPicker()
    }
  }

  const handleRef = (node: HTMLInputElement | null) => {
    if (typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
    inputRef.current = node;
  };

  const handleBlur = () => {
    isPickerOpenRef.current = false
  }

  return (
    <Input
      {...inputProps}
      type="date"
      onClick={openCalendar}
      onFocus={openCalendar}
      onBlur={e => {
        handleBlur()
        if (inputProps.onBlur) inputProps.onBlur(e)
      }}
      ref={handleRef}
    />
  );
});

export const validateInputDate = (value: string): string | boolean => {
  try {
    const dateValue = dayjs(value)
    if (!dateValue.isValid()) return 'Invalid date'

    const year = new Date(value).getFullYear()
    if (year.toString().length > 4) {
      throw new Error('Invalid year')
    }

    return true
  } catch(e) {
    if (e instanceof Error) return e.message
  }

  return true
}