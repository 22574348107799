import React from 'react';
import PaginationButton from 'components/PaginationButton';
import { PaginationProps } from 'components/Pagination/types';
import clsx from 'clsx';

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const visiblePages = 5; // Number of visible page numbers
    const spread = 2; // Number of additional numbers displayed around current page
    const start = Math.max(1, currentPage - spread);
    const end = Math.min(totalPages, start + visiblePages - 1);

    const pages = [];

    if (start > 1) {
      pages.push(
        <li
          key="start"
          className={clsx(`pagination-item flex items-center justify-center w-12 h-12 border-2 rounded-md cursor-pointer`, {
            'border-primary': currentPage === 1,
            'hover:bg-gray-200': currentPage !== 1
          })}
          onClick={() => handlePageChange(1)}
        >
          1
        </li>
      );
      if (start > 2) {
        pages.push(
          <li key="startDots" className="pagination-item flex items-center justify-center w-12 h-12">
            ...
          </li>
        );
      }
    }

    for (let i = start; i <= end; i++) {
      pages.push(
        <li
          key={i}
          className={clsx(`pagination-item flex items-center justify-center w-12 h-12 border-2 rounded-md cursor-pointer`, {
            'border-primary': currentPage === i,
            'hover:bg-gray-200': currentPage !== i
          })}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pages.push(
          <li key="endDots" className="pagination-item flex items-center justify-center w-12 h-12">
            ...
          </li>
        );
      }
      pages.push(
        <li
          key="end"
          className={clsx(`pagination-item flex items-center justify-center w-12 h-12 border-2 rounded-md cursor-pointer`, {
            'border-primary': currentPage === totalPages,
            'hover:bg-gray-200': currentPage !== totalPages
          })}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </li>
      );
    }

    return pages;
  };

  return (
    <nav className="flex items-center justify-center mt-6">
      <ul className="pagination flex gap-x-4">
        <li
          className={clsx(`pagination-item flex items-center justify-center w-12 h-12 border-2 rounded-md cursor-pointer`, {
            'opacity-50 pointer-events-none': currentPage === 1,
            'hover:bg-gray-200': currentPage !== 1
          })}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <PaginationButton color={currentPage === 1 ? 'gray' : '#085072'} direction="previous" />
        </li>

        {renderPageNumbers()}

        <li
          className={clsx(`pagination-item flex items-center justify-center w-12 h-12 border-2 rounded-md cursor-pointer`, {
            'opacity-50 pointer-events-none': currentPage === totalPages,
            'hover:bg-gray-200': currentPage !== totalPages 
          })}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <PaginationButton color={currentPage === totalPages ? 'gray' : '#085072'} direction="forward" />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
