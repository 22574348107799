import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'components/Button'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import type { TableSettings as TableSettingsProp } from 'components/Table/types'
import { ReactComponent as Down } from 'assets/icons/down-arrow.svg'
import clsx from 'clsx'
import { ConfirmationModal, openConfirmationModal } from 'components/Modals/ConfirmationModal'

interface TableSettingsProps {
  onClose: () => void,
  settings: TableSettingsProp,
  onSave?: (settings: TableSettingsProp) => void
}

export const TableSettings = ({
  onClose,
  onSave = () => {},
  settings
}: TableSettingsProps) => {
  const [isDirty, setIsDirty] = useState(false)
  const [columns, setColumns] = useState(settings.columns)
  const dialogRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [onClose])

  const onSaveSettings = () => {
    const newSettings = {
      columns,
    }

    onClose()
    onSave(newSettings)
  }

  const onCancelClick: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
  }

  const onResetSettings = () => {
    const newSettings = {
      columns: [],
    }

    onClose()
    onSave(newSettings)
  }

  const moveUp = (index: number) => {
    if (index === 0) return;
    const newColumns = [...columns];
    [newColumns[index - 1], newColumns[index]] = [newColumns[index], newColumns[index - 1]];
    setColumns(newColumns);
    setIsDirty(true)
  };

  const moveDown = (index: number) => {
    if (index === columns.length - 1) return;
    const newColumns = [...columns];
    [newColumns[index + 1], newColumns[index]] = [newColumns[index], newColumns[index + 1]];
    setColumns(newColumns);
    setIsDirty(true)
  };

  return (
    <>
      <dialog open className='modal backdrop-blur-sm !mt-0' onClick={onClose}>
        <div
          ref={dialogRef}
          className='modal-box overflow-scroll h-auto hide-scrollbar relative'
          onClick={onCancelClick}
        >
          <div className='absolute right-4 top-4'>
            <Button type="button" className='bg-white border-none hover:bg-white' onClick={onClose}>
              <Close />
            </Button>
          </div>

          <div className='flex flex-col space-y-2'>
            <p className='font-medium text-2xl my-4'>Table settings</p>

            <p className='text-lg font-medium'>Columns Order</p>
            <div>
              {columns.map((column, index) => (
                <div
                  key={`table-settings-col-${String(column.key)}`}
                  className='flex justify-between py-2 border-b border-b-slate-300 last:border-none hover:bg-light-blue active:bg-light-blue'
                >
                  <div> {column.label} </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => moveDown(index)}
                      disabled={index === columns.length - 1}
                      className={clsx('mx-5', {
                        'opacity-50': index === columns.length - 1
                      })}
                    >
                      <Down />
                    </button>

                    <button
                      type="button"
                      onClick={() => moveUp(index)}
                      disabled={index === 0}
                      className={clsx('mx-5', {
                        'opacity-50': index === 0
                      })}
                    >
                      <Down className='rotate-180' />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className='flex justify-end mt-[100px] gap-2'>
              <Button
                className='min-h-8 h-8'
                background='red'
                onClick={() => openConfirmationModal('resetTableSettingsConfirmationModal')}
              >
                Reset
              </Button>

              <Button
                disabled={!isDirty}
                type='button'
                className='min-h-8 h-8 bg-secondary'
                onClick={() => onSaveSettings()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </dialog>

      <ConfirmationModal
        id='resetTableSettingsConfirmationModal'
        title={'Confirm'}
        description={'Are you sure you want to reset settings ?'}
        onConfirm={() => onResetSettings()}
      />
    </>
  )
}