import { useState } from 'react'
import * as storage from 'lib/storage'

export const useLocalStorage = (key: string, defaultValue: any) => {
  const [value, setStoredValue] = useState(() => storage.get(key, defaultValue))

  const setValue = (newValue: any) => {
    storage.set(key, newValue)
    setStoredValue(newValue)
  }

  return [value, setValue]
}
